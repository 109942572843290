import { TextField, Select2V2 } from "@components/FormsV2";
import RequiredReactSelect from "@components/FormsV2/RequiredReactSelect/RequiredReactSelect";
import { MultiSelectAutocomplete } from "@components/FormsV2/MultiSelectAutoComplete";
import { Row, Col } from "react-bootstrap";
import {
  useListPhasesForAccount,
  useListServiceCategoriesForAccount,
} from "../Show/api";
import { useEffect, useState } from "react";
import { Option } from "../Show/types";
import {
  useListResourcesForAccount,
  useListTeamsForAccount,
  useListTagsForAccount,
} from "./api";
import { FormNumberFieldV2 } from "@components/FormsV2/FormNumberFieldV2";

const General = ({
  fields,
  setFields,
  isManagedService,
  permission,
  editing,
}) => {
  const { phases } = useListPhasesForAccount();
  const [phaseOptions, setPhaseOptions] = useState<Option[]>([]);

  const { categories } = useListServiceCategoriesForAccount([]);
  const [categoryOptions, setCategoryOptions] = useState<Option[]>([]);

  const { resources } = useListResourcesForAccount();
  const [resourceOptions, setResourceOptions] = useState<Option[]>([]);

  const { teams } = useListTeamsForAccount();
  const [teamOptions, setTeamOptions] = useState<Option[]>([]);

  const { tags } = useListTagsForAccount();
  const [tagOptions, setTagOptions] = useState<Option[]>([]);

  const frequencyOptions: Option[] = [
    { label: "Monthly", value: "monthly" },
    { label: "Quarterly", value: "quarterly" },
    { label: "Yearly", value: "yearly" },
  ];

  useEffect(() => {
    if (phases.length && !phaseOptions.length) {
      setPhaseOptions(phases);
    }
  }, [phases]);

  useEffect(() => {
    if (JSON.stringify(categories) !== JSON.stringify(categoryOptions)) {
      setCategoryOptions(categories);
    }
  }, [categories]);

  useEffect(() => {
    if (resources.length !== resourceOptions.length) {
      setResourceOptions(resources);
    }
  }, [resources]);

  useEffect(() => {
    if (teams.length !== teamOptions.length) {
      setTeamOptions(teams);
    }
  }, [teams]);

  useEffect(() => {
    if (tags.length !== tagOptions.length) {
      setTagOptions(tags);
    }
  }, [tags]);

  return (
    <div className="generalTab">
      <div className="formSection">
        <Row>
          <Col sm={12} className="nameField">
            <TextField
              label="*Service Name"
              id="name"
              placeholder="Enter service name"
              value={fields.name}
              onChange={(e) => setFields({ ...fields, name: e.target.value })}
              required
              readOnly={editing && permission !== "manage"}
            />
          </Col>
        </Row>

        <Row>
          {!isManagedService && (
            <Col sm={4}>
              <RequiredReactSelect
                label="*Phase"
                options={phaseOptions}
                placeholder="Select phase"
                value={
                  phases.find((p) => p.value === fields.phase) || undefined
                }
                onChange={(e) =>
                  //@ts-ignore
                  setFields({ ...fields, phase: e.value })
                }
                isDisabled={editing && permission !== "manage"}
              />
            </Col>
          )}
          <Col sm={4}>
            <RequiredReactSelect
              label="*Service Category"
              options={categoryOptions}
              placeholder="Select service category"
              value={
                categories.find((c) => c.value === fields.category) || undefined
              }
              onChange={(e) =>
                //@ts-ignore
                setFields({ ...fields, category: e.value })
              }
              isDisabled={editing && permission !== "manage"}
            />
          </Col>
          <Col sm={4}>
            <TextField
              label="SKU"
              id="sku"
              placeholder="Enter SKU"
              value={fields?.sku || ""}
              onChange={(e) => setFields({ ...fields, sku: e.target.value })}
              required={false}
              readOnly={editing && permission !== "manage"}
            />
          </Col>
        </Row>
      </div>
      <div className="formSection">
        <h5>Resource & Pricing</h5>
        <Row>
          <Col sm={4}>
            <Select2V2
              label="Resource"
              options={resourceOptions}
              isDisabled={editing && permission !== "manage"}
              placeholder="Select resource"
              value={
                resources.find((r) => r.value === fields.resource) || undefined
              }
              onChange={(e) => setFields({ ...fields, resource: e.value })}
            />
          </Col>
          <Col sm={4}>
            <FormNumberFieldV2
              label="Hours"
              required={false}
              min={0}
              step={0.01}
              value={fields?.hours || ""}
              onChange={(e) => setFields({ ...fields, hours: e.target.value })}
              placeholder="Enter hours"
              readOnly={editing && permission !== "manage"}
            />
          </Col>
          {isManagedService && (
            <Col sm={4}>
              <RequiredReactSelect
                label="*Billing Frequency"
                options={frequencyOptions}
                placeholder="Select billing frequency"
                value={
                  frequencyOptions.find((o) => o.value === fields.frequency) ||
                  undefined
                }
                onChange={(e) =>
                  //@ts-ignore
                  setFields({ ...fields, frequency: e.value })
                }
                isDisabled={editing && permission !== "manage"}
              />
            </Col>
          )}
        </Row>
      </div>
      <Row>
        <Col sm={6}>
          <h5>Assigned Teams</h5>
          <MultiSelectAutocomplete
            label="Search Teams"
            placeholder="Search by team name or select from list"
            id="serviceTeams"
            options={teamOptions}
            value={fields?.teams || []}
            onChange={(e) =>
              //@ts-ignore
              setFields({ ...fields, teams: e })
            }
            isDisabled={editing && permission !== "manage"}
          />
        </Col>
        <Col sm={6}>
          <h5>Assigned Tags</h5>
          <MultiSelectAutocomplete
            label="Enter Tags"
            placeholder="Enter tags or select from list"
            id="serviceTags"
            options={tagOptions}
            value={fields?.tags || []}
            onChange={(e) =>
              //@ts-ignore
              setFields({ ...fields, tags: e })
            }
            isDisabled={editing && permission !== "manage"}
          />
        </Col>
      </Row>
    </div>
  );
};

export default General;

import React, { useEffect } from "react";
import ServicesByPhase from "../ServicesByPhase";

const ApplyStandards = ({
  servicesAdded,
  subpage,
  setSubpage,
  subservicesOnServicesAdded,
  account_slug,
  authorizationCode,
  projectId,
  phasesOnStandard,
  resources,
  rateType,
}) => {
  useEffect(() => {
    // Add grey background to body
    document.body.setAttribute("style", "background: #eee");

    // Remove screenoverlay from slideout on previous page
    if (document.querySelector(".screenOverlay")) {
      document.querySelector(".screenOverlay").remove();
    }
  }, []);

  let phasesWithServices = new Set();
  servicesAdded.forEach((service) => {
    if (service.relationships.phase && service.relationships.phase.data) {
      phasesOnStandard.forEach((phase) => {
        if (phase.id == service.relationships.phase.data.id) {
          phasesWithServices.add(phase);
        }
      });
    }
  });

  return (
    <ServicesByPhase
      authorizationCode={authorizationCode}
      account_slug={account_slug}
      subpage={subpage}
      setSubpage={setSubpage}
      projectId={projectId}
      data={{
        data: servicesAdded,
        resources: resources,
        subservices: subservicesOnServicesAdded,
        phases: Array.from(phasesWithServices),
      }}
      sourcePage="addServicesFromStandard"
      title="Add Services from Standards"
      subtitle="Review and apply standard services"
      submitButtonText="Apply Standards"
      resources={resources}
      rateType={rateType}
    />
  );
};

export default ApplyStandards;

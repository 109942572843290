import React from "react";
import API from "@API";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { formatUnprocessibleResponse } from "@utils/helperFunctions";

const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

// Functions getting the current data before submission
export const getUpdatedProjectVariables = (allProjectVariables) => {
  let newProjVariables = allProjectVariables.map((variable) => {
    return { name: variable.name, value: variable.value };
  });
  return newProjVariables;
};

export const getUpdatedCrm = (newCrmId) => {
  if (newCrmId) {
    let crmOpp = { data: { id: newCrmId, type: "crm-opportunities" } };
    return crmOpp;
  }
};
export const getUpdatedPaymentTerm = (newPaymentTermId) => {
  if (newPaymentTermId !== null) {
    let paymentTerm = {
      data: { id: newPaymentTermId, type: "payment-terms" },
    };
    return paymentTerm;
  }
};

export const getUpdatedRateTable = (newRateTableId) => {
  if (newRateTableId !== null) {
    let rateTable = {
      data: { id: newRateTableId, type: "rate-tables" },
    };
    return rateTable;
  }
};

export const getUpdatedPresalesEngineer = (newPresalesEngineerId) => {
  if (newPresalesEngineerId !== null && newPresalesEngineerId !== "") {
    let presalesEng = {
      data: { id: newPresalesEngineerId, type: "presales-engineers" },
    };
    return presalesEng;
  }
};

export const getUpdatedBusinessUnit = (newBusinessUnitId) => {
  if (newBusinessUnitId !== null) {
    let busUnit = {
      data: { id: newBusinessUnitId, type: "business-units" },
    };
    return busUnit;
  }
};

export function newResourceByName(resourceType, name, account_id) {
  return {
    data: {
      type: resourceType,
      attributes: { name: name },
      relationships: {
        account: { data: { type: "accounts", id: account_id } },
      },
    },
  };
}

export async function createResourceByName(
  resourceType,
  name,
  account_slug,
  authorizationCode,
  account_id
) {
  return API.Post(
    `${apiHost}/${account_slug}/v1/${resourceType}`,
    newResourceByName(resourceType, name, account_id),
    authorizationCode
  )
    .then((response) => {
      return response.data.data.id;
    })
    .catch((err) => {
      if (err.response.status === 422 && resourceType === "sales-executives") {
        alert(
          `Your project hss been submitted but ${name} could not be added as the sales executive.  Please visit Settings to add this user to the list of Sales Executives.`
        );
      }
      return { error: formatUnprocessibleResponse(err, "Project") };
    });
}

export async function findResourceByName(
  resourceType,
  name,
  account_slug,
  authorizationCode
) {
  return API.Get(
    `${apiHost}/${account_slug}/v1/${resourceType}?filter[active]=true,false&filter[name]=${encodeURIComponent(
      name
    )}`,
    authorizationCode
  ).then(async (res) => {
    let data = res.data.data;
    let resourceId = null;

    // Assign resourceId to the response data with an exact match on name
    for (let i = 0; i < data.length; i++) {
      if (data[i].attributes.name === name) resourceId = data[i].id;
    }
    return resourceId;
  });
}

export async function upsertClientId(
  clientName,
  account_slug,
  authorizationCode,
  account_id
) {
  let clientId = await findResourceByName(
    "clients",
    clientName,
    account_slug,
    authorizationCode
  );
  if (clientId === null)
    clientId = await createResourceByName(
      "clients",
      clientName,
      account_slug,
      authorizationCode,
      account_id
    );

  if (clientId.error) {
    return { type: "clients", id: { error: clientId.error } };
  } else {
    return { type: "clients", id: clientId };
  }
}

export async function upsertSalesExecutiveId(
  name,
  account_slug,
  authorizationCode,
  account_id
) {
  let salesExecutiveId = await findResourceByName(
    "sales-executives",
    name,
    account_slug,
    authorizationCode
  );
  if (salesExecutiveId === null)
    salesExecutiveId = await createResourceByName(
      "sales-executives",
      name,
      account_slug,
      authorizationCode,
      account_id
    );
  return { type: "sales-executives", id: salesExecutiveId };
}

const getNewLocation = (
  projectId,
  requiredLocationVariables,
  unrequiredLocationVariables,
  customerSite,
  address,
  street2,
  city,
  state,
  zip,
  remote,
  countryId
) => {
  let allLocationVariables = requiredLocationVariables.concat(
    unrequiredLocationVariables
  );
  let newLocationVariables = allLocationVariables.map((variable) => {
    return { name: variable.name, value: variable.value };
  });
  let newLocation = {
    data: {
      type: "project-locations",
      attributes: {
        name: customerSite,
        street: address,
        street2: street2,
        city: city,
        state: state,
        "postal-code": zip,
        country: countryId,
        remote: remote,
        "project-variables": newLocationVariables,
      },
      relationships: {
        project: {
          data: {
            type: "projects",
            id: projectId,
          },
        },
      },
    },
  };
  return newLocation;
};

export async function upsertProjectLocation(
  projectId,
  unrequiredLocationVariables,
  requiredLocationVariables,
  locationId,
  account_slug,
  authorizationCode,
  customerSite,
  address,
  street2,
  city,
  state,
  zip,
  remote,
  countryId
) {
  let locationData = getNewLocation(
    projectId,
    unrequiredLocationVariables,
    requiredLocationVariables,
    customerSite,
    address,
    street2,
    city,
    state,
    zip,
    remote,
    countryId
  );
  let url = `${apiHost}/${account_slug}/v1/project-locations`;
  let submitMethod = "POST";

  if (locationId !== null) {
    url = `${apiHost}/${account_slug}/v1/project-locations/${locationId}`;
    submitMethod = "PATCH";
    locationData.data.id = locationId;
  }

  return API.Submit(submitMethod, url, locationData, authorizationCode).then(
    (res) => {
      let responseData = { type: "project-locations", id: res.data.data.id };
      return responseData;
    }
  );
}

export const validateVariables = (
  allProjectVariablesArray,
  setMinValue,
  setMaxValue,
  setShowMinCharAlert,
  setShowMaxCharAlert
) => {
  var projectIsValid = true;
  var maximumValue = "";
  var minimumValue = "";
  const location = window.location.href;
  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);

  for (let i = 0; i < allProjectVariablesArray.length; i++) {
    let variableType = "";
    if (lastWordInLocation === "new") {
      variableType = allProjectVariablesArray[i]["variable-type"];
    }
    if (lastWordInLocation !== "new") {
      variableType = allProjectVariablesArray[i]["variable_type"];
    }
    if (
      variableType === "text" &&
      allProjectVariablesArray[i]["select_options"].length === 0
    ) {
      minimumValue = allProjectVariablesArray[i].minimum;

      maximumValue = allProjectVariablesArray[i].maximum;

      let value = allProjectVariablesArray[i].value;
      if (value && minimumValue != null && value.length < minimumValue) {
        setMinValue(minimumValue);
        setShowMinCharAlert(true);
        projectIsValid = false;
      }
      if (value && maximumValue != null && value.length > maximumValue) {
        setMaxValue(maximumValue);
        setShowMaxCharAlert(true);
        projectIsValid = false;
      }
    }
  } // End for loop

  return projectIsValid;
};

const formatLocationErrorMsg = (errors) => {
  let result = "";
  if (errors.length === 1) {
    result = errors[0];
  } else if (errors.length === 2) {
    result = errors[0] + " and " + errors[1];
  } else if (errors.length > 2) {
    let lastItem = errors.pop();
    result = errors.join(", ") + ", and " + lastItem;
  }

  return `Project location ${result} can't be blank.`;
};

export const validateLocation = (
  addressChanged,
  customerSite,
  address,
  city,
  state,
  zip,
  remoteSite,
  setLocationErrors
) => {
  let isValid = true;
  let errors = [];
  if (addressChanged) {
    if (customerSite === "") {
      isValid = false;
      errors.push("name");
    }
    if (!remoteSite) {
      if (address === "") {
        isValid = false;
        errors.push("street");
      }
      if (city === "") {
        isValid = false;
        errors.push("city");
      }
      if (state === "") {
        isValid = false;
        errors.push("state");
      }
      if (zip === "") {
        isValid = false;
        errors.push("postal code");
      }
    }
    setLocationErrors(formatLocationErrorMsg(errors));
  }

  return isValid;
};

export const handleFailAlert = (
  showFailAlert,
  errorMessage,
  setShowFailAlert
) => {
  if (showFailAlert === true) {
    let message =
      "Something went wrong! Some of your changes could not be saved at this time.";
    if (errorMessage !== null) message = errorMessage;
    return (
      <DismissibleAlert
        variant="warning"
        onClose={() => setShowFailAlert(false)}
        text={message}
      />
    );
  }
};

export const handleUserExistsAlert = (
  userExistsAlertIsOpen,
  setUserExistsAlertIsOpen,
  Component
) => {
  if (userExistsAlertIsOpen === true) {
    let message =
      "The user you typed already exists but is not in the list of Sales Executives. Please go to User Settings and add this user as a Sales Executive before trying to create this project.";
    if (Component) {
      return (
        <Component
          variant="warning"
          onClose={() => setUserExistsAlertIsOpen(false)}
          text={message}
        />
      );
    } else {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setUserExistsAlertIsOpen(false)}
          text={message}
        />
      );
    }
  }
};

export const handleCharacterAlert = (
  showMinCharAlert,
  setShowMinCharAlert,
  showMaxCharAlert,
  setShowMaxCharAlert,
  Component,
  minValue,
  maxValue
) => {
  if (showMinCharAlert === true) {
    if (Component) {
      return (
        <Component
          variant="danger"
          onClose={() => setShowMinCharAlert(false)}
          text={`Required text is too short (minimum is ${minValue} characters)`}
        />
      );
    } else {
      return (
        <DismissibleAlert
          variant="danger"
          onClose={() => setShowMinCharAlert(false)}
          text={`Required text is too short (minimum is ${minValue} characters)`}
        />
      );
    }
  } else if (showMaxCharAlert === true) {
    if (Component) {
      return (
        <Component
          variant="danger"
          onClose={() => setShowMinCharAlert(false)}
          text={`Required text is too long (maximum is ${maxValue} characters)`}
        />
      );
    }
    return (
      <DismissibleAlert
        variant="danger"
        onClose={() => setShowMaxCharAlert(false)}
        text={`Required text is too long (maximum is ${maxValue} characters)`}
      />
    );
  }
};

export const handleLocationAlert = (
  showLocationAlert,
  locationErrors,
  setShowLocationAlert
) => {
  if (showLocationAlert === true && locationErrors.length !== 0) {
    return (
      <ToastAlert
        variant="warning"
        onClose={() => setShowLocationAlert(false)}
        text={locationErrors}
      />
    );
  }
};

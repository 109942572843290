import General from "../common/General";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router";
import { useEffect, useState } from "react";
import { Service } from "../common/types";
import { useGetServiceById } from "../common/api";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import { Tab, Tabs } from "react-bootstrap";
import { useUpdateServiceMutation, V1ServiceResource } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { handleAlert } from "@utils/helperFunctions";
import { formatServiceData } from "../Show/common/helpers";
import useGetAccount from "../../Teams/common/api/useGetAccount";
import VersionHistory from "@components/VersionHistory";

const Edit = ({ permission }): JSX.Element => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { account } = useGetAccount();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from;
  const url = window.location.href;
  const managedService: boolean = url.includes("managed_services");
  const id = url.split("/").slice(-2, -1);
  const { service, isLoading, isFetching, refetch } = useGetServiceById(id);
  const [changes, setChanges] = useState<boolean>(false);
  const [generalFields, setGeneralFields] = useState<Service>({
    id: "",
    name: "",
    phase: "",
    category: "",
    resource: "",
    sku: "",
    hours: "",
    tags: [],
    teams: [],
    frequency: "",
  });
  const [prevGeneralFields, setPrevGeneralFields] = useState<Service>({
    id: "",
    name: "",
    phase: "",
    category: "",
    resource: "",
    sku: "",
    hours: "",
    tags: [],
    teams: [],
    frequency: "",
  });
  const [updateService] = useUpdateServiceMutation();
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const alertKey = "createdAlertShown";

    // Check if the user came from the new route and if the alert hasn't been shown yet
    if (from?.includes("new") && !localStorage.getItem(alertKey)) {
      setSuccessMessage("Service created");
      setShowSuccessAlert(true);
      localStorage.setItem(alertKey, "true");
    }
  }, [from]);

  useEffect(() => {
    if (!isLoading && !isFetching && service?.id !== generalFields.id) {
      const data = {
        id: service?.id || "",
        name: service?.name || "",
        phase: service?.phase || "",
        category: service?.category || "",
        resource: service?.resource || "",
        sku: service?.sku || "",
        hours: service?.hours || "",
        tags: service?.tags || [],
        teams: service?.teams || [],
        frequency: service?.frequency || "",
      };
      setGeneralFields(data);
      setPrevGeneralFields(data);
    }
  }, [service]);

  useEffect(() => {
    if (
      generalFields?.id &&
      prevGeneralFields?.id &&
      JSON.stringify(generalFields) !== JSON.stringify(prevGeneralFields)
    ) {
      setChanges(true);
    } else {
      setChanges(false);
    }
  }, [generalFields]);

  const handleFailResponse = (msg) => {
    setErrorMessage(msg || "Something went wrong.");
    setShowFailAlert(true);
  };

  const onSave = () => {
    if (!service?.id || !account?.id) {
      handleFailResponse("");
      return;
    }
    const data = formatServiceData(generalFields, account.id, managedService);

    if (managedService) delete data.relationships.phase;

    updateService({
      slug: accountSlug,
      id: data.id as number,
      body: { data },
    })
      .unwrap()
      .then((res) => {
        if (res?.data?.id) {
          refetch();
          setSuccessMessage("Service updated");
          setShowSuccessAlert(true);
        } else {
          handleFailResponse("");
        }
      })
      .catch((error) => handleFailResponse(error?.data?.errors?.[0]?.detail));
  };

  return isLoading ? (
    <ScopeStackSpinner />
  ) : (
    <div className="profServicesV2 manageService">
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      <div className="headerContainer">
        <div className="actionBtns">
          <Button
            className="cancelButton"
            onClick={(e) => {
              if (localStorage.getItem("createdAlertShown")) {
                localStorage.removeItem("createdAlertShown");
              }
              navigate(
                `/admin/${managedService ? "managed_services" : "tasks"}`
              );
            }}
          >
            {!changes ? "Back" : "Cancel"}
          </Button>
          <h2>{`Edit ${service?.name || ""}`}</h2>
        </div>

        <div className="actionBtns">
          {permission === "manage" && (
            <Button
              className={`button ${!changes ? "disabled" : ""}`}
              onClick={onSave}
              disabled={!changes}
            >
              Save
            </Button>
          )}
        </div>
      </div>
      <div className="tabs">
        <h2>Service Options</h2>
        <Tabs
          defaultActiveKey={1}
          id="uncontrolled-tab"
          className="mb-3  manageService"
        >
          <Tab
            eventKey={1}
            title="General"
            tabClassName="profServicesTab"
            className="profServicesTabContent generalInfo"
          >
            <General
              fields={generalFields}
              setFields={setGeneralFields}
              isManagedService={managedService}
              permission={permission}
              editing={true}
            />
          </Tab>
          <Tab
            eventKey={2}
            title="Subservices"
            tabClassName="profServicesTab"
            className="profServicesTabContent subservices"
          >
            <>Subservices</>
          </Tab>
          <Tab
            eventKey={3}
            title="Service Language"
            tabClassName="profServicesTab"
            className="profServicesTabContent serviceLanguage"
          >
            <>Service Language</>
          </Tab>
          <Tab
            eventKey={4}
            title="Products"
            tabClassName="profServicesTab"
            className="profServicesTabContent serviceProducts"
          >
            <>Products</>
          </Tab>
          <Tab
            eventKey={5}
            title="Version History"
            tabClassName="profServicesTab"
            className="profServicesTabContent serviceVersionHistory"
          >
            <div style={{ marginTop: "20px" }}>
              <VersionHistory
                filter={{
                  "context-type": "Task",
                  "context-id": service?.id.toString(),
                }}
              />
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Edit;

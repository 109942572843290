import React, { useEffect } from "react";
import ServicesByPhase from "../ServicesByPhase";

function ApplyBlueprint({
  subpage,
  setSubpage,
  services,
  resourcesOnBlueprints,
  subservicesOnBlueprint,
  authorizationCode,
  phasesOnBlueprint,
  account_slug,
  projectId,
  resources,
  rateType,
  phasesOnAccount,
}) {
  useEffect(() => {
    // Add grey background to body
    document.body.setAttribute("style", "background: #eee");

    // Remove screenoverlay from slideout on previous page
    if (document.querySelector(".screenOverlay")) {
      document.querySelector(".screenOverlay").remove();
    }
  }, []);

  let phasesWithServices = new Set();
  services.forEach((service) => {
    if (service.relationships.phase && service.relationships.phase.data) {
      phasesOnBlueprint.forEach((phase) => {
        if (phase.id == service.relationships.phase.data.id) {
          phasesWithServices.add(phase);
        }
      });
    }
  });
  phasesWithServices = Array.from(phasesWithServices);

  const needsAssignmentPhaseIndex = phasesWithServices
    .map((p) => p.id)
    .indexOf("needsAssignment");

  //If "needs assignment" phase exists, make it the first item in the array
  if (needsAssignmentPhaseIndex !== -1 && needsAssignmentPhaseIndex !== 0) {
    const needsAssignmentPhase = phasesWithServices[needsAssignmentPhaseIndex];
    phasesWithServices.splice(needsAssignmentPhaseIndex, 1);
    phasesWithServices.unshift(needsAssignmentPhase);
  }

  return (
    <ServicesByPhase
      authorizationCode={authorizationCode}
      account_slug={account_slug}
      subpage={subpage}
      setSubpage={setSubpage}
      projectId={projectId}
      data={{
        data: services,
        resources: resourcesOnBlueprints,
        subservices: subservicesOnBlueprint,
        phases: phasesWithServices,
      }}
      sourcePage="addBlueprint"
      resources={resources}
      rateType={rateType}
      phasesOnAccount={phasesOnAccount}
    />
  );
}

export default ApplyBlueprint;

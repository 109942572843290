import General from "../common/General";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Service } from "../common/types";
import { formatServiceData } from "../Show/common/helpers";
import { useCreateServiceMutation } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { handleAlert } from "@utils/helperFunctions";
import useGetAccount from "../../Teams/common/api/useGetAccount";

const New = ({ permission }): JSX.Element => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { account } = useGetAccount();
  const navigate = useNavigate();
  const location = window.location.href;
  const managedService: boolean = location.includes("managed_services");
  const [createService] = useCreateServiceMutation();
  const [saveEnabled, setSaveEnabled] = useState<boolean>(false);
  const [generalFields, setGeneralFields] = useState<Service>({
    id: "",
    name: "",
    phase: "",
    category: "",
    resource: "",
    sku: "",
    hours: "",
    tags: [],
    teams: [],
    frequency: "",
  });
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFailResponse = (msg) => {
    setErrorMessage(msg || "Something went wrong.");
    setShowFailAlert(true);
  };

  const onSave = (e) => {
    if (!account?.id) {
      handleFailResponse("");
      return;
    }
    const data = formatServiceData(generalFields, account.id, managedService);
    delete data.id;

    createService({ slug: accountSlug, body: { data } })
      .unwrap()
      .then((res) => {
        const id = res?.data?.id;
        if (id) {
          navigate(
            `/admin/${
              managedService ? "managed_services" : "tasks"
            }/${id}/edit`,
            {
              state: { from: location },
            }
          );
        } else {
          handleFailResponse("");
        }
      })
      .catch((error) => handleFailResponse(error?.data?.errors?.[0]?.detail));
  };

  useEffect(() => {
    if (generalFields.name !== "" && generalFields.category !== "") {
      if (
        (managedService && generalFields.frequency !== "") ||
        (!managedService && generalFields.phase !== "")
      ) {
        setSaveEnabled(true);
      } else {
        setSaveEnabled(false);
      }
    } else {
      setSaveEnabled(false);
    }
  }, [generalFields]);

  return (
    <div className="profServicesV2">
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      <div className="headerContainer">
        <div className="actionBtns">
          <Button
            className="cancelButton"
            onClick={(e) =>
              navigate(
                managedService ? "/admin/managed_services" : "/admin/tasks"
              )
            }
          >
            Cancel
          </Button>
          <h2>Add a {managedService ? "Managed" : "Professional"} Service</h2>
        </div>

        <div className={"actionBtns"}>
          <Button
            className={`button ${!saveEnabled ? "disabled" : ""}`}
            onClick={onSave}
            disabled={!saveEnabled}
          >
            Save
          </Button>
        </div>
      </div>
      <General
        fields={generalFields}
        setFields={setGeneralFields}
        isManagedService={managedService}
        permission={permission}
        editing={false}
      />
    </div>
  );
};

export default New;

import {
  faCircleCheck,
  faCirclePlus,
  faClock,
  faGear,
  faScrewdriverWrench,
  faWrench,
} from "@fortawesome/pro-light-svg-icons";
import {
  faArrowDown,
  faArrowRight,
  faTrashCan,
  faTrash,
  faClone,
  faUsers,
  faGripDotsVertical,
  faPencil,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useContext } from "react";
import { Card, Row, Form, Button, Modal } from "react-bootstrap";
import AccordionCard from "@components/AccordionCard/AccordionCard";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import "../NewProjectDetails/NewProjectDetails.css";
import "./Services.css";
import {
  arrangeDataForTable,
  updateSingleService,
  updateSubservices,
  arrangeServicesForSubmission,
  getProjectServices,
  deleteProjectSubservices,
  changeAccordions,
  updatePhase,
} from "./serviceHelpers";
import Select from "react-select";
import {
  formatUnprocessibleResponse,
  validateDrop,
} from "@utils/helperFunctions";
import API from "@API";
import ContextMenu from "@components/ContextMenu/ContextMenu";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import DraggableTableBuilder from "@components/Tables/DraggableTableBuilder";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DraggableTable from "@components/Tables/DraggableTable";
import ConfirmationModal from "@components/Modals/ConfirmationModal";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import FormTextField from "@components/Forms/FormTextField";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { AccountContext } from "../../ProjectsMspa";
import ControlledDropDown from "../../../../components/Forms/ControlledDropDown";

function ServicesByPhase({
  authorizationCode,
  account_slug,
  subpage,
  setSubpage,
  projectId,
  data,
  sourcePage,
  title = "Add Services From Blueprint",
  subtitle = "Review & Apply Blueprint",
  submitButtonText = "Apply Blueprint",
  resources,
  rateType,
  phasesOnAccount,
}) {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const [services, setServices] = useState([]);
  const [subservices, setSubservices] = useState([]);
  const [subservicesToDelete, setSubservicesToDelete] = useState([]);
  const [allProjectServices, setAllProjectServices] = useState([]);
  const [phases, setPhases] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [lobs, setLobs] = useState([]);
  const [servicesToSubmit, setServicesToSubmit] = useState(
    arrangeDataForTable(data.data)
  );
  const [isServicesAdded, setIsServicesAdded] = useState(false);
  const [submitFail, setSubmitFail] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState([]);
  const [showSubserviceConfirmation, setShowSubserviceConfirmation] =
    useState(false);
  const [subserviceToDelete, setSubserviceToDelete] = useState([]);
  const [showServiceConfirmation, setShowServiceConfirmation] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState([]);
  const [showPhaseConfirmation, setShowPhaseConfirmation] = useState(false);
  const [phaseToDelete, setPhaseToDelete] = useState([]);
  const [phasePermissions, setPhasePermissions] = useState(false);
  let phaseIdToAssignServiceTo = 0;
  const [contextX, setContextX] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [oneAccordionIsClosed, setOneAccordionIsClosed] = useState(false);

  const [serviceListInDrag, setServiceListInDrag] = useState([]);
  const [subserviceListInDrag, setSubserviceListInDrag] = useState([]);

  useEffect(() => {
    if (data) {
      //Check phase permissions
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${projectId}/project-phases`,
        authorizationCode
      ).then((response) => {
        let phasePermission =
          response.data.meta.permissions["project-phases"] === "manage";
        if (phasePermission) setPhasePermissions(true);
      });
      let allServices = data.data;
      let servicesToSubmit = [];

      if (sourcePage == "addByQuestionnaire") {
        allServices.forEach((service) => {
          servicesToSubmit.push(service);
          if (service.subservices && service.subservices.length > 0) {
            servicesToSubmit = servicesToSubmit.concat(service.subservices);
          }
        });

        setServicesToSubmit(servicesToSubmit);
      }
      let subservices = data.projectSubservices
        ? data.projectSubservices.filter(
            (subservice) => subservice.attributes.active
          )
        : data.subservices.filter((subservice) => subservice.attributes.active);
      let phases = data.phases ? data.phases : [];
      let lobs = data.lobs ? data.lobs : [];
      let services = [];
      let serviceCategories = data.serviceCategories
        ? data.serviceCategories
        : [];

      let isServicesAdded = false;
      if (
        allServices.length > 0 &&
        allServices[0].type === "project-services"
      ) {
        isServicesAdded = true;
        setIsServicesAdded(true);
        setAllProjectServices(allServices);
      }

      allServices.forEach((service) => {
        // Match service to resource
        let serviceResourceData = service.relationships.resource.data;
        resources.forEach((resource) => {
          if (serviceResourceData && serviceResourceData.id == resource.id) {
            service.resource = resource.attributes.name;
            service.relationships.resource = {
              data: {
                type: "resources",
                id: resource.id,
              },
            };
          }
        });

        const matchSubservices = (relationshipPath) => {
          if (relationshipPath && relationshipPath.data) {
            let subservicesPerService = [];
            let subservicesOnService = relationshipPath.data;

            if (Array.isArray(subservicesOnService)) {
              subservicesOnService.forEach((subserviceOnService) => {
                subservices.forEach((subservice) => {
                  if (subserviceOnService.id === subservice.id) {
                    if (isServicesAdded) {
                      subservice.contextMenuIsOpen = false;
                    }
                    subservicesPerService.push(subservice);
                  }
                });
              });
              service.subservices = subservicesPerService.sort(
                (a, b) => a.attributes.position - b.attributes.position
              );
            }
          }
        };
        if (isServicesAdded) {
          matchSubservices(service.relationships["project-subservices"]);
        } else {
          matchSubservices(service.relationships.subservices);
        }
        if (isServicesAdded) {
          service.contextMenuIsOpen = false;
          service.accordianState = "open";
        }
        if (
          !isServicesAdded &&
          service.relationships.phase.id === "needsAssingment"
        ) {
          service.contextMenuIsOpen = false;
          service.modalShowing = false;
          service.accordianState = "open";
        }
        services.push(service);
      });

      let servicesWithoutPhases = isServicesAdded
        ? services.filter(
            (service) =>
              !service.relationships["project-phase"].data ||
              service.relationships["project-phase"].data.id == null
          )
        : services.filter(
            (service) =>
              !service.relationships.phase ||
              !service.relationships.phase.data ||
              service.relationships.phase.data.id == null
          );

      if (servicesWithoutPhases.length > 0) {
        phases.push({ id: "noPhase", attributes: { name: "No Phase" } });
        services = services.map((service) => {
          servicesWithoutPhases.forEach((serv) => {
            if (serv.id === service.id) {
              if (isServicesAdded) {
                service.relationships["project-phase"] = {
                  data: { type: "project-phases", id: "noPhase" },
                };
              } else {
                service.relationships.phase = {
                  data: { type: "phases", id: "noPhase" },
                };
              }
            }
          });
          return service;
        });
      }

      setServices(
        Array.from(new Set(arrangeDataForTable(services))).sort(
          (a, b) => a.attributes.position - b.attributes.position
        )
      );
      setSubservices(
        Array.from(new Set(subservices)).sort(
          (a, b) => a.attributes.position - b.attributes.position
        )
      );
      setLobs(lobs);
      setPhases(
        Array.from(new Set(phases))
          .sort((a, b) => a.attributes.position - b.attributes.position)
          .map((phase) => {
            phase.accordianState = "open";
            phase.modalShowing = false;
            return phase;
          })
      );
      setServiceCategories(serviceCategories);
    }
  }, [phasePermissions]);

  useEffect(() => {
    getTableData(services);
  }, [services]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Submit Services with changed values
    const processAndPostServiceData = (services) => {
      let service = services.shift();
      let serviceData = { data: service };
      if (service) {
        if (
          (service.attributes["task-source"] &&
            service.attributes["task-source"] === "managed") ||
          (service.attributes["service-type"] &&
            service.attributes["service-type"] === "managed_services")
        ) {
          delete serviceData.data.relationships.resource;
        }

        delete service.modalShowing;

        API.Post(
          `${apiHost}/${account_slug}/v1/project-services?include=service,project-subservices,project-subservices.resource,project-subservices.subservice`,
          serviceData,
          authorizationCode
        ).then((response) => {
          if (services.length !== 0) {
            processAndPostServiceData(services);
          } else {
            getProjectServices(account_slug, projectId, authorizationCode).then(
              (res) => {
                let projectServices = res.data.data;
                let subservices = res.data.included.filter(
                  (item) => item.type == "project-subservices"
                );
                let subservicesToSubmit = servicesToSubmit.filter((serv) =>
                  serv.type.includes("subservices")
                );
                subservicesToSubmit = subservicesToSubmit.map((subservice) => {
                  delete subservice.accordianState;
                  delete subservice.contextMenuIsOpen;
                  return subservice;
                });

                // Update subservices if changed
                let subservicesToUpdate = new Set();
                let subservicesToRemove = new Set();
                subservicesToSubmit.forEach((sub) => {
                  if (sub.id.includes("new")) {
                    let projectService = projectServices.find(
                      (projectServ) =>
                        projectServ.relationships.service.data.id ==
                        sub.relationships["project-service"].data.id
                    );
                    sub.serviceId = projectService.id;
                    subservicesToUpdate.add(sub);
                  }
                  subservices.forEach((subservice) => {
                    if (
                      subservice.relationships.subservice &&
                      subservice.relationships.subservice.data &&
                      subservice.relationships.subservice.data.id === sub.id
                    ) {
                      sub.relationships.subservice = {
                        data: subservice.relationships.subservice.data,
                      };
                      delete sub.relationships.resource.links;
                      sub.id = subservice.id;
                      subservicesToUpdate.add(sub);
                    }

                    if (subservicesToDelete.length > 0) {
                      subservicesToDelete.forEach((sub) => {
                        if (
                          subservice.relationships.subservice &&
                          subservice.relationships.subservice.data &&
                          subservice.relationships.subservice.data.id ===
                            sub.data.id
                        ) {
                          sub.data.id = subservice.id;
                          subservicesToRemove.add(sub);
                        }
                      });
                    }
                  });
                });

                let deletedSubservicePromises = deleteProjectSubservices(
                  Array.from(subservicesToRemove),
                  account_slug,
                  authorizationCode
                );
                Promise.all(deletedSubservicePromises)
                  .then((res) => {
                    let responses = res;
                    let submitFail = false;
                    responses.forEach((response) => {
                      if (response.status !== 204) {
                        submitFail = true;
                        setSubmitFail(submitFail);
                      }
                    });
                  })
                  .catch((err) => {
                    setSubmitFail(true);
                    setErrorMessages(
                      formatUnprocessibleResponse(err, "subservice")
                    );
                    setShowFailAlert(true);
                  });
                let updatedSubservicePromises = updateSubservices(
                  Array.from(subservicesToUpdate),
                  account_slug,
                  authorizationCode,
                  projectId
                );
                Promise.all(updatedSubservicePromises)
                  .then((res) => {
                    let responses = res;
                    let submitFail = false;
                    responses.forEach((response) => {
                      if (response.status !== 200 && response.status !== 201) {
                        submitFail = true;
                        setSubmitFail(submitFail);
                      }
                    });
                    if (!submitFail) {
                      setSubpage("servicesAdded");
                    }
                  })
                  .catch((err) => {
                    setSubmitFail(true);
                    setErrorMessages(
                      formatUnprocessibleResponse(err, "service")
                    );
                    setShowFailAlert(true);
                  });
              }
            );
          }
        });
      }
    };

    let servicesForSubmission = arrangeServicesForSubmission(
      servicesToSubmit,
      projectId,
      "project-services"
    );

    servicesForSubmission = servicesForSubmission.map((serviceToSubmit) => {
      delete serviceToSubmit.accordianState;
      delete serviceToSubmit.contextMenuIsOpen;
      return serviceToSubmit;
    });

    processAndPostServiceData(servicesForSubmission);
  };

  const updateService = (service) => {
    let serviceToSubmit = Object.assign({}, service);
    delete serviceToSubmit.accordianState;
    delete serviceToSubmit.links;
    delete serviceToSubmit.resource;
    delete serviceToSubmit.subservices;
    delete serviceToSubmit.relationships["project-subservices"];
    delete serviceToSubmit.name;
    delete serviceToSubmit.category;
    delete serviceToSubmit.lob;
    delete serviceToSubmit.phase;
    delete serviceToSubmit.tags;
    delete serviceToSubmit.contextMenuIsOpen;
    serviceToSubmit.attributes = {
      name: service.attributes.name,
      quantity: service.attributes.quantity,
      "override-hours": service.attributes["override-hours"],
      position: service.attributes.position,
    };
    updateSingleService(
      serviceToSubmit,
      account_slug,
      service.type,
      authorizationCode
    );
  };

  const confirmDeleteSubservice = (service) => {
    setShowSubserviceConfirmation(true);
    setSubserviceToDelete(service);
  };

  const deleteSubservice = () => {
    if (subserviceToDelete.id.includes("new")) {
      let newServices = services.map((serv) => {
        if (serv.subservices) {
          serv.subservices = serv.subservices.filter(
            (s) => s.id !== subserviceToDelete.id
          );
        }
        return serv;
      });
      setServices(newServices);
    }
    subserviceToDelete.deleted = true;

    setServicesToSubmit(
      servicesToSubmit.filter((serv) => serv.id !== subserviceToDelete.id)
    );
    let subserviceData = {
      data: {
        type: "project-subservices",
        id: subserviceToDelete.id,
      },
    };
    setSubservicesToDelete([...subservicesToDelete, subserviceData]);

    if (isServicesAdded && subserviceToDelete.id.includes("new") === false) {
      let promises = deleteProjectSubservices(
        [subserviceData],
        account_slug,
        authorizationCode
      );
      Promise.all(promises).catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "subservice"));
        setShowFailAlert(true);
      });
    }
    setShowSubserviceConfirmation(false);
    setSubserviceToDelete({});
  };

  const confirmDeleteService = (service) => {
    setShowServiceConfirmation(true);
    setServiceToDelete(service);
  };

  const deleteService = () => {
    let localServiceToDelete = {
      data: {
        type: "project-services",
        id: serviceToDelete.id,
      },
    };
    API.Delete(
      `${apiHost}/${account_slug}/v1/project-services/${serviceToDelete.id}`,
      localServiceToDelete,
      authorizationCode
    )
      .then((res) => {
        setServices(
          services
            .map((serv) => {
              serv.contextMenuIsOpen = false;
              return serv;
            })
            .filter((serv) => serv.id !== serviceToDelete.id)
        );
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "contact"));
        setShowFailAlert(true);
      });

    setShowServiceConfirmation(false);
    setServiceToDelete({});
  };

  const duplicateService = (service, index) => {
    setServices(
      services.map((serv) => {
        serv.contextMenuIsOpen = false;
        return serv;
      })
    );
    if (window.confirm("Are you user you want to duplicate this service?")) {
      let serviceToDuplicate = {
        data: {
          type: "project-services",
          id: service.id,
        },
      };
      API.Post(
        `${apiHost}/${account_slug}/v1/project-services/${service.id}/duplicate?include=service-category,project,project-subservices.subservice,project-subservices.resource,lob,service,project-phase,resource,service.service-category,service.resource,service.subservices,service.phase`,
        serviceToDuplicate,
        authorizationCode
      )
        .then((res) => {
          let duplicatedService = { ...service };
          duplicatedService.id = res.data.data.id;
          services.unshift(duplicatedService);
          setServices(services);
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "project"));
          setShowFailAlert(true);
        });
    }
  };

  const getPhaseRenameModalBody = (phase) => (
    <div>
      <p style={{ marginBottom: "20px" }}>
        To rename a phase, please provide the new name.
      </p>
      <FormTextField
        style={{ marginRight: "10px" }}
        label="Phase Name"
        placeholder="What do you want to call this phase"
        onChange={(e) => (phase.attributes.name = e.target.value)}
      />
    </div>
  );

  const closeAndClearPhaseModal = (phase) => {
    phase.modalShowing = false;
    setPhases(
      phases.map((p) => {
        if (p.id === phase.id) {
          p.modalShowing = false;
        }
        return p;
      })
    );
  };

  const renamePhaseModal = (phase) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <ScopeStackModal
          modalTitle="Rename Phase"
          modalBody={getPhaseRenameModalBody(phase)}
          button1Text="Cancel"
          handleButton1Click={() => closeAndClearPhaseModal(phase)}
          button2Text="Rename"
          handleButton2Click={() => renamePhase(phase)}
          show={phase.modalShowing}
          handleClose={() => closeAndClearPhaseModal(phase)}
        />
      </div>
    );
  };

  const renamePhase = (phase, phaseRenamed) => {
    let phaseToUpdate = { ...phase };
    phaseToUpdate.attributes = { name: phase.attributes.name };
    delete phaseToUpdate.links;
    delete phaseToUpdate.accordianState;
    delete phaseToUpdate.modalShowing;
    delete phaseToUpdate.contextMenuIsOpen;
    phaseToUpdate.relationships = {
      project: { data: { id: projectId, type: "projects" } },
    };

    updatePhase(phaseToUpdate, account_slug, authorizationCode)
      .then((res) => {
        setPhases(
          phases.map((p) => {
            if (p.id === phase.id) {
              p.attributes.name = res.data.data.attributes.name;
            }
            return p;
          })
        );
        closeAndClearPhaseModal(res.data.data);
      })
      .catch((err) => {
        setSubmitFail(true);
        setErrorMessages(formatUnprocessibleResponse(err, "phase"));
        setShowFailAlert(true);
      });
  };

  const confirmDeletePhase = (phase) => {
    setShowPhaseConfirmation(true);
    setPhaseToDelete(phase);
  };

  const deletePhase = () => {
    if (phaseToDelete.id === "noPhase") {
      setPhases(
        phases
          .map((phase) => {
            phase.contextMenuIsOpen = false;
            return phase;
          })
          .filter((phase) => phase.id !== phaseToDelete.id)
      );
      setShowPhaseConfirmation(false);
      setPhaseToDelete({});
    } else {
      let localPhaseToDelete = {
        data: {
          type: "project-phases",
          id: phaseToDelete.id,
        },
      };
      API.Delete(
        `${apiHost}/${account_slug}/v1/project-phases/${phaseToDelete.id}`,
        localPhaseToDelete,
        authorizationCode
      )
        .then((res) => {
          setPhases(
            phases
              .map((phase) => {
                phase.contextMenuIsOpen = false;
                return phase;
              })
              .filter((phase) => phase.id !== phaseToDelete.id)
          );
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "contact"));
          setShowFailAlert(true);
        });

      setShowPhaseConfirmation(false);
      setPhaseToDelete({});
    }
  };

  const getReassignServiceModalBody = (service) => {
    if (subpage == "applyBlueprint") {
      return (
        <div>
          <p>Please select a phase to assign this service to.</p>
          <ControlledDropDown
            label=""
            onChange={(e) => {
              let selected = e.target.options.selectedIndex;
              phaseIdToAssignServiceTo = e.target.options[selected].accessKey;
            }}
            options={[
              { id: 0, attributes: { name: "Select a phase" } },
              ...phasesOnAccount,
            ].map((p) => {
              return (
                <option className="phaseOptions" key={p.id} accessKey={p.id}>
                  {p.attributes.name}
                </option>
              );
            })}
          />
        </div>
      );
    } else {
      return <></>;
    }
  };

  const closeAndClearReassignServiceModal = (service) => {
    service.modalShowing = false;
    setServices(
      services.map((s) => {
        if (s.id === service.id) {
          s.modalShowing = false;
        }
        return s;
      })
    );
  };

  const reassignServiceModal = (service) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <ScopeStackModal
          modalTitle="Assign service to phase"
          modalBody={getReassignServiceModalBody(service)}
          button1Text="Cancel"
          handleButton1Click={() => {
            phaseIdToAssignServiceTo = 0;
            closeAndClearReassignServiceModal(service);
          }}
          button2Text="Submit"
          handleButton2Click={() => assignServiceToPhase(service)}
          show={service.modalShowing}
          handleClose={() => {
            phaseIdToAssignServiceTo = 0;
            closeAndClearReassignServiceModal(service);
          }}
        />
      </div>
    );
  };

  const assignServiceToPhase = (service) => {
    if (phaseIdToAssignServiceTo == 0) {
      setErrorMessages("Please select a phase.");
      setShowFailAlert(true);
      return;
    }
    let serviceData = {
      data: {
        id: service.id,
        type: "services",
        relationships: {
          phase: { data: { id: phaseIdToAssignServiceTo, type: "phases" } },
        },
      },
    };

    API.Patch(
      `${apiHost}/${account_slug}/v1/services/${service.id}`,
      serviceData,
      authorizationCode
    )
      .then((response) => {
        closeAndClearReassignServiceModal(service);
        if (response.status == 200) {
          setSuccessMessage("Service updated.");
          setShowSuccessAlert(true);
          const updatedServices = services
            .filter((s) => s.type === "services")
            .map((s) => {
              if (s.id == service.id) {
                s.relationships.phase = serviceData.data.relationships.phase;
              }
              return s;
            });
          setServices(updatedServices);
          let newPhases = [...phases];
          if (
            updatedServices.filter(
              (s) => s.relationships.phase.data.id === "needsAssignment"
            ).length === 0
          ) {
            newPhases = newPhases.filter((p) => p.id !== "needsAssignment");
          }

          const selectedPhase = phasesOnAccount.filter(
            (phase) => phase.id === phaseIdToAssignServiceTo
          )[0];

          if (phases.filter((p) => p.id === selectedPhase.id).length === 0) {
            if (
              updatedServices.filter(
                (s) => s.relationships.phase.data.id === "needsAssignment"
              ).length === 0
            ) {
              newPhases.unshift(selectedPhase);
            } else {
              newPhases.splice(1, 0, selectedPhase);
            }
          }
          setPhases(
            newPhases.sort(
              (a, b) => a.attributes.position - b.attributes.position
            )
          );
          phaseIdToAssignServiceTo = 0;
        }
      })
      .catch((err) => {
        phaseIdToAssignServiceTo = 0;
        setErrorMessages("Something went wrong.");
        setShowFailAlert(true);
      });
  };

  const submitAsStandard = (service) => {
    setServices(
      services.map((serv) => {
        serv.contextMenuIsOpen = false;
        return serv;
      })
    );
    API.Post(
      `${apiHost}/${account_slug}/v1/project-services/${service.id}/synchronize-standard`,
      null,
      authorizationCode
    )
      .then((res) => {
        if (res.status == 200) {
          setSuccessMessage(
            "You have successfully submitted this service as standard."
          );
          setShowSuccessAlert(true);
        }
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "project"));
        setShowFailAlert(true);
      });
  };

  const closeContextMenu = () => {
    setPhases(
      phases.map((p) => {
        p.contextMenuIsOpen = false;
        return p;
      })
    );
    setServices(
      services.map((s) => {
        s.contextMenuIsOpen = false;
        return s;
      })
    );
    document.removeEventListener("click", closeContextMenu);
  };
  const closePhaseContextMenu = () => {
    setPhases(
      phases.map((p) => {
        p.contextMenuIsOpen = false;
        return p;
      })
    );
    setServices(
      services.map((s) => {
        s.contextMenuIsOpen = false;
        return s;
      })
    );
    document.removeEventListener("click", closePhaseContextMenu);
  };

  const dragServicesEnd = (result) => {
    let isValid = validateDrop(result);
    if (!isValid) return;

    const { source, destination } = result;

    if (destination.droppableId == "PhaseDropZone") return;

    const copyListItems = [...serviceListInDrag];
    const dragItemContent = copyListItems[source.index];
    copyListItems.splice(source.index, 1);
    copyListItems.splice(destination.index, 0, dragItemContent);
    let newServices = services.filter(
      (o1) => !copyListItems.some((o2) => o1.id === o2.id)
    );
    let newlyOrderedServices = newServices.concat(copyListItems);
    setServices(newlyOrderedServices);
    dragItemContent.attributes.position = copyListItems[destination.index - 1]
      ? copyListItems[destination.index - 1].attributes.position + 1
      : 1;
    let serviceToUpdate = dragItemContent;
    updateService(serviceToUpdate);
  };

  const dragPhaseEnd = (result) => {
    let isValid = validateDrop(result);
    if (!isValid) return;
    const { source, destination } = result;
    const copyListItems = [...phases];
    const dragItemContent = copyListItems[source.index];
    if (
      destination.droppableId !== "PhaseDropZone" ||
      dragItemContent.id == "noPhase"
    )
      return;
    copyListItems.splice(source.index, 1);
    copyListItems.splice(destination.index, 0, dragItemContent);
    let newPhases = phases.filter(
      (o1) => !copyListItems.some((o2) => o1.id === o2.id)
    );
    setPhases(newPhases.concat(copyListItems));
    let phaseToUpdate = dragItemContent;
    delete phaseToUpdate.links;
    delete phaseToUpdate.accordianState;
    delete phaseToUpdate.modalShowing;
    phaseToUpdate.attributes = {
      name: dragItemContent.attributes.name,
      position: copyListItems.indexOf(dragItemContent) + 1,
    };
    phaseToUpdate.relationships = {
      project: { data: { id: projectId, type: "projects" } },
    };
    updatePhase(phaseToUpdate, account_slug, authorizationCode).catch((err) => {
      setSubmitFail(true);
      setErrorMessages(formatUnprocessibleResponse(err, "phase"));
      setShowFailAlert(true);
    });
  };

  const onDragSubserviceEnd = (result) => {
    let isValid = validateDrop(result);
    if (!isValid) return;

    const { source, destination } = result;
    if (destination.droppableId !== "SubserviceRow") return;

    const copyListItems = [...subserviceListInDrag];
    const dragItemContent = copyListItems[source.index];
    copyListItems.splice(source.index, 1);
    copyListItems.splice(destination.index, 0, dragItemContent);
    const newServices = services.map((serv) => {
      if (serv.id == dragItemContent.relationships["project-service"].data.id) {
        serv.subservices = copyListItems.map((item, index) => {
          item.attributes.position = index + 1;
          return item;
        });
        serv.relationships["project-subservices"].data = copyListItems.map(
          (item) => {
            return { typpe: "project-subservices", id: item.id };
          }
        );
      }
      return serv;
    });
    setServices(newServices);
    dragItemContent.attributes.position =
      copyListItems.indexOf(dragItemContent) + 1;
    let subserviceToUpdate = dragItemContent;
    updateService(
      subserviceToUpdate,
      account_slug,
      subserviceToUpdate.type,
      authorizationCode
    );
  };

  const getTableData = (tableDataServices) => {
    if (tableDataServices.length === 0) {
      return [];
    }
    // Map over services & return data for ScopeStackTable
    return tableDataServices.map((service, index) => {
      //Match Resources
      let resourceData = service.relationships.resource.data;
      resources.forEach((resource) => {
        if (resourceData && resourceData.id == resource.id) {
          service.resource = resource.attributes.name;
          service.relationships.resource = {
            data: {
              type: "resources",
              id: resource.id,
            },
          };
        }
      });
      // Match lob
      let lobName =
        service.relationships.lob && service.relationships.lob.data
          ? lobs.find((lob) => lob.id == service.relationships.lob.data.id)
              .attributes.name
          : "";
      let serviceCategory = "";
      if (isServicesAdded) {
        serviceCategory =
          service.relationships["service-category"] &&
          service.relationships["service-category"].data
            ? serviceCategories.find(
                (sc) =>
                  sc.id == service.relationships["service-category"].data.id
              ).attributes.name
            : "";
      }

      let quantity = 1;
      if (
        (isServicesAdded ||
          sourcePage === ("addServicesFromStandard" || "addCustomServices")) &&
        service.attributes.quantity
      ) {
        quantity = service.attributes.quantity;
      } else if (service.quantity) {
        quantity = service.quantity;
      }

      let hours = (0.0).toFixed(1);
      if (isServicesAdded && service.attributes["override-hours"]) {
        hours = service.attributes["override-hours"];
      } else if (service.attributes["suggested-hours"]) {
        hours = service.attributes["suggested-hours"];
        service.attributes["override-hours"] = hours;
      }

      let menuItemsForContext = [
        {
          newTab: false,
          onClick: () => duplicateService(service, index),
          iconClass: faClone,
          style: { padding: "0 0.5em 0 0" },
          name: <span>Duplicate</span>,
          hasBreakingLine: true,
        },
        {
          newTab: false,
          onClick: () => confirmDeleteService(service),
          iconClass: faTrash,
          style: { padding: "0 0.5em 0 0" },
          name: <span>Delete</span>,
          hasBreakingLine: service.attributes["task-source"] === "custom",
        },
      ];

      if (service.attributes["task-source"] === "custom") {
        menuItemsForContext.push({
          newTab: false,
          onClick: () => submitAsStandard(service),
          iconClass: faCheck,
          style: { padding: "0 0.5em 0 0", height: "12px" },
          name: <span>Submit As Standard</span>,
          hasBreakingLine: false,
        });
      }

      // Map returns a table row for each service
      return {
        rowClass: service.deleted ? "displayNone" : "serviceRow",
        onClick: null,
        draggableId: service.id,
        columns: [
          {
            class: "col-5",
            name:
              service.type == "project-services" ||
              service.type == "services" ? (
                <>
                  {lobName !== "" ? (
                    <div style={{ color: "gray" }}>
                      {lobName}
                      {serviceCategory ? `: ${serviceCategory}` : ""}
                    </div>
                  ) : null}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>
                      <strong className="serviceName">
                        {service.attributes.name}
                      </strong>
                    </span>
                    <span className="serviceIcon">
                      {!isServicesAdded &&
                        service.relationships.phase.data.id ===
                          "needsAssignment" && (
                          <FontAwesomeIcon
                            style={{
                              fontSize: "20px",
                              marginLeft: "10px",
                              color: "#418172",
                              cursor: "pointer",
                            }}
                            icon={faGear}
                            onClick={(e) => {
                              e.stopPropagation();
                              let width = e.pageX / 2;
                              setContextX(width);
                              service.contextMenuIsOpen =
                                !service.contextMenuIsOpen;
                              document.addEventListener(
                                "click",
                                closeContextMenu
                              );
                            }}
                          />
                        )}
                      {service.contextMenuIsOpen &&
                        !isServicesAdded &&
                        service.relationships.phase.data.id ===
                          "needsAssignment" && (
                          <ContextMenu
                            menuItems={[
                              {
                                newTab: false,
                                onClick: () => {
                                  service.modalShowing = true;
                                },
                                iconClass: faClone,
                                style: { padding: "0 0.5em 0 0" },
                                name: <span>Assign to phase</span>,
                                hasBreakingLine: false,
                              },
                            ]}
                            style={{
                              zIndex: "100",
                              top: "75%",
                              left: contextX,
                            }}
                          />
                        )}
                      {subpage === "applyBlueprint" &&
                        reassignServiceModal(service)}
                      {isServicesAdded ? (
                        <FontAwesomeIcon
                          style={{
                            fontSize: "20px",
                            marginLeft: "10px",
                            color: "#418172",
                            cursor: "pointer",
                          }}
                          icon={faGear}
                          onClick={(e) => {
                            e.stopPropagation();
                            let width = e.pageX / 2;
                            setContextX(width);
                            service.contextMenuIsOpen =
                              !service.contextMenuIsOpen;
                            document.addEventListener(
                              "click",
                              closeContextMenu
                            );

                            setServices(
                              services.map((serv) => {
                                if (serv.id == service.id) {
                                  serv.contextMenuIsOpen = true;
                                } else {
                                  serv.contextMenuIsOpen = false;
                                }
                                return serv;
                              })
                            );
                            setPhases(
                              phases.map((p) => {
                                p.contextMenuIsOpen = false;
                                return p;
                              })
                            );
                          }}
                        />
                      ) : null}
                    </span>
                    {service.contextMenuIsOpen && isServicesAdded ? (
                      <ContextMenu
                        menuItems={menuItemsForContext}
                        style={{ zIndex: "100", top: "75%", left: contextX }}
                      />
                    ) : null}
                  </div>
                </>
              ) : (
                <span style={{ display: "flex", alignItems: "center" }}>
                  {isServicesAdded ? (
                    <FontAwesomeIcon
                      style={{
                        fontSize: "20px",
                        marginRight: "10px",
                        color: "808080",
                        cursor: "pointer",
                      }}
                      icon={faGripDotsVertical}
                    />
                  ) : null}
                  <Form.Control
                    required={service.id.includes("new")}
                    type="text"
                    readOnly={!service.id.includes("new")}
                    defaultValue={service.attributes.name}
                    onChange={(e) => {
                      service.attributes.name = e.target.value;
                    }}
                    onBlur={(e) => {
                      if (!isServicesAdded) {
                        setServicesToSubmit(
                          servicesToSubmit.map((serv) => {
                            if (serv.id == e.target.id) {
                              serv.attributes.name = e.target.value;
                              return serv;
                            } else {
                              return serv;
                            }
                          })
                        );
                      }
                      if (isServicesAdded && service.attributes.name !== "") {
                        allProjectServices.forEach((projectService) => {
                          if (
                            projectService.id ===
                            service.relationships["project-service"].data.id
                          ) {
                            service.serviceId = projectService.id;
                          }
                        });
                        updateSubservices(
                          [service],
                          account_slug,
                          authorizationCode,
                          projectId
                        )[0].then((res) => {
                          service.id = res.data.data.id;
                        });
                      }
                    }}
                  />
                </span>
              ),
          },
          {
            class: "col-2",
            name: (
              <Form.Control
                id={service.id}
                type="number"
                defaultValue={quantity}
                min="1"
                step="1"
                onChange={(e) => {
                  if (!isServicesAdded) {
                    service.quantity = e.target.value;
                  }
                  if (isServicesAdded) {
                    service.attributes.quantity = e.target.value;
                  }
                }}
                onBlur={(e) => {
                  if (!isServicesAdded) {
                    setServicesToSubmit(
                      servicesToSubmit.map((serv) => {
                        if (serv.id == e.target.id) {
                          serv.attributes.quantity = e.target.value;
                          return serv;
                        } else {
                          return serv;
                        }
                      })
                    );
                  }
                  if (isServicesAdded) {
                    if (service.attributes.name !== "") {
                      updateService(service);
                    }
                  }
                }}
              />
            ),
          },
          {
            class: "col-2",
            name: (
              <Form.Control
                id={service.id}
                type="text"
                defaultValue={hours}
                onChange={(e) => {
                  if (!isServicesAdded) {
                    service.attributes["suggested-hours"] = e.target.value;
                  }
                  if (isServicesAdded) {
                    service.attributes["override-hours"] = e.target.value;
                  }
                }}
                onBlur={(e) => {
                  if (!isServicesAdded) {
                    setServicesToSubmit(
                      servicesToSubmit.map((serv) => {
                        if (serv.id == e.target.id) {
                          serv.attributes["override-hours"] = e.target.value;
                          return serv;
                        } else {
                          return serv;
                        }
                      })
                    );
                  }
                  if (isServicesAdded) {
                    setServices(
                      services.map((serv) => {
                        if (serv.id == e.target.id) {
                          serv.attributes["override-hours"] = e.target.value;
                          return serv;
                        } else {
                          return serv;
                        }
                      })
                    );
                    if (service.attributes.name !== "") {
                      updateService(service);
                    }
                  }
                }}
              />
            ),
          },
          {
            class: "col-3",
            name:
              service.attributes["service-type"] !== "managed_services" ? (
                <div>
                  <Select
                    id={service.id}
                    defaultValue={{
                      label: service.resource,
                      value:
                        service.relationships.resource &&
                        service.relationships.resource.data
                          ? service.relationships.resource.data.id
                          : null,
                    }}
                    onChange={(e) => {
                      let resource = e.value;
                      setServicesToSubmit(
                        servicesToSubmit.map((serv) => {
                          if (serv.id == service.id) {
                            serv.relationships.resource = {
                              data: {
                                type: "resources",
                                id: resource.id,
                              },
                            };
                            serv.resource = e.label;
                            return serv;
                          } else {
                            return serv;
                          }
                        })
                      );
                      service.relationships.resource.data = {
                        type: "resources",
                        id: service.relationships.resource.data
                          ? resource
                          : null,
                      };
                      service.resource = e.label;
                      if (isServicesAdded && service.attributes.name !== "") {
                        updateService(service);
                      }
                    }}
                    options={resources.map((resource) => {
                      return {
                        label: resource.attributes.name,
                        value: resource.id,
                      };
                    })}
                  />
                  {!service.resource ||
                  (service.resource == undefined &&
                    service.id.includes("new") == false) ? (
                    <small style={{ color: "red" }}>
                      You must select a resource to continue.
                    </small>
                  ) : null}
                </div>
              ) : null,
          },
        ],
        actions:
          service.type == "project-subservices" ||
          service.type == "subservices" ? (
            <FontAwesomeIcon
              onClick={() => confirmDeleteSubservice(service)}
              style={{ color: "#418172", cursor: "pointer" }}
              icon={faTrashCan}
            />
          ) : null,
      };
    });
  };

  const headerData = {
    rowClass: "serviceHeaderRow",
    columns: [
      {
        class: "col-5",
        name: isServicesAdded ? "Category & Service" : "Service Name",
      },
      {
        class: "col-2",
        name: "Quantity",
      },
      {
        class: "col-2",
        name: `${rateType}`,
      },
      {
        class: "col-3",
        name: "Resource",
      },
    ],
  };

  const subserviceHeaderData = {
    rowClass: "subserviceHeaderRow",
    columns: [],
  };

  const addSubservice = (service) => {
    let newSubservice = {
      id: `new-${Math.floor(Math.random() * 100)}`,
      type: "project-subservices",
      quantity: 1,
      attributes: {
        name: "",
        "override-hours": 0.0,
        quantity: 1,
        "suggested-hours": 0.0,
      },
      relationships: {
        resource: {
          data: {
            type: "resources",
            id: service.relationships.resource.data
              ? service.relationships.resource.data.id
              : null,
          },
        },
        "project-service": {
          data: {
            type: "project-services",
            id: service.id,
          },
        },
      },
    };
    let newServices = services.map((serv) => {
      if (!serv.subservices) {
        serv.subservices = [];
      }
      if (serv.id == service.id) {
        serv.subservices.push(newSubservice);
      }
      return serv;
    });
    if (!isServicesAdded) {
      newServices.push(newSubservice);
    }
    setServices(newServices);
    setServicesToSubmit(newServices);
  };

  const getSubserviceFooterData = (service) => {
    let content = (
      <Button
        onClick={() => addSubservice(service)}
        className="ssButtonBgWhite"
      >
        Add Subservice
      </Button>
    );

    return {
      rowClass: "col-12",
      columns: [{ class: "createFlowSubserviceFooter", content: content }],
    };
  };

  const changeAllPhaseAccordions = (change) => {
    changeAccordions(".phaseAccordion", change);
  };

  const changeAllServiceAccordions = (change) => {
    changeAccordions(".serviceAccordion", change);
  };

  const handleCancel = () => {
    setSubpage(sourcePage);
  };

  const circlePlusIcon = (
    <FontAwesomeIcon
      style={{
        marginRight: "10px",
        color: "#418172",
        cursor: "pointer",
        fontSize: "23px",
        fontWeight: "bolder",
        height: "25px",
        marginBottom: "-3px",
      }}
      icon={faCirclePlus}
    />
  );

  const account = useContext(AccountContext);

  return (
    <div
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Escape")
          setServices(
            services.map((serv) => {
              serv.contextMenuIsOpen = false;
              return serv;
            })
          );
      }}
      className="servicesByPhase"
    >
      {handleAlert(
        showFailAlert,
        errorMessages,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      {isServicesAdded ? (
        <div className="redirectButtons">
          {account.planDetails.questionnaires && (
            <div
              onClick={() => setSubpage("addByQuestionnaire")}
              className="accordionButton"
            >
              {circlePlusIcon} Add From Survey
            </div>
          )}
          <div
            onClick={() => setSubpage("addBlueprint")}
            className="accordionButton"
          >
            {circlePlusIcon} Add From Blueprint
          </div>
          <div
            onClick={() => setSubpage("addServicesFromStandard")}
            className="accordionButton"
          >
            {circlePlusIcon} Add From Standards
          </div>
          <div
            onClick={() => setSubpage("addCustomServices")}
            className="accordionButton"
          >
            {circlePlusIcon} Add Custom
          </div>
        </div>
      ) : null}
      <Form onSubmit={handleSubmit}>
        <Card style={{ padding: "20px" }}>
          <Card.Header
            style={{
              backgroundColor: "#fff",
              color: "#1C2655",
            }}
          >
            {isServicesAdded ? (
              <Row style={{ alignItems: "center" }}>
                <div style={{ marginRight: "10px" }}>
                  <FontAwesomeIcon
                    style={{
                      color: "#418172",
                      fontSize: "30px",
                      height: "36px",
                    }}
                    icon={faCircleCheck}
                  />
                </div>
                <div style={{ width: "80vw" }}>
                  <div className="accordianTitle">Services Added</div>
                </div>
                <div style={{ display: "flex" }}>
                  <Button
                    onClick={() => {
                      changeAllPhaseAccordions(
                        oneAccordionIsClosed ? "open" : "close"
                      );
                      changeAllServiceAccordions(
                        oneAccordionIsClosed ? "open" : "close"
                      );
                      setOneAccordionIsClosed(!oneAccordionIsClosed);
                    }}
                    className="ssButtonBgWhite"
                  >
                    {oneAccordionIsClosed ? "Expand All" : "Collapse All"}
                  </Button>
                </div>
              </Row>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  className="accordianTitle"
                  style={{ width: "75vw", margin: "0" }}
                >
                  {title}
                </div>
                <Button
                  style={{ marginRight: "10px" }}
                  onClick={() => handleCancel()}
                  className="ssButtonBgWhite"
                >
                  Cancel
                </Button>
                <Button
                  disabled={
                    servicesToSubmit.filter(
                      (s) =>
                        (!s.resource || s.resource == undefined) &&
                        s.attributes["service-type"] !== "managed_services"
                    ).length > 0 ||
                    servicesToSubmit.filter(
                      (s) =>
                        s.type === "services" &&
                        (s.relationships.phase.data === null ||
                          s.relationships.phase.data.id ===
                            "needsAssignment") &&
                        s.attributes["service-type"] !== "managed_services"
                    ).length > 0
                  }
                  type="submit"
                  className="seafoamBgButton"
                >
                  {submitButtonText}
                </Button>
              </div>
            )}
          </Card.Header>
          <hr />
          <DragDropContext onDragEnd={dragPhaseEnd}>
            <Droppable droppableId="PhaseDropZone">
              {(provided) => (
                <Card.Body ref={provided.innerRef} {...provided.droppableProps}>
                  {provided.placeholder}
                  {!isServicesAdded ? (
                    <div
                      style={{ fontSize: "20px", marginBottom: "30px" }}
                      className="accordianSubtitle"
                    >
                      {subtitle}
                    </div>
                  ) : null}
                  {phases.map((phase, phaseIndex) => {
                    let servicesOnPhase = [];
                    services.forEach((service) => {
                      let phaseDataOnService = {};
                      if (isServicesAdded) {
                        phaseDataOnService =
                          service.relationships["project-phase"].data;
                      } else if (service.relationships.phase) {
                        phaseDataOnService = service.relationships.phase.data;
                      }
                      if (
                        phaseDataOnService &&
                        phaseDataOnService.id === phase.id
                      ) {
                        servicesOnPhase.push(service);
                      }
                    });

                    let phaseHasServices = servicesOnPhase.length > 0;

                    let numberOfSubservicesOnPhase = servicesOnPhase
                      .map((s) =>
                        s.subservices
                          ? s.subservices.filter((sub) => !sub.deleted).length
                          : 0.0
                      )
                      .reduce((a, b) => {
                        return a + b;
                      }, 0);

                    let serviceHoursOnPhase = isServicesAdded
                      ? servicesOnPhase.map((s) =>
                          parseFloat(s.attributes["override-hours"])
                            ? parseFloat(s.attributes["override-hours"])
                            : 0.0
                        )
                      : servicesOnPhase.map((s) =>
                          parseFloat(s.attributes["suggested-hours"])
                            ? parseFloat(s.attributes["suggested-hours"])
                            : 0.0
                        );

                    let hoursOnServices = serviceHoursOnPhase.reduce((a, b) => {
                      return a + b;
                    }, 0);

                    let subservicesOnPhase = [];
                    servicesOnPhase.forEach((serv) => {
                      if (serv.subservices) {
                        serv.subservices.forEach((sub) => {
                          if (sub.attributes.quantity != sub.quantity) {
                            sub.quantity = sub.attributes.quantity;
                          }
                          subservicesOnPhase.push(sub);
                        });
                      }
                    });

                    let subserviceHoursOnPhase = isServicesAdded
                      ? subservicesOnPhase.map((s) =>
                          parseFloat(s.attributes["override-hours"])
                            ? parseFloat(s.attributes["override-hours"])
                            : 0.0
                        )
                      : subservicesOnPhase.map((s) =>
                          parseFloat(s.attributes["suggested-hours"])
                            ? parseFloat(s.attributes["suggested-hours"])
                            : 0.0
                        );

                    let hoursOnSubervices = subserviceHoursOnPhase.reduce(
                      (a, b) => {
                        return a + b;
                      },
                      0
                    );

                    let hoursOnPhase = hoursOnServices + hoursOnSubervices;

                    let assignedResourcesSet = new Set();
                    servicesOnPhase
                      .concat(subservicesOnPhase)
                      .forEach((item) => {
                        if (item.relationships.resource.data) {
                          assignedResourcesSet.add(
                            item.relationships.resource.data.id
                          );
                        }
                      });

                    let assignedResourcesArray =
                      Array.from(assignedResourcesSet);

                    const phaseButtons = [
                      {
                        key: 0,
                        text: `${servicesOnPhase.length} Service${
                          servicesOnPhase.length !== 1 ? "s" : ""
                        }`,
                        icon: faWrench,
                      },
                      {
                        key: 1,
                        text: `${numberOfSubservicesOnPhase} Subservice${
                          numberOfSubservicesOnPhase !== 1 ? "s" : ""
                        }`,
                        icon: faScrewdriverWrench,
                      },
                      {
                        key: 2,
                        text: `${
                          phase.attributes.name == "No Phase"
                            ? 0
                            : assignedResourcesArray.length
                        } Assigned Resource${
                          assignedResourcesArray.length !== 1 ||
                          phase.attributes.name == "No Phase"
                            ? "s"
                            : ""
                        }`,
                        icon: faUsers,
                      },
                      {
                        key: 3,
                        text: `${hoursOnPhase.toFixed(2)} Unit Hour${
                          hoursOnPhase !== 1 ? "s" : ""
                        }`,
                        icon: faClock,
                      },
                    ];

                    const phaseMenuItemsForContext = (phase) => {
                      if (phaseHasServices === false) {
                        return [
                          {
                            newTab: false,
                            onClick: () => confirmDeletePhase(phase),
                            iconClass: faTrash,
                            style: { padding: "0 0.5em 0 0" },
                            name: <span>Delete</span>,
                            hasBreakingLine: false,
                            disabled: false,
                          },
                        ];
                      } else if (phaseHasServices) {
                        if (phase.id !== "noPhase") {
                          return [
                            {
                              newTab: false,
                              onClick: () => {
                                phase.modalShowing = true;
                              },
                              iconClass: faPencil,
                              style: { padding: "0 0.5em 0 0" },
                              name: <span>Rename</span>,
                              hasBreakingLine: true,
                              disabled: false,
                            },
                            {
                              newTab: false,
                              onClick: () => null,
                              style: {
                                padding: "0 0.5em 0 0",
                                backgroundColor: "#D9D9D9",
                                color: "#46474A",
                              },
                              iconClass: false,
                              name: (
                                <span>Remove all services to delete phase</span>
                              ),
                              hasBreakingLine: false,
                              disabled: true,
                            },
                          ];
                        } else {
                          return [
                            {
                              newTab: false,
                              onClick: () => null,
                              style: {
                                padding: "0 0.5em 0 0",
                                backgroundColor: "#D9D9D9",
                                color: "#46474A",
                              },
                              iconClass: false,
                              name: (
                                <span>Remove all services to delete phase</span>
                              ),
                              hasBreakingLine: false,
                              disabled: true,
                            },
                          ];
                        }
                      }
                    };

                    const phaseHeader = (
                      <div>
                        <div
                          style={{
                            width: "22vw",
                          }}
                          className="accordianTitle"
                        >
                          {phase.attributes ? phase.attributes.name : "None"}

                          <span>
                            {phasePermissions && isServicesAdded ? (
                              <FontAwesomeIcon
                                style={{
                                  fontSize: "20px",
                                  marginLeft: "10px",
                                  color: "#418172",
                                  cursor: "pointer",
                                }}
                                icon={faGear}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let width = e.pageX / 1.5;
                                  setContextX(width);
                                  phase.contextMenuIsOpen =
                                    !phase.contextMenuIsOpen;
                                  document.addEventListener(
                                    "click",
                                    closePhaseContextMenu
                                  );
                                  setPhases(
                                    phases.map((p) => {
                                      if (p.id == phase.id) {
                                        p.contextMenuIsOpen = true;
                                      } else {
                                        p.contextMenuIsOpen = false;
                                      }
                                      return p;
                                    })
                                  );
                                  setServices(
                                    services.map((serv) => {
                                      serv.contextMenuIsOpen = false;
                                      return serv;
                                    })
                                  );
                                }}
                              />
                            ) : null}
                          </span>
                          {phase.contextMenuIsOpen && isServicesAdded ? (
                            <div className="phaseContextMenu">
                              <ContextMenu
                                menuItems={phaseMenuItemsForContext(phase)}
                                style={{
                                  zIndex: "100",
                                  top: "30%",
                                  left: contextX,
                                }}
                              />
                            </div>
                          ) : null}
                          {renamePhaseModal(phase)}
                        </div>
                        <hr />
                        {phase.attributes.name === "Needs Assignment" && (
                          <div className="reassignContainer">
                            <span className="reassignAlert">
                              <strong>
                                Please assign these services to a phase!
                              </strong>{" "}
                              The phase that they were originally assigned to
                              has been deprecated.
                            </span>
                          </div>
                        )}
                        <div
                          style={{
                            width: "85vw",
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "10px",
                          }}
                        >
                          {phaseButtons.map((item) => (
                            <div
                              key={item.key}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <FontAwesomeIcon
                                className="smallInfoGrey"
                                style={{
                                  marginRight: "5px",
                                }}
                                icon={item.icon}
                              />
                              <div
                                className="smallInfoGrey"
                                style={{ margin: "10px 15px 10px 0" }}
                              >
                                {item.text}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    );

                    const serviceAccordions = servicesOnPhase.map(
                      (service, serviceIndex) => {
                        let subservicesOnService = [];
                        subservicesOnService = service.subservices
                          ? service.subservices.sort(
                              (a, b) =>
                                a.attributes.position - b.attributes.position
                            )
                          : [];

                        subservicesOnService = subservicesOnService.map(
                          (subservice) => {
                            subservice.attributes = {
                              ...subservice.attributes,
                              "service-type":
                                service.attributes["service-type"],
                            };
                            return subservice;
                          }
                        );
                        const servicesTable = new ScopeStackTable(
                          "services",
                          headerData,
                          getTableData([service]),
                          null
                        );

                        const subservicesTable = new DraggableTableBuilder(
                          "subservices",
                          subserviceHeaderData,
                          getTableData(subservicesOnService),
                          getSubserviceFooterData(service),
                          !isServicesAdded
                        );

                        return (
                          <Draggable
                            key={service.id}
                            draggableId={service.id}
                            index={serviceIndex}
                            isDragDisabled={
                              !isServicesAdded ||
                              service.accordianState == "open"
                            }
                          >
                            {(provided) => (
                              <div
                                id={service.id}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                {provided.placeholder}
                                <span style={{ display: "flex" }}>
                                  {isServicesAdded ? (
                                    <FontAwesomeIcon
                                      style={{
                                        fontSize: "20px",
                                        marginRight: "10px",
                                        marginTop: "40px",
                                        color: "#808080",
                                        cursor: "pointer",
                                      }}
                                      icon={faGripDotsVertical}
                                      onMouseOver={() =>
                                        setServices(
                                          services.map((serv) => {
                                            serv.accordianState = "closed";
                                            return serv;
                                          })
                                        )
                                      }
                                      onMouseDown={() => {
                                        changeAllServiceAccordions("close");
                                      }}
                                    />
                                  ) : null}
                                  <AccordionCard
                                    id={service.id}
                                    className="serviceAccordion"
                                    header={
                                      <BaseTable
                                        className="accordionTable createFlowServicesTable"
                                        striped={false}
                                        hover={false}
                                        bordered={false}
                                        headerRows={servicesTable.buildHeaderRows()}
                                        dataRows={servicesTable.buildDataRows()}
                                        footerRows={servicesTable.buildFooterRows()}
                                        isLoading={isLoading}
                                      />
                                    }
                                    body={
                                      <DraggableTable
                                        className="accordionTable createFlowSubservicesTable"
                                        striped={false}
                                        hover={false}
                                        bordered={false}
                                        headerRows={
                                          subservicesTable
                                            ? subservicesTable.buildHeaderRows()
                                            : null
                                        }
                                        dataRows={
                                          subservicesTable
                                            ? subservicesTable.buildDataRows()
                                            : null
                                        }
                                        footerRows={
                                          subservicesTable
                                            ? subservicesTable.buildFooterRows()
                                            : null
                                        }
                                        isLoading={isLoading}
                                        onDragEnd={onDragSubserviceEnd}
                                        onDragStart={() =>
                                          setSubserviceListInDrag(
                                            subservicesOnService
                                          )
                                        }
                                        droppableId={"SubserviceRow"}
                                      />
                                    }
                                    iconOpen={faArrowDown}
                                    iconClosed={faArrowRight}
                                    closeable={true}
                                    iconHexCode="#1c2655"
                                    headerBackgroundHexCode="#eee"
                                    cardBackgroundHexCode="#eee"
                                    cardClass="serviceAccordion"
                                    setAccordianIsOpen={(accordianState) => {
                                      services.map((service) => {
                                        if (service.id === service.id) {
                                          service.accordianState =
                                            accordianState === true
                                              ? "open"
                                              : "closed";
                                          if (
                                            service.accordianState === "closed"
                                          ) {
                                            setOneAccordionIsClosed(true);
                                          }
                                          return service;
                                        } else {
                                          return service;
                                        }
                                      });
                                    }}
                                    startingAccordionState={"open"}
                                  />
                                </span>
                              </div>
                            )}
                          </Draggable>
                        );
                      }
                    );

                    return (
                      <Draggable
                        key={phase.id}
                        draggableId={phase.id.toString()}
                        index={phaseIndex}
                        isDragDisabled={
                          !isServicesAdded ||
                          phase.accordianState == "open" ||
                          !phasePermissions
                        }
                      >
                        {(provided) => (
                          <div
                            id={phase.id}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            {provided.placeholder}
                            <span style={{ display: "flex" }}>
                              {isServicesAdded && phasePermissions ? (
                                <FontAwesomeIcon
                                  style={{
                                    fontSize: "20px",
                                    marginRight: "10px",
                                    marginTop: "30px",
                                    color: "#808080",
                                    cursor: "pointer",
                                  }}
                                  icon={faGripDotsVertical}
                                  onMouseOver={() =>
                                    setPhases(
                                      phases.map((p) => {
                                        p.accordianState = "closed";
                                        return p;
                                      })
                                    )
                                  }
                                  onMouseDown={() => {
                                    changeAllPhaseAccordions("close");
                                  }}
                                />
                              ) : null}
                              <AccordionCard
                                className="phaseAccordion"
                                header={phaseHeader}
                                body={
                                  <DragDropContext
                                    onDragStart={() =>
                                      setServiceListInDrag(servicesOnPhase)
                                    }
                                    onDragEnd={dragServicesEnd}
                                  >
                                    <Droppable
                                      droppableId={phase.id.toString()}
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.droppableProps}
                                        >
                                          {provided.placeholder}
                                          {[serviceAccordions]}
                                        </div>
                                      )}
                                    </Droppable>
                                  </DragDropContext>
                                }
                                iconOpen={faArrowDown}
                                iconClosed={faArrowRight}
                                closeable={true}
                                iconHexCode="#1c2655"
                                headerBackgroundHexCode="#fff"
                                setAccordianIsOpen={(accordianState) => {
                                  phases.map((p) => {
                                    if (p.id === phase.id) {
                                      p.accordianState =
                                        accordianState === true
                                          ? "open"
                                          : "closed";
                                      if (p.accordianState === "closed") {
                                        setOneAccordionIsClosed(true);
                                      }
                                      return p;
                                    } else {
                                      return p;
                                    }
                                  });
                                }}
                                startingAccordionState={"open"}
                              />
                            </span>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </Card.Body>
              )}
            </Droppable>
          </DragDropContext>
        </Card>
      </Form>
      <ConfirmationModal
        show={showPhaseConfirmation}
        title="Delete Phase"
        message="Are you sure you want to delete this phase?"
        onConfirm={deletePhase}
        onCancel={() => {
          setPhaseToDelete({}), setShowPhaseConfirmation(false);
        }}
      />
      <ConfirmationModal
        show={showSubserviceConfirmation}
        title="Delete Subservice"
        message="Are you sure you want to delete this subservice?"
        onConfirm={deleteSubservice}
        onCancel={() => {
          setSubserviceToDelete({}), setShowSubserviceConfirmation(false);
        }}
      />
      <ConfirmationModal
        show={showServiceConfirmation}
        title="Delete Service"
        message="Are you sure you want to delete this service?"
        onConfirm={deleteService}
        onCancel={() => {
          setServiceToDelete({}), setShowServiceConfirmation(false);
        }}
      />
    </div>
  );
}

export default ServicesByPhase;

import { useState, useEffect } from "react";
import "./styles.css";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ConfirmationModal from "@components/Modals/ConfirmationModal";
import { faX } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PaginationNumbersAPI from "@components/Buttons/PaginationAPI/PaginationNumbersAPI";
import { useSelector } from "react-redux";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import { RootState } from "@reducers/rootReducer";
import {
  useGetUsersQuery,
  useRemoveUserMutation,
  useGetSubscriptionQuery,
} from "../../../../services/ScopeStackAPI";
import { Users, DeleteUserInput, Privilege } from "app/javascript/src/types";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { Form } from "react-bootstrap";
import useOauth from "@utils/customHooks/useOauth";
import API from "../../../../utils/API/API";
import { AddOns } from "../../../../types";
import useWhoAmI from "../api/useWhoAmI";
import useListUsers from "../api/useListUsers";

const Licenses = (): JSX.Element => {
  const chargeBeeEnv = process.env.CHARGEBEE_ENV;
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const [authorizationCode] = useOauth();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const {
    data: subscription,
    error: subscriptionError,
    isLoading: subscriptionLoading,
  } = useGetSubscriptionQuery(null);

  const {
    data: usersOnAccount,
    isLoading: usersLoading,
    pageMeta: usersMeta,
    refetch,
  } = useListUsers({
    slug: accountSlug,
    currentPage,
  });

  const { data: currentUser, error, isLoading: userLoading } = useWhoAmI();
  const [permissions, setPermissions] = useState<string>("");
  const [deleteUserMutation] = useRemoveUserMutation();
  const [users, setUsers] = useState<Users>({
    data: [],
    meta: { "page-count": 0, "record-count": 0 },
  });
  const [pageArray, setPageArray] = useState<number[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState<Boolean>(false);
  const [userToDelete, setUserToDelete] = useState({
    id: "",
    attributes: { name: "", email: "" },
  });
  const [userLimit, setUserLimit] = useState(0);
  const [cbInstance, setCbInstance] = useState<any>(null);
  const [addOns, setAddOns] = useState<AddOns[]>([]);

  const [showFailAlert, setShowFailAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const errorMessage = "Something went wrong, your changes were not saved.";

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.chargebee.com/v2/chargebee.js";
    document.getElementsByTagName("head")[0].appendChild(script);
  }, []);

  if (
    document.readyState === "complete" &&
    window["Chargebee"] &&
    !cbInstance
  ) {
    //Create chargbee instance
    let instance = window["Chargebee"].init({ site: chargeBeeEnv });
    instance.setBusinessEntity(accountSlug);
    setCbInstance(instance);
  }
  useEffect(() => {
    if (subscription) {
      setAddOns(subscription.data.attributes.addons);
    }
    if (!usersLoading && currentUser) {
      const buildPageArray = (() => {
        const pageArrayToReturn: number[] = [];
        if (usersMeta) {
          for (let i = 1; i <= usersMeta["page-count"]; i += 1) {
            pageArrayToReturn.push(i);
          }
        } else {
          pageArrayToReturn.push(1);
        }
        return pageArrayToReturn;
      })();
      if (subscription) {
        if (
          subscription.data.attributes["max-users"] === "" ||
          subscription.data.attributes["max-users"] === null
        ) {
          setUserLimit(1);
        } else {
          setUserLimit(Number(subscription.data.attributes["max-users"]));
        }
      }
      setPageArray(buildPageArray);
      setUsers({ data: usersOnAccount, meta: usersMeta });
      const permission: Privilege | undefined =
        currentUser?.attributes?.privileges?.find(
          (p) => p.privilege == "settings.billing"
        );
      if (permission?.accessLevel) {
        setPermissions(permission.accessLevel);
      } else {
        setPermissions("");
      }
    }
  }, [
    usersOnAccount,
    userLoading,
    subscription,
    subscriptionError,
    currentPage,
  ]);

  const headerData = {
    rowClass: "",
    columns: [
      ,
      {
        class: "columnHeading",
        name: "Licenses on account",
      },
      {
        class: "columnHeading",
        name: "Email",
      },
    ],
  };

  const tableData = users.data.map((user: any, i: number) => {
    return {
      rowClass: "",
      columns: [
        {
          class: "userColumn",
          name: user.attributes.name,
        },
        {
          class: "emailColumn",
          name: (
            <div className="email">
              <span>{user.attributes.email} </span>
              {permissions === "manage" && (
                <button
                  className="deleteUser"
                  onClick={() => confirmDeleteUser(user)}
                >
                  <FontAwesomeIcon icon={faX} />
                </button>
              )}
            </div>
          ),
        },
      ],
    };
  });

  const usersListTable = new ScopeStackTable(
    "licenses",
    headerData,
    tableData,
    null
  );

  const updateLicenses = (event) => {
    event.preventDefault();
    if (cbInstance) {
      cbInstance.openCheckout({
        hostedPage: async () => {
          let upgradeData = {};
          if (subscription) {
            upgradeData = {
              data: {
                type: "purchase-pages",
                attributes: {
                  mode: "upgrade",
                  "subscription-items": [
                    {
                      item_price_id: subscription.data.attributes["plan-id"],
                      quantity: userLimit,
                    },
                    ...addOns
                      .filter((addOn) => addOn?.id !== "test_sso-USD-Monthly")
                      .map((addOn) => {
                        return {
                          item_price_id: addOn.id,
                          quantity: 1,
                        };
                      }),
                  ],
                },
              },
            };
          }

          let response = await API.Post(
            `${apiHost}/v1/purchase-pages`,
            upgradeData,
            authorizationCode
          );
          return response.data.data.attributes["hosted-page"];
        },
        error: function (error) {
          setShowFailAlert(true);
        },
        // When the chargebee dialog has done it's magic, We can do whatever makes sense here
        success: function (hostedPageId) {
          setSuccessMessage("You will receive an email shortly.");
          setShowSuccessAlert(true);
        },
        close: function () {
          window.location.reload();
        },
      });
    }
  };
  const confirmDeleteUser = (user) => {
    setShowDeleteModal(true);
    setUserToDelete(user);
  };

  const deleteUser = async () => {
    try {
      const user: DeleteUserInput = {
        data: {
          id: userToDelete.id,
          type: "users",
        },
      };

      deleteUserMutation({
        slug: accountSlug,
        userInfo: user,
      })
        .then(() => {
          refetch();
        })
        .catch((error) => {
          console.log(error);
        });

      setUsers((prevUsers) => {
        const updatedUsers = {
          ...prevUsers,
          data: prevUsers.data.filter(
            (userData) => String(userData.id) !== userToDelete.id
          ),
        };
        return updatedUsers;
      });

      setSuccessMessage("User has been deleted");
      setShowSuccessAlert(true);
      setShowDeleteModal(false);

      //if deleting last user on current page
      if (users.data.length <= 1) {
        let response = await refetch();
        setCurrentPage(currentPage - 1);
        setPageArray(pageArray.slice(0, pageArray.length - 1));
      }
    } catch (error) {
      setShowFailAlert(true);
    }
  };

  return (
    <>
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      {!usersLoading && !userLoading && !subscriptionLoading ? (
        <div className="usersTabContainer">
          {users.meta["record-count"] >= userLimit &&
            userLimit > 1 &&
            permissions === "manage" && (
              <div className="userLimitWarning">
                You’ve used all your licenses, <strong>add more</strong> or
                <strong> upgrade your plan.</strong>
              </div>
            )}
          <div className="usersTableCard">
            <div className="usersHeader">
              <h2 className="userHeading">Licenses</h2>
              <span>
                Licenses Used:{" "}
                <strong>
                  {usersMeta["record-count"]}/
                  {userLimit === 1 ? "Unlimited" : userLimit}
                </strong>
              </span>
            </div>
            <div className="usersTableContainer">
              <BaseTable
                className="sansTable usersTable"
                striped={true}
                hover={true}
                bordered={true}
                headerRows={usersListTable.buildHeaderRows()}
                dataRows={usersListTable.buildDataRows()}
                footerRows={usersListTable.buildFooterRows()}
                isLoading={false}
              />
              {pageArray.length > 1 && (
                <PaginationNumbersAPI
                  pageCount={users.meta["page-count"]}
                  pageArray={pageArray}
                  currentPage={currentPage}
                  setCurrentPage={(page) => setCurrentPage(page)}
                  leftArrowOnClick={() => {
                    if (currentPage > 1) {
                      setCurrentPage(currentPage - 1);
                    }
                  }}
                  rightArrowOnClick={() => {
                    if (currentPage < users.meta["page-count"]) {
                      setCurrentPage(currentPage + 1);
                    }
                  }}
                  itemsPerPage={3}
                  onNumberClick={(_, currentPage) => {
                    setCurrentPage(currentPage);
                  }}
                />
              )}
            </div>
            <div className="footerBtns">
              {permissions === "manage" && (
                <Form onSubmit={updateLicenses}>
                  <button className="btnSeafoam getLicenses" type="submit">
                    Get more licenses
                  </button>
                </Form>
              )}
            </div>
          </div>

          <ConfirmationModal
            show={showDeleteModal}
            title="Delete User"
            message="Are you sure you want to delete this user?"
            onConfirm={deleteUser}
            onCancel={() => setShowDeleteModal(false)}
          />
        </div>
      ) : (
        <SmallSpinner />
      )}
    </>
  );
};
export default Licenses;

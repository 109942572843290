import API from "@API";

const getNewCustomService = async ({
  service,
  projectId,
  rateType,
  account_slug,
  authorizationCode,
}) => {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const url = new URL(`/${account_slug}/v1/project-services`, apiHost);
  url.searchParams.append(
    "include",
    "service-category,resource,project-phase,project-subservices"
  );

  const payload = {
    data: {
      relationships: {
        project: {
          data: {
            type: "projects",
            id: projectId,
          },
        },
        "service-category": {
          data: {
            type: "service-categories",
            id: service.category,
          },
        },
        resource: {
          data: {
            type: "resources",
            id: service.resource,
          },
        },
        "project-phase": {
          data: {
            type: "project-phases",
            id: service.phase,
          },
        },
      },
      attributes: {
        quantity: service.qty,
        "task-source": "custom",
        name: service.name,
      },
      type: "project-services",
    },
  };
  payload.data.attributes[`override-${rateType}`] = service.loe;

  try {
    const response = await API.Post(url.toString(), payload, authorizationCode);

    return { data: response.data.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export default getNewCustomService;

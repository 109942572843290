import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { useGetServiceByIdQuery } from "@generated";
import { Service } from "../types";

const useGetServiceById = (id) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null; // Declare customError

  const { data, error, isLoading, refetch, isFetching } =
    useGetServiceByIdQuery({
      slug: accountSlug,
      id,
      include: ["resource", "phase", "service-category", "subservices"],
    });

  let service: Service | undefined = undefined;

  if (data?.data) {
    try {
      const phaseId = data.data?.relationships?.phase?.data?.id;
      const categoryId =
        data.data?.relationships?.["service-category"]?.data?.id;
      const sku = data.data?.attributes?.sku;
      const resource = data.data?.relationships?.resource?.data?.id;
      const hours = data.data?.attributes?.["suggested-hours"];
      const tags = data.data?.attributes?.["tag-list"]?.map((tag) => {
        return { value: tag, label: tag };
      });

      service = {
        id: String(data.data.id),
        name: data.data.attributes.name,
        phase: phaseId ? String(phaseId) : "",
        category: categoryId ? String(categoryId) : "",
        sku: sku ? String(sku) : "",
        resource: resource ? String(resource) : "",
        hours: hours ? String(hours) : "",
        teams:
          data.data?.attributes?.teams?.map((t) => {
            return { value: t?.id || "", label: t?.name || "" };
          }) || [],
        tags: tags || [],
        frequency: data.data?.attributes?.["payment-frequency"] || "",
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }
  return {
    service,
    isLoading,
    error: error ?? customError,
    refetch,
    isFetching,
  };
};
export default useGetServiceById;

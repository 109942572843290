import API from "@API";
import { formatUnprocessibleResponse } from "@utils/helperFunctions";
import React from "react";

const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

export const arrangeDataForTable = (services) => {
  let tableArray = [];
  services.forEach((service) => {
    tableArray.push(service);
    if (service.subservices) {
      service.subservices.forEach((subservice) => {
        subservice.quantity = service.quantity;
        subservice.hours = service.attributes["suggested-hours"];
        subservice.resource = service.resource;
        if (
          !subservice.relationships.resource ||
          !subservice.relationships.resource.data
        ) {
          subservice.relationships.resource = {
            data: service.relationships.resource.data,
          };
        }
        if (subservice.attributes.state === "active") {
          tableArray.push(subservice);
        }
      });
    }
  });
  return tableArray;
};

export const arrangeServicesForSubmission = (servicesToSubmit, projectId) => {
  let services = [];
  servicesToSubmit.forEach((service) => {
    if (service.type == "services") {
      service.type = "project-services";
      service.attributes = {
        name: service.serviceName,
        quantity: service.quantity,
        "override-hours": service.attributes["override-hours"],
      };
      service.relationships = {
        project: { data: { type: "projects", id: projectId } },
        resource: {
          data: {
            type: "resources",
            id: service.relationships.resource.data
              ? service.relationships.resource.data.id
              : null,
          },
        },
        service: { data: { type: "services", id: service.id } },
      };
      delete service.quantity;
      delete service.subservices;
      delete service.resource;
      delete service.serviceName;
      delete service.id;
      delete service.links;
      delete service.name;
      delete service.category;
      delete service.lob;
      delete service.phase;
      delete service.tags;
      services.push(service);
    }
  });
  return services;
};

export const processAndPostServiceData = (
  services,
  account_slug,
  serviceType,
  authorizationCode
) => {
  let service = services.shift();
  let serviceData = { data: service };
  if (service) {
    if (
      (service.attributes["task-source"] &&
        service.attributes["task-source"] === "managed") ||
      (service.attributes["service-type"] &&
        service.attributes["service-type"] === "managed_services")
    ) {
      delete serviceData.data.relationships.resource;
    }
    API.Post(
      `${apiHost}/${account_slug}/v1/${serviceType}`,
      serviceData,
      authorizationCode
    ).then((response) => {
      if (services.length !== 0) {
        processAndPostServiceData(
          services,
          account_slug,
          serviceType,
          authorizationCode
        );
      }
    });
  }
};

export const updateSingleService = (
  service,
  account_slug,
  serviceType,
  authorizationCode
) => {
  let serviceData = { data: service };
  API.Patch(
    `${apiHost}/${account_slug}/v1/${serviceType}/${service.id}`,
    serviceData,
    authorizationCode
  );
};

export const resolveServicePromises = (
  promisesToResolve,
  submitFail,
  setSubmitFail,
  setErrorMessages,
  setShowFailAlert
) => {
  Promise.all(promisesToResolve)
    .then((res) => {
      let responses = res;
      for (let i = 0; i < responses.length; i++) {
        if (responses[i].status !== 201) {
          setSubmitFail(true);
        }
      }
    })
    .catch((err) => {
      setSubmitFail(true);
      setErrorMessages(formatUnprocessibleResponse(err, "service"));
      setShowFailAlert(true);
    });
};

export const getProjectServices = (
  account_slug,
  projectId,
  authorizationCode
) => {
  return API.Get(
    `${apiHost}/${account_slug}/v1/project-services?filter[project]=${projectId}&include=service,project-subservices,project-subservices.resource,project-subservices.subservice`,
    authorizationCode
  );
};

export const updateSubservices = (
  subservices,
  account_slug,
  authorizationCode
) => {
  let promises = [];
  let id = "";
  subservices.forEach((subservice) => {
    id = subservice.id;
    subservice.type = "project-subservices";
    subservice.attributes = {
      name: subservice.attributes.name,
      quantity: subservice.attributes.quantity,
      "override-hours": subservice.attributes["override-hours"],
    };
    let resourceData = {
      data: {
        type: "resources",
        id: subservice.relationships.resource.data
          ? subservice.relationships.resource.data.id
          : null,
      },
    };
    subservice.relationships = {
      subservice: {
        data: {
          type: "subservices",
          id:
            subservice.relationships.subservice &&
            subservice.relationships.subservice.data
              ? subservice.relationships.subservice.data.id
              : null,
        },
      },
      resource: resourceData,
    };
    delete subservice.quantity;
    delete subservice.resource;
    delete subservice.hours;
    delete subservice.links;
    delete subservice.subservices;
    delete subservice.deleted;
    if (!subservice.relationships.subservice) {
      delete subservice.relationships.subservice;
    }
    let serviceData = { data: subservice };
    let method = "PATCH";
    let url = `${apiHost}/${account_slug}/v1/project-subservices/${id}`;
    if (id && id.includes("new")) {
      url = `${apiHost}/${account_slug}/v1/project-subservices`;
      method = "POST";
      delete subservice.id;
      subservice.relationships = {
        "project-service": {
          data: {
            type: "project-services",
            id: subservice.serviceId,
          },
        },
        resource: resourceData,
      };
      delete subservice.serviceId;
    }
    promises.push(API.Submit(method, url, serviceData, authorizationCode));
  });
  return promises;
};

export const deleteProjectSubservices = (
  subservices,
  account_slug,
  authorizationCode
) => {
  let promises = [];
  subservices.forEach((subservice) => {
    let subserviceData = subservice;
    promises.push(
      API.Delete(
        `${apiHost}/${account_slug}/v1/project-subservices/${subservice.data.id}`,
        subserviceData,
        authorizationCode
      )
    );
  });
  return promises;
};

export const getAccordianHeaderMarkup = (title, subtitle) => (
  <div className="accordionHeaderContent" style={{ width: "80vw" }}>
    <div className="accordianTitle">{title}</div>
    <div className="accordianSubtitle">{subtitle}</div>
  </div>
);

export const changeAccordions = (className, change) => {
  document
    .querySelectorAll(`.details ${className} #openCloseToggle`)
    .forEach((element) => {
      if (change == "open" && !element.classList.contains("open")) {
        element.click();
      }
      if (change == "close" && element.classList.contains("open")) {
        element.click();
      }
    });
};

export const updatePhase = (phase, account_slug, authorizationCode) => {
  let phaseData = { data: phase };
  return API.Patch(
    `${apiHost}/${account_slug}/v1/project-phases/${phase.id}`,
    phaseData,
    authorizationCode
  );
};

export const arrangeDataByPhase = (
  servicesArray,
  phasesOnAccount,
  phases,
  setPhases
) => {
  let services = servicesArray;
  let servicesWithoutPhases = services.filter(
    (service) =>
      service.attributes["service-type"] === "managed_services" ||
      !service.relationships.phase.data ||
      service.relationships.phase.data.id == null
  );
  //Add any professional services without an active phase to "Needs Assignment"
  let professionalServices = services.filter(
    (service) => service.attributes["service-type"] === "professional_services"
  );
  let servicesNeedingReassignment = professionalServices.filter(
    (service) =>
      phasesOnAccount.filter(
        (p) =>
          !service.relationships.phase.data ||
          p.id === service.relationships.phase.data.id
      ).length == 0
  );

  if (servicesWithoutPhases.length > 0) {
    if (phases.filter((p) => p.id === "noPhase").length === 0) {
      let phasesData = [
        ...phases,
        {
          id: "noPhase",
          attributes: { name: "No Phase" },
        },
      ];
      setPhases(phasesData);
    }

    services = services.map((service) => {
      servicesWithoutPhases.forEach((serv) => {
        if (serv.id === service.id) {
          service.relationships.phase = {
            data: { type: "phases", id: "noPhase" },
          };
        }
      });
      return service;
    });
  }

  if (servicesNeedingReassignment.length > 0) {
    if (phases.filter((p) => p.id === "needsAssignment").length === 0) {
      let phasesData = [
        {
          id: "needsAssignment",
          attributes: { name: "Needs Assignment" },
        },
        ...phases,
      ];
      setPhases(phasesData);
    }

    services = services.map((service) => {
      servicesNeedingReassignment.forEach((serv) => {
        if (serv.id === service.id) {
          service.relationships.phase = {
            data: { type: "phases", id: "needsAssignment" },
          };
        }
      });
      return service;
    });
  }

  let servicesByPhaseSet = [];
  services.forEach((service) => {
    let phaseRelDataOnService = service.relationships.phase.data;
    phases.forEach((phase) => {
      if (phaseRelDataOnService && phaseRelDataOnService.id == phase.id) {
        servicesByPhaseSet.push(service);
      }
    });
  });

  let servicesByPhase = servicesByPhaseSet;

  return services;
};

import { ListAuditLogsApiArg, useListAuditLogsQuery } from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { AuditLog } from "../types/AuditLog";
import React from "react";

export interface HookArgs {
  filter?: ListAuditLogsApiArg["filter"];
  page?: ListAuditLogsApiArg["page"];
}

const useListAuditLogs = ({ filter, page }: HookArgs) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { data, error, isLoading, refetch } = useListAuditLogsQuery({
    slug: accountSlug,
    filter,
    sort: ["-created-at"],
    page
  });

  let auditLogs: AuditLog[] = [];
  let customError: string | null = null;
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  const simplifyObjectChanges = (changeSet) => {
    changeSet = Object.entries(changeSet);
    changeSet = changeSet.map((arr) => {
      arr.splice(1, 0, ": ");
      return arr.join("");
    });
    return changeSet.map((item, idx) => {
      return item;
    });
  };

  if (data?.data) {
    try {
      auditLogs = data.data.map((auditLog) => {
        if (!auditLog.id) {
          throw new Error("Missing auditLog.id");
        }
        const options: any = { month: "long", day: "numeric", year: "numeric" };

        return {
          id: String(auditLog.id),
          name: auditLog?.attributes?.name || "",
          createdAt: auditLog?.attributes?.["created-at"]
            ? new Date(auditLog?.attributes?.["created-at"])
            : new Date(),
          changedBy: auditLog?.attributes?.["changed-by"] || "",
          event: auditLog?.attributes?.event || "",
          changes:
            auditLog?.attributes?.changes?.map((change, index) => {
              let changedFrom: null | string = change["changed_from"] || null;
              let changedTo: null | string = change["changed_to"] || null;
              if (changedFrom && changedFrom.toString().includes("-")) {
                let date = new Date(changedFrom);
                changedFrom = date.toLocaleDateString("en-US", options);
              }
              if (changedTo && changedTo.toString().includes("-")) {
                let date = new Date(changedTo);
                changedTo = date.toLocaleDateString("en-US", options);
              }
              if (changedFrom && typeof changedFrom === "object") {
                changedFrom = simplifyObjectChanges(changedFrom);
              }
              if (changedTo && typeof changedTo === "object") {
                let objectHasArray = false;
                const changedToArray = Object.entries(changedTo) || [];
                changedToArray.forEach(([key, value]) => {
                  if (Array.isArray(value)) {
                    objectHasArray = true;
                    changedTo?.[key]?.forEach((item) => {
                      changedTo = simplifyObjectChanges(item);
                    });
                  }
                });
                if (!objectHasArray) {
                  changedTo = simplifyObjectChanges(changedTo);
                }
              }
              return {
                id: change.attribute || index.toString(),
                attribute: change.attribute || "",
                changedFrom: changedFrom,
                changedTo: changedTo,
              };
            }) || [],
        };
      });

      if (!data?.["meta"]) {
        throw new Error("Missing data.meta");
      }

      if (!data?.["meta"]?.["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data?.["meta"]?.["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data?.["meta"]?.["record-count"],
        pageCount: data?.["meta"]?.["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return {
    auditLogs: auditLogs,
    auditLogsError: error || customError,
    auditLogsLoading: isLoading,
    pageMeta,
    refetch,
  };
};

export default useListAuditLogs;

import { faCirclePlus } from "@fortawesome/pro-light-svg-icons";
import React, { useState, useEffect, useContext } from "react";
import AccordianDrop from "@components/AccordianDrop/AccordianDrop";
import CreateProjectFooter from "../CreateProjectFooter/CreateProjectFooter";
import { Row, Col } from "react-bootstrap";
import "../NewProjectDetails/NewProjectDetails.css";
import AddByBlueprint from "./Blueprint/AddByBlueprint";
import ApplyBlueprint from "./Blueprint/ApplyBlueprint";
import Standard from "./Standard/Standard";
import ServicesAdded from "./ServicesAdded/ServicesAdded";
import ApplyStandards from "./Standard/ApplyStandards";
import useProjectServicesData from "./api/useProjectServicesData";
import CustomService from "./CustomService/CustomService";
import { getAccordianHeaderMarkup } from "./serviceHelpers";
import ApplyCustomService from "./CustomService/ApplyCustomService";
import Questionnaire from "./Questionnaire/Questionnaire";
import API from "@API";
import ApplyQuestionnaire from "./Questionnaire/ApplyQuestionnaire";
import { AccountContext } from "../../ProjectsMspa";

function Services({
  account_slug,
  authorizationCode,
  projectId,
  patchProjectUrl,
  setCurrentPage,
  setPageReturn,
  pageReturn,
  projectServices,
  onData,
}) {
  // Env/ API
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;

  // States
  const [subpage, setSubpage] = useState("accordians");
  const [phasesOnAccount, setPhasesOnAccount] = useState([]);
  const [servicesOnBlueprint, setServicesOnBlueprint] = useState([]);
  const [servicesNeedingReassignment, setServicesNeedingReassignment] =
    useState([]);
  const [subservicesOnBlueprint, setSubservicesOnBlueprint] = useState([]);
  const [phasesOnBlueprint, setPhasesOnBlueprint] = useState([]);
  const [servicesOnStandard, setServicesOnStandard] = useState([]);
  const [subservicesOnStandard, setSubservicesOnStandard] = useState([]);
  const [phasesOnStandard, setPhasesOnStandard] = useState([]);
  const [servicesHaveBeenAdded, setServicesHaveBeenAdded] = useState(false);
  const [customServices, setCustomServices] = useState([]);
  const [subservicesOnCustomServices, setSubservicesOnCustomServices] =
    useState([]);
  const [phasesOnCustomServices, setPhasesOnCustomServices] = useState([]);
  const [resources, setResources] = useState([]);
  const [rateType, setRateType] = useState("Unit Hours");

  const [servicesOnQuestionnaire, setServicesOnQuestionnaire] = useState([]);
  const [subservicesOnQuestionnaire, setSubservicesOnQuestionnaire] = useState(
    []
  );
  const [phasesOnQuestionnaire, setPhasesOnQuestionnaire] = useState([]);

  const continueProjectCreationWorkFlow = () => {
    setCurrentPage(3);
  };

  const handleServicesData = (data) => {
    onData(data);
  };

  useEffect(() => {
    if (projectServices) {
      if (projectServices.length !== 0) {
        setSubpage("servicesAdded");
      }
    }

    // Get resources
    API.Get(
      `${apiHost}/${account_slug}/v1/resources?filter[active]=true&page[size]=1000`,
      authorizationCode
    ).then((response) => {
      let data = response.data.data;
      if (data !== null) setResources(data);
    });

    //Get Phases on Account
    API.Get(`${apiHost}/${account_slug}/v1/phases`, authorizationCode).then(
      (res) => {
        const data = res.data.data;
        setPhasesOnAccount(data);
      }
    );

    // Get Rate Type
    API.Get(`${apiHost}/v1/accounts/${account_slug}`, authorizationCode).then(
      (response) => {
        const data = response.data.data;
        if (data && data !== "") {
          setRateType(data.attributes["rate-type"] + "s");
        }
      }
    );
    // Add grey background to body
    document.body.setAttribute("style", "background: #eee");
  }, [projectServices]);

  const account = useContext(AccountContext);

  const ServiceAccordans = () => {
    return (
      <>
        {account.planDetails.questionnaires && (
          <Row
            className="accordianRedirect"
            onClick={() => setSubpage("addByQuestionnaire")}
          >
            <Col>
              <AccordianDrop
                header={getAccordianHeaderMarkup(
                  "Add Services From Survey",
                  "Fill out a survey, created by your organization, to suggest services for a given solution."
                )}
                body={null}
                iconOpen={faCirclePlus}
                iconClosed={faCirclePlus}
                closeable={false}
                setAccordianIsOpen={null}
                className="questionnaireAccordian mainAccordion"
              />
            </Col>
          </Row>
        )}
        <Row
          className="accordianRedirect"
          onClick={() => setSubpage("addBlueprint")}
        >
          <Col>
            <AccordianDrop
              header={getAccordianHeaderMarkup(
                "Add Services From Blueprint",
                "Blueprints are collections of pre-defined services that represent completed solutions."
              )}
              body={null}
              iconOpen={faCirclePlus}
              iconClosed={faCirclePlus}
              closeable={false}
              setAccordianIsOpen={null}
              className="blueprintAccordian mainAccordion"
            />
          </Col>
        </Row>
        <Row
          className="accordianRedirect"
          onClick={() => setSubpage("addServicesFromStandard")}
        >
          <Col>
            <AccordianDrop
              header={getAccordianHeaderMarkup(
                "Add Services From Standards",
                "Standards are services pre-configured by your organization."
              )}
              body={null}
              iconOpen={faCirclePlus}
              iconClosed={faCirclePlus}
              closeable={false}
              setAccordianIsOpen={null}
              className="standardServicesAccordian mainAccordion"
            />
          </Col>
        </Row>
        <Row
          className="accordianRedirect"
          onClick={() => setSubpage("addCustomServices")}
        >
          <Col>
            <AccordianDrop
              header={getAccordianHeaderMarkup(
                "Add Custom Services",
                "Create a-la-carte services and add them to your project one-by-one."
              )}
              body={null}
              iconOpen={faCirclePlus}
              iconClosed={faCirclePlus}
              closeable={false}
              setAccordianIsOpen={null}
              className="standardServicesAccordian mainAccordion"
            />
          </Col>
        </Row>
      </>
    );
  };

  const renderSubpages = () => {
    switch (subpage) {
      case "addBlueprint":
        return (
          <AddByBlueprint
            authorizationCode={authorizationCode}
            account_slug={account_slug}
            subpage={subpage}
            setSubpage={(subpage) => setSubpage(subpage)}
            setServicesOnBlueprint={(array) => setServicesOnBlueprint(array)}
            setServicesNeedingReassignment={(array) =>
              setServicesNeedingReassignment(array)
            }
            setSubservicesOnBlueprint={(array) =>
              setSubservicesOnBlueprint(array)
            }
            resources={resources}
            setPhasesOnBlueprint={(array) => setPhasesOnBlueprint(array)}
            servicesHaveBeenAdded={servicesHaveBeenAdded}
            rateType={rateType}
            phasesOnAccount={phasesOnAccount}
          />
        );
      case "applyBlueprint":
        return (
          <ApplyBlueprint
            subpage={subpage}
            setSubpage={(subpage) => setSubpage(subpage)}
            services={servicesOnBlueprint}
            servicesNeedingReassignment={servicesNeedingReassignment}
            resourcesOnBlueprints={resources}
            subservicesOnBlueprint={subservicesOnBlueprint}
            phasesOnBlueprint={phasesOnBlueprint}
            authorizationCode={authorizationCode}
            account_slug={account_slug}
            projectId={projectId}
            resources={resources}
            rateType={rateType}
            phasesOnAccount={phasesOnAccount}
          />
        );
      case "addServicesFromStandard":
        return (
          <Standard
            authorizationCode={authorizationCode}
            account_slug={account_slug}
            subpage={subpage}
            setSubpage={(subpage) => setSubpage(subpage)}
            setServicesOnStandard={(array) => setServicesOnStandard(array)}
            setSubservicesOnStandard={(array) =>
              setSubservicesOnStandard(array)
            }
            setPhasesOnStandard={(array) => setPhasesOnStandard(array)}
            servicesHaveBeenAdded={servicesHaveBeenAdded}
            resources={resources}
            rateType={rateType}
          />
        );
      case "servicesAdded":
        return (
          <ServicesAdded
            authorizationCode={authorizationCode}
            account_slug={account_slug}
            subpage={subpage}
            setSubpage={(subpage) => setSubpage(subpage)}
            projectId={projectId}
            setServicesHaveBeenAdded={(bool) => setServicesHaveBeenAdded(bool)}
            onData={onData}
            onServicesData={handleServicesData}
            resources={resources}
            rateType={rateType}
          />
        );
      case "applyStandards":
        return (
          <ApplyStandards
            servicesAdded={servicesOnStandard}
            authorizationCode={authorizationCode}
            account_slug={account_slug}
            subpage={subpage}
            setSubpage={(subpage) => setSubpage(subpage)}
            subservicesOnServicesAdded={subservicesOnStandard}
            projectId={projectId}
            phasesOnStandard={phasesOnStandard}
            resources={resources}
            rateType={rateType}
          />
        );
      case "addCustomServices":
        return (
          <CustomService
            authorizationCode={authorizationCode}
            account_slug={account_slug}
            subpage={subpage}
            setSubpage={(subpage) => setSubpage(subpage)}
            setCustomServices={(array) => setCustomServices(array)}
            servicesHaveBeenAdded={servicesHaveBeenAdded}
            setSubservicesOnCustomServices={(array) =>
              setSubservicesOnCustomServices(array)
            }
            setPhasesOnCustomServices={(array) =>
              setPhasesOnCustomServices(array)
            }
            projectId={projectId}
            resources={resources}
            rateType={rateType}
          />
        );
      case "applyCustomServices":
        return (
          <ApplyCustomService
            subpage={subpage}
            setSubpage={(subpage) => setSubpage(subpage)}
            services={customServices}
            resources={resources}
            subservices={subservicesOnCustomServices}
            authorizationCode={authorizationCode}
            phases={phasesOnCustomServices}
            account_slug={account_slug}
            projectId={projectId}
            rateType={rateType}
          />
        );
      case "addByQuestionnaire":
        return (
          <Questionnaire
            // subpage={subpage}
            setSubpage={(subpage) => setSubpage(subpage)}
            servicesHaveBeenAdded={servicesHaveBeenAdded}
            setServices={setServicesOnQuestionnaire}
            setResources={setResources}
            setSubservices={setSubservicesOnQuestionnaire}
            setPhases={setPhasesOnQuestionnaire}
            projectId={projectId}
            // rateType={rateType}
          />
        );
      case "applyQuestionnaire":
        return (
          <ApplyQuestionnaire
            subpage={subpage}
            setSubpage={(subpage) => setSubpage(subpage)}
            services={servicesOnQuestionnaire}
            resources={resources}
            subservices={subservicesOnQuestionnaire}
            phases={phasesOnQuestionnaire}
            projectId={projectId}
            rateType={rateType}
          />
        );
      default:
        return <ServiceAccordans />;
    }
  };

  const backToSummary = () => {
    setCurrentPage(1);
    setPageReturn(true);
  };

  return (
    <>
      <div className="details">{renderSubpages()}</div>
      {subpage == "accordians" || subpage == "servicesAdded" ? (
        <CreateProjectFooter
          previousTabName={"Summary"}
          backButtonOnClick={backToSummary}
          continueButtonType={"button"}
          continueButtonOnClick={() => continueProjectCreationWorkFlow()}
          disabled={subpage == "accordians"}
        />
      ) : null}
    </>
  );
}

export default Services;

import {
  ListQuotesForAccountApiArg,
  V1LineOfBusinessResource,
  V1QuoteResource,
  V1VendorResource,
  useListQuotesForAccountQuery,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { OptionType, Quote } from "../../types/Quote";
import { snakeToText } from "@utils/helperFunctions";

export interface HookArgs {
  page?: ListQuotesForAccountApiArg["page"];
  include?: ListQuotesForAccountApiArg["include"];
  filter?: ListQuotesForAccountApiArg["filter"];
  lobs?: V1LineOfBusinessResource[] | [];
  vendors?: V1VendorResource[] | [];
}

const useListQuotes = ({ page, include, filter, lobs, vendors }: HookArgs) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { data, error, isLoading, refetch } = useListQuotesForAccountQuery({
    slug: accountSlug,
    page,
    include,
    filter,
  });

  let quotes: Quote[] = [];
  let customError: string | null = null;
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  let billingFrequencies: OptionType[] = [
    "one_time",
    "monthly",
    "quarterly",
    "yearly",
  ].map((bf) => {
    return { value: bf, label: snakeToText(bf) || "" } as OptionType;
  });

  if (data?.data) {
    try {
      quotes = data.data.map((quote) => {
        if (!quote.id) {
          throw new Error("Missing quote.id");
        }
        let vendor: OptionType = {
          value: 0,
          label: "Search and select vendor name",
        };
        let lob: OptionType = {
          value: 0,
          label: "If applicable, assign to a Line of Business",
        };
        let billingFrequency: OptionType = {
          value: 0,
          label: "How often do costs recur",
        };

        let foundVendor = (vendors || []).find(
          (p) =>
            quote?.relationships &&
            quote?.relationships["vendor"] &&
            quote?.relationships?.["vendor"].data?.id == p.id
        );
        if (foundVendor && foundVendor.id) {
          vendor = {
            label: foundVendor.attributes?.["name"],
            value: foundVendor.id,
          };
        }

        let foundBillingFrequency = billingFrequencies.find(
          (bf) => bf.value === quote?.attributes?.["billing-frequency"]
        );
        if (foundBillingFrequency) {
          billingFrequency = foundBillingFrequency;
        }

        let foundLob = (lobs || []).find(
          (lob) =>
            quote?.relationships &&
            quote?.relationships.lob &&
            quote?.relationships?.lob.data?.id == lob.id
        );
        if (foundLob && foundLob.id) {
          lob = {
            label: foundLob.attributes?.["name"],
            value: foundLob.id,
          };
        }

        return {
          id: String(quote.id),
          description: quote?.attributes.description,
          vendor: vendor,
          oneTimeCost: quote?.attributes?.cost || "",
          oneTimeRevenue: quote?.attributes?.price || "",
          markup: Number(quote?.attributes?.markup) || "",
          recurringCost: quote?.attributes?.["recurring-cost"] || "",
          recurringRevenue: quote?.attributes?.["recurring-price"] || "",
          lob: lob,
          totalRevenue: quote?.attributes?.["total-revenue"] || 0,
          deriveFromServices: quote?.attributes?.["service-pricing"] || false,
          billingFrequency: billingFrequency,
          billingFrequencies: billingFrequencies,
          terms: quote?.attributes?.["mrr-terms"],
          vendorDocument: quote?.attributes?.["vendor-document"]
            ? quote?.attributes?.["vendor-document"]
            : null,
          vendorDocumentStatus:
            quote?.attributes?.["vendor-document-status"] || "",
          documentPending:
            quote?.attributes?.["vendor-document-status"] &&
            quote?.attributes?.["vendor-document-status"] !==
              "not_applicable" &&
            quote?.attributes?.["vendor-document-status"] !== "accepted",
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return {
    quotes: quotes,
    quotesError: error || customError,
    quotesLoading: isLoading,
    pageMeta,
    refetch,
  };
};

export default useListQuotes;

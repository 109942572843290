import React, { useState, useEffect } from "react";
import { Button, Card, Form } from "react-bootstrap";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import SearchField from "@components/Forms/SearchField/SearchField";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import API from "@API";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/pro-solid-svg-icons";
import SlideOutMenuSectioned from "@components/SlideOutMenu/SlideOutMenuSectioned";
import "../Services.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { arrangeDataByPhase } from "../serviceHelpers";

function AddByBlueprint({
  authorizationCode,
  account_slug,
  subpage,
  setSubpage,
  setServicesOnBlueprint,
  setServicesNeedingReassignment,
  // setResourcesOnBlueprints,
  setSubservicesOnBlueprint,
  setPhasesOnBlueprint,
  servicesHaveBeenAdded,
  resources,
  rateType,
  phasesOnAccount,
}) {
  //ENV
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  //States
  const [blueprints, setBlueprints] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [blueprintItems, setBlueprintItems] = useState([]);
  const [services, setServices] = useState([]);
  const [subservices, setSubservices] = useState([]);
  const [blueprintToSearch, setBlueprintToSearch] = useState("");
  const [showBlueprintSlideout, setShowBlueprintSlideout] = useState(false);
  const [selectedBlueprint, setSelectedBlueprint] = useState({});
  const [servicesForSelectedBlueprint, setServicesForSelectedBlueprint] =
    useState([]);
  const [phases, setPhases] = useState([]);

  useEffect(() => {
    // Add grey background to body
    document.body.setAttribute("style", "background: #eee");

    //Get Blueprints
    API.Get(
      `${apiHost}/${account_slug}/v1/blueprints?include=blueprint-items,blueprint-items.resource,blueprint-items.service,blueprint-items.service.phase,blueprint-items.service.resource,blueprint-items.service.subservices,blueprint-items.service.subservices.resource,blueprint-items.service.subservices.service`,
      authorizationCode
    ).then((response) => {
      let data = response.data.data;
      setBlueprints(data);
      let includedData = response.data.included;
      setBlueprintItems(
        includedData.filter((item) => item.type === "blueprint-items")
      );
      const servicesData = includedData.filter(
        (item) => item.type === "services" && item.attributes.state === "active"
      );
      setServices(servicesData);
      const subservicesOnBlueprint = includedData.filter(
        (item) =>
          item.type === "subservices" && item.attributes.state === "active"
      );
      setSubservices(subservicesOnBlueprint);
      setSubservicesOnBlueprint(subservicesOnBlueprint);
      const phasesOnBlueprint = includedData.filter(
        (item) => item.type === "phases" && item.attributes.active
      );
      setPhases(phasesOnBlueprint);
      setPhasesOnBlueprint(phasesOnBlueprint);
      setIsLoading(false);
    });
  }, []);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Preview Services
    </Tooltip>
  );

  const closeSlideout = () => {
    setShowBlueprintSlideout(false);
  };

  const handleCancel = () => {
    if (servicesHaveBeenAdded) {
      setSubpage("servicesAdded");
    } else {
      setSubpage("accordians");
    }
  };
  const searchBlueprintName = (blueprintName) => {
    API.Get(
      `${apiHost}/${account_slug}/v1/blueprints?filter[name]=${encodeURIComponent(
        blueprintName
      )}&include=blueprint-items,blueprint-items.resource,blueprint-items,blueprint-items.service,blueprint-items.service.phase`,
      authorizationCode
    ).then((response) => setBlueprints(response.data.data));
  };

  const handleBlueprintSearch = (e) => {
    e.preventDefault();
    searchBlueprintName(blueprintToSearch);
  };

  const getTableData = (services) => {
    if (blueprints.length === 0) {
      return [];
    }
    // Map over blueprints & return data for ScopeStackTable
    return blueprints.map((blueprint, index) => {
      // Match services to blueprint
      let blueprintItemsPerBlueprint = [];
      for (let i = 0; i < blueprintItems.length; i++) {
        let blueprintItemData = blueprintItems[i].relationships.blueprint.data;
        if (blueprintItemData && blueprintItemData.id === blueprint.id) {
          blueprintItemsPerBlueprint.push(blueprintItems[i]);
        }
      }
      let servicesPerBlueprint = [];
      blueprintItemsPerBlueprint.forEach((blueprintItem) => {
        services.forEach((service) => {
          // Match service to resource
          resources.forEach((resource) => {
            let blueprintResourceData =
              blueprintItem.relationships.resource &&
              blueprintItem.relationships.resource.data
                ? blueprintItem.relationships.resource.data
                : null;
            let serviceResourceData =
              service.relationships.resource &&
              service.relationships.resource.data
                ? service.relationships.resource.data
                : null;
            if (
              blueprintResourceData &&
              blueprintResourceData.id == resource.id
            ) {
              service.resource = resource.attributes.name;
              service.relationships.resource = {
                data: {
                  type: "resources",
                  id: resource.id,
                },
              };
            } else if (
              serviceResourceData &&
              serviceResourceData.id == resource.id
            ) {
              service.resource = resource.attributes.name;
              service.relationships.resource = {
                data: {
                  type: "resources",
                  id: resource.id,
                },
              };
            }
          });

          //Match service to blueprint-item to get quantity
          blueprintItems.forEach((item) => {
            let serviceData = item.relationships.service.data;
            if (serviceData && serviceData.id == service.id) {
              service.quantity = item.attributes.quantity;
              service.serviceName = service.attributes.name;
            }
          });
          if (
            blueprintItem.relationships.service.data &&
            blueprintItem.relationships.service.data.id == service.id
          ) {
            if (
              service.relationships.subservices &&
              service.relationships.subservices.data
            ) {
              let subservicesPerService = [];
              let subservicesOnService = service.relationships.subservices.data;
              subservicesOnService.forEach((subserviceOnService) => {
                subservices.forEach((subservice) => {
                  if (subserviceOnService.id == subservice.id) {
                    subservice.attributes["service-type"] =
                      service.attributes["service-type"];
                    subservicesPerService.push(subservice);
                  }
                });
              });
              service.subservices = subservicesPerService;
              servicesPerBlueprint.push(service);
            } else {
              servicesPerBlueprint.push(service);
            }
          }
        });
      });

      let displayedServices = servicesPerBlueprint
        .map((service) => service.attributes.name)
        .filter((service, index) => index < 2)
        .join(", ");

      if (servicesPerBlueprint.length > 2) {
        displayedServices = (
          <span>
            {displayedServices} ...and {servicesPerBlueprint.length - 2} more{" "}
          </span>
        );
      }

      const setCurrentBlueprintState = () => {
        setSelectedBlueprint({
          id: blueprint.id,
          name: blueprint.attributes.name,
        });
        setServicesForSelectedBlueprint(servicesPerBlueprint);
        setServicesOnBlueprint(servicesPerBlueprint);
        setPhasesOnBlueprint(phases);
      };

      // Map returns a table row for each blueprint
      return {
        rowClass: "blueprintListRow",
        onClick: () => {
          setCurrentBlueprintState();
          handleApplyBlueprint();
        },
        columns: [
          {
            class: "",
            name: <strong>{blueprint.attributes.name}</strong>,
          },
          {
            class: "",
            name: (
              <>
                {displayedServices} &nbsp;
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <FontAwesomeIcon
                    style={{ zIndex: "10", position: "relative" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setCurrentBlueprintState();
                      setShowBlueprintSlideout(true);
                    }}
                    icon={faEye}
                  />
                </OverlayTrigger>
              </>
            ),
          },
          {
            class: "",
            name: (
              <div>
                {blueprint.attributes["tag-list"].map((tag) => (
                  <Button
                    key={tag}
                    style={{
                      color: "#fff",
                      backgroundColor: "#418172",
                      marginRight: "10px",
                      fontSize: "12px",
                      cursor: "auto",
                    }}
                  >
                    {tag}
                  </Button>
                ))}
              </div>
            ),
          },
        ],
        actions: null,
      };
    });
  };

  const headerData = {
    rowClass: "",
    columns: [
      {
        class: "",
        name: "Blueprint Name",
      },
      {
        class: "",
        name: "Services In Blueprint",
      },
      {
        class: "",
        name: "Tags",
      },
    ],
  };

  const blueprintListTable = new ScopeStackTable(
    "blueprints",
    headerData,
    getTableData(
      arrangeDataByPhase(services, phasesOnAccount, phases, setPhases)
    ),
    null
  );

  // Slide Out Menu
  const handleApplyBlueprint = () => {
    closeSlideout();
    setSubpage("applyBlueprint");
  };

  const handleCancelButtonOnSlideout = () => closeSlideout();

  const getBlueprintServiceTableData = (services) => {
    if (services.length === 0) {
      return [];
    }
    // Map over blueprints & return data for ScopeStackTable
    return services.map((service, index) => {
      // Match service to phase
      let phaseName = "";
      phases.forEach((phase) => {
        let servicePhaseData =
          service.relationships.phase && service.relationships.phase.data
            ? service.relationships.phase.data
            : null;
        if (servicePhaseData && servicePhaseData.id == phase.id) {
          phaseName = phase.attributes.name;
        }
      });

      // Map returns a table row for each blueprint
      return {
        rowClass: "blueprintListRow",
        onClick: null,
        columns: [
          {
            class: "col-3",
            name: phaseName,
          },
          {
            class: "col-4",
            name: service.serviceName,
          },
          {
            class: "col-2",
            name: service.quantity,
          },
          {
            class: "col-3",
            name: service.resource,
          },
        ],
        actions: null,
      };
    });
  };

  const blueprintServiceHeaderData = {
    rowClass: "",
    columns: [
      {
        class: "col-3",
        name: "Phase",
      },
      {
        class: "col-4",
        name: "Name",
      },
      {
        class: "col-2",
        name: "Quantity",
      },
      {
        class: "col-3",
        name: "Resource",
      },
    ],
  };

  const blueprintServiceSection = {
    sectionTitle: (
      <div>
        Previewing: <strong>{selectedBlueprint.name}</strong>
      </div>
    ),
    sectionClass: "blueprintsSection",
    containerClass: "blueprintsContainer",
    sectionContent: phases.map((phase) => {
      let tableData = getBlueprintServiceTableData(
        arrangeDataByPhase(
          servicesForSelectedBlueprint,
          phasesOnAccount,
          phases,
          setPhases
        ).filter(
          (service) =>
            service.relationships.phase &&
            service.relationships.phase.data &&
            service.relationships.phase.data.id == phase.id
        )
      );
      const blueprintServiceTable = new ScopeStackTable(
        "services",
        blueprintServiceHeaderData,
        tableData,
        null
      );

      if (tableData.length !== 0) {
        return (
          <div key={phase.id} style={{ padding: "24px" }}>
            <div>
              <strong>{phase.attributes.name}</strong>
            </div>
            <BaseTable
              className="blueprintSlideOutTable"
              striped={true}
              hover={false}
              bordered={false}
              headerRows={blueprintServiceTable.buildHeaderRows()}
              dataRows={blueprintServiceTable.buildDataRows()}
              footerRows={blueprintServiceTable.buildFooterRows()}
              isLoading={false}
            />
          </div>
        );
      }
    }),
  };

  return (
    <Card className="addBlueprintCard">
      {subpage === "addBlueprint" ? (
        <SlideOutMenuSectioned
          show={showBlueprintSlideout}
          setShow={(show) => setShowBlueprintSlideout(show)}
          menuHeader="Blueprint Preview"
          actionButtonText="Use"
          onActionButtonClick={() => handleApplyBlueprint()}
          clearFields={null}
          onCancel={handleCancelButtonOnSlideout}
          onClearFieldsClick={null}
          sectionsArray={[blueprintServiceSection]}
        />
      ) : null}
      <Card.Header
        style={{
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="accordianTitle" style={{ width: "90vw" }}>
          Add Services From Blueprint
        </div>
        <Button
          style={{ marginRight: "10px" }}
          onClick={() => handleCancel()}
          className="ssButtonBgWhite"
        >
          Cancel
        </Button>
      </Card.Header>
      <hr />
      <Card.Body>
        <div
          style={{ fontSize: "20px", marginBottom: "30px" }}
          className="accordianSubtitle"
        >
          Select a Blueprint
        </div>
        <div>
          <FormFieldLabel label="Search Blueprints" />
          <Form
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                handleBlueprintSearch(e);
              }
            }}
            onSubmit={handleBlueprintSearch}
          >
            <SearchField
              placeholder="Search by blueprint or service name"
              value={blueprintToSearch}
              onChange={(e) => setBlueprintToSearch(e.target.value)}
              onClick={() => {
                setBlueprintToSearch("");
                searchBlueprintName("");
              }}
            />
          </Form>
        </div>
        <div style={{ marginTop: "40px" }}>
          <BaseTable
            className="sansTable blueprintListTable"
            striped={true}
            hover={true}
            bordered={true}
            headerRows={blueprintListTable.buildHeaderRows()}
            dataRows={blueprintListTable.buildDataRows()}
            footerRows={blueprintListTable.buildFooterRows()}
            isLoading={isLoading}
          />
        </div>
      </Card.Body>
    </Card>
  );
}

export default AddByBlueprint;

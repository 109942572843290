import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { useStyles } from "./styles";
import { Button, Row, Col, Form } from "react-bootstrap";
import { TextField } from "@components/FormsV2";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import {
  useCreateProjectProductMutation,
  useUpdateProjectProductMutation,
  V1ProjectProductResource,
} from "@generated";
import { useNavigate, useLocation } from "react-router-dom";
import { handleAlert, snakeToText } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { Product, OptionType } from "../types";
import ControlledDropDownV2 from "@components/Forms/ControlledDropDownV2";

// This component is responsible for the edit product page AND the create custom product page

const Edit = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const styles = useStyles();
  const [createProjectProduct] = useCreateProjectProductMutation();
  const [updateProjectProduct] = useUpdateProjectProductMutation();

  const [editProduct, setEditProduct] = useState<Product | null>(
    location.state || null
  );

  const editingThirdPartyProduct =
    location.state?.source &&
    !["standard", "custom", "imported"].includes(location.state?.source);

  const [name, setName] = useState<string>(editProduct?.name || "");
  const [description, setDescription] = useState<string>(
    editProduct?.description || ""
  );
  const [mfrPartNumber, setMfrPartNumber] = useState<string>(
    editProduct?.mfrPartNumber || ""
  );
  const [productId, setProductId] = useState<string>(
    editProduct?.productId || ""
  );
  const [sku, setSku] = useState<string>(editProduct?.sku || "");
  const [category, setCategory] = useState<string>(editProduct?.category || "");
  const [subcategory, setSubcategory] = useState<string>(
    editProduct?.subcategory || ""
  );
  const [quantity, setQuantity] = useState<string>(
    String(editProduct?.quantity) || ""
  );
  const [unitOfMeasure, setUnitOfMeasure] = useState<string>(
    editProduct?.units || ""
  );
  const [unitPrice, setUnitPrice] = useState<string>(editProduct?.price || "");
  const [unitCost, setUnitCost] = useState<string>(editProduct?.unitCost || "");
  const [listPrice, setListPrice] = useState<string>(
    editProduct?.listPrice || ""
  );
  const [vendorDiscount, setVendorDiscount] = useState<string>(
    editProduct?.vendorDiscount || ""
  );
  const [markup, setMarkup] = useState<string>(editProduct?.markup || "");
  //true if "Manage Unit Price" checked, otherwise false
  const [manageUnitPrice, setManageUnitPrice] = useState<boolean>(true);

  const projectId = parseInt(window.location.href.split("/")[4]);

  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [billingFrequency, setBillingFrequency] = useState<string>(editProduct?.billingFrequency || "one_time");

  const billingFrequencyOptions = [
    "one_time",
    "monthly",
    "quarterly",
    "yearly",
    "bi-monthly",
    "semi-annual"
  ];

  const handleSaveProduct = (saveAndAddAnother: boolean) => {
    if (name === "") {
      setErrorMessage("Name field cannot be blank.");
      setShowFailAlert(true);
      return;
    } else if (Number(quantity) <= 0) {
      setErrorMessage("Quantity must be greater than 0.");
      setShowFailAlert(true);
      return;
    }

    let data: V1ProjectProductResource = {
      type: "project-products",
      attributes: {
        name,
        description,
        custom: true,
        quantity: quantity ? parseInt(quantity) : 0,
        sku,
        "product-id": productId,
        "manufacturer-part-number": mfrPartNumber,
        "unit-of-measure": unitOfMeasure,
        category,
        subcategory,
        "unit-price": unitPrice ? parseFloat(unitPrice) : 0,
        "unit-cost": unitCost ? parseFloat(unitCost) : 0,
        "list-price": listPrice ? parseFloat(listPrice) : 0,
        "vendor-discount": vendorDiscount ? parseFloat(vendorDiscount) : 0,
        markup: markup ? parseFloat(markup) : 0,
        "billing-frequency": billingFrequency ? billingFrequency as Product["billingFrequency"] : "one_time",
      },
      relationships: {
        project: { data: { id: projectId, type: "projects" } },
      },
    };

    const handleFailResponse = (msg) => {
      if (msg) {
        setErrorMessage(msg);
        setShowFailAlert(true);
      } else {
        setErrorMessage("Something went wrong.");
        setShowFailAlert(true);
      }
    };

    if (editProduct) {
      data.id = Number(editProduct.id);
      updateProjectProduct({
        slug: accountSlug,
        id: data.id,
        body: { data },
      })
        .unwrap()
        .then((response) => {
          if (response?.data?.id) {
            const data = response.data.attributes;
            setSuccessMessage("Product has been updated.");
            setShowSuccessAlert(true);
            setName(data.name);
            setDescription(data.description || "");
            setMfrPartNumber(data["manufacturer-part-number"] || "");
            setProductId(data["product-id"] || "");
            setSku(data.sku || "");
            setCategory(data.category || "");
            setSubcategory(data.subcategory || "");
            setQuantity(String(quantity));
            setUnitOfMeasure(String(data["unit-of-measure"]) || "");
            setUnitPrice(String(data["unit-price"]) || "");
            setUnitCost(String(data["unit-cost"]) || "");
            setListPrice(String(data["list-price"]) || "");
            setVendorDiscount(String(data["vendor-discount"]) || "");
            setMarkup(String(data["markup"]) || "");
            setBillingFrequency(data["billing-frequency"] || "one_time");
            setEditProduct({
              ...editProduct,
              name: data.name,
              description: data.description,
              mfrPartNumber: data["manufacturer-part-number"],
              productId: data["product-id"],
              sku: data.sku,
              category: data.category,
              subcategory: data.subcategory,
              quantity: data.quantity,
              units: data["unit-of-measure"],
              price: String(data["unit-price"]),
              unitCost: String(data["unit-cost"]),
              listPrice: String(data["list-price"]),
              vendorDiscount: String(data["vendor-discount"]),
              markup: String(data.markup),
              billingFrequency:  data["billing-frequency"],
            });
          }
        })
        .catch((error) => handleFailResponse(error?.data?.errors?.[0]?.detail));
    } else {
      data.attributes.source = "custom";
      createProjectProduct({ slug: accountSlug, body: { data } })
        .unwrap()
        .then((response) => {
          if (saveAndAddAnother) {
            setName("");
            setDescription("");
            setMfrPartNumber("");
            setProductId("");
            setSku("");
            setCategory("");
            setSubcategory("");
            setQuantity("");
            setUnitOfMeasure("");
            setUnitPrice("");
            setUnitCost("");
            setListPrice("");
            setVendorDiscount("");
            setMarkup("");
            setManageUnitPrice(true);
            setSuccessMessage("Product has been added.");
            setShowSuccessAlert(true);
          } else {
            navigate(`/projects/${projectId}/project_materials`, {
              state: { productsCreated: 1 },
            });
          }
        })
        .catch((error) => handleFailResponse(error?.data?.errors?.[0]?.detail));
    }
  };

  const checkValidity = () => {
    if (!editProduct) {
      return name !== "" && quantity !== "";
    }
    return (
      name !== editProduct.name ||
      description !== editProduct.description ||
      mfrPartNumber !== editProduct.mfrPartNumber ||
      productId !== editProduct.productId ||
      sku !== editProduct.sku ||
      category !== editProduct.category ||
      subcategory !== editProduct.subcategory ||
      quantity !== String(editProduct.quantity) ||
      unitOfMeasure !== editProduct.units ||
      unitPrice !== editProduct.price ||
      unitCost !== editProduct.unitCost ||
      listPrice !== editProduct.listPrice ||
      vendorDiscount !== editProduct.vendorDiscount ||
      markup !== editProduct.markup || 
      billingFrequency !== editProduct.billingFrequency
    );
  };

  const saveEnabled = checkValidity();

  return (
    <div className={styles.card}>
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      <div className={styles.cardHeader}>
        <div className={styles.actionButtons}>
          <Button
            className={styles.cancelButton}
            onClick={(e) =>
              navigate(`/projects/${projectId}/project_materials`, {
                state: null,
              })
            }
          >
            {!saveEnabled && editProduct ? "Back" : "Cancel"}
          </Button>
          <h2>{editProduct ? "Edit Product" : "Add Custom Product"}</h2>
        </div>

        <div className={styles.actionButtons}>
          {!editProduct && (
            <Button
              className={`${styles.button} ${styles.addAnotherBtn} ${
                saveEnabled ? "" : styles.disabled
              }`}
              onClick={() => handleSaveProduct(true)}
              disabled={!saveEnabled}
            >
              Save & Add Another
            </Button>
          )}
          <Button
            className={`${styles.button} ${saveEnabled ? "" : styles.disabled}`}
            onClick={() => handleSaveProduct(false)}
            disabled={!saveEnabled}
          >
            Save
          </Button>
        </div>
      </div>
      <div>
        <div className={styles.formSection}>
          <div className={styles.sectionHeader}>Identifier</div>
          <Row>
            <Col>
              <TextField
                label="*Name"
                id="name"
                placeholder="Enter product name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                label="Description"
                id="description"
                placeholder="Enter product description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label="Manufacturer Part Number"
                id="mfrPartNumber"
                placeholder="Enter manufacturer part number"
                value={mfrPartNumber}
                onChange={(e) => setMfrPartNumber(e.target.value)}
                disabled={editingThirdPartyProduct}
              />
            </Col>
            <Col>
              <TextField
                label="Internal Product ID"
                id="productId"
                placeholder="Enter internal product ID"
                value={productId}
                onChange={(e) => setProductId(e.target.value)}
                disabled={editingThirdPartyProduct}
              />
            </Col>
            <Col>
              <TextField
                label="Vendor SKU"
                id="sku"
                placeholder="Enter vendor SKU"
                value={sku}
                onChange={(e) => setSku(e.target.value)}
                disabled={editingThirdPartyProduct}
              />
            </Col>
          </Row>
        </div>
        <div className={styles.formSection}>
          <div className={styles.sectionHeader}>Classification</div>
          <Row>
            <Col>
              <TextField
                label="Category"
                id="category"
                placeholder="Enter category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                label="Subcategory"
                id="subcategory"
                placeholder="Enter subcategory"
                value={subcategory}
                onChange={(e) => setSubcategory(e.target.value)}
              />
            </Col>
          </Row>
        </div>
        <div className={styles.formSection}>
          <div className={styles.sectionHeader}>Quantity</div>
          <Row>
            <Col>
              <FormFieldLabel label="*Quantity" className="" />
              <Form.Control
                required={true}
                placeholder="Enter quantity"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                type="number"
                min={0}
                step={1}
              />
            </Col>
            <Col>
              <TextField
                label="Unit of Measure"
                id="units"
                placeholder="Enter unit of measure"
                value={unitOfMeasure}
                onChange={(e) => setUnitOfMeasure(e.target.value)}
              />
            </Col>
          </Row>
        </div>
        <div className={styles.formSection}>
          <div className={styles.sectionHeader}>Billing</div>
          <Row>
            <Col>
            <ControlledDropDownV2
                label="Includes Recurring Costs"
                value={billingFrequency}
                options={
                  billingFrequencyOptions.map((opt) => {
                    return (
                      <option value={opt} key={opt}>
                        {snakeToText(opt)}
                      </option>
                    );
                  }) || []
                }
                onChange={(e) => {
                  setBillingFrequency((e.target.value as Product["billingFrequency"]) || "one_time");
                }}
                required={false}
                readOnly={false}
                disabled={false}
              />
            </Col>
          </Row>
        </div>
        <div className={styles.pricing}>
          <div className={styles.sectionHeader}>Price</div>
          <Row>
            <Col sm={3}>
              <Form.Check
                type="radio"
                label="Manage Unit Price"
                name="priceOption"
                checked={manageUnitPrice}
                onChange={() => setManageUnitPrice(true)}
                className={`${styles.radioOption} ${
                  manageUnitPrice ? styles.selected : ""
                }`}
              />
              <Form.Check
                type="radio"
                label="Manage List Price"
                name="priceOption"
                checked={!manageUnitPrice}
                onChange={() => setManageUnitPrice(false)}
                className={`${styles.radioOption} ${
                  !manageUnitPrice ? styles.selected : ""
                }`}
              />
            </Col>
            <Col sm={9}>
              {manageUnitPrice ? (
                <Row>
                  <Col>
                    <FormFieldLabel label="Unit Price" className="" />
                    <Form.Control
                      placeholder="Enter unit price"
                      value={unitPrice}
                      onChange={(e) => setUnitPrice(e.target.value)}
                      type="number"
                      min={0}
                      step={0.01}
                    />
                  </Col>
                  <Col>
                    <FormFieldLabel label="Unit Cost" className="" />
                    <Form.Control
                      placeholder="Enter unit cost"
                      value={unitCost}
                      onChange={(e) => setUnitCost(e.target.value)}
                      type="number"
                      min={0}
                      step={0.01}
                    />
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col>
                    <FormFieldLabel label="List Price" className="" />
                    <Form.Control
                      placeholder="Enter list price"
                      value={listPrice}
                      onChange={(e) => setListPrice(e.target.value)}
                      type="number"
                      min={0}
                      step={0.01}
                    />
                  </Col>
                  <Col>
                    <FormFieldLabel label="Vendor Discount" className="" />
                    <Form.Control
                      placeholder="Enter vendor discount"
                      value={vendorDiscount}
                      onChange={(e) => setVendorDiscount(e.target.value)}
                      type="number"
                      min={0}
                      step={0.01}
                    />
                  </Col>
                  <Col>
                    <FormFieldLabel label="Markup (%)" className="" />
                    <Form.Control
                      placeholder="Enter markup"
                      value={markup}
                      onChange={(e) => setMarkup(e.target.value)}
                      type="number"
                      min={0}
                      step={0.01}
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Edit;

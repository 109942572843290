import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Card, Row, Col, Form } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import SubmitButton from "@components/Buttons/SubmitButton/SubmitButton";
import BackButton from "@components/Buttons/BackButton/BackButton";
import FormTextField from "@components/Forms/FormTextField";
import FormNumberField from "@components/Forms/FormNumberField";
import DisabledTextField from "@components/Forms/DisabledTextField";
import API from "@API";
import CustomFields from "../CustomProducts/CustomFields/CustomFields";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import { formatUnprocessibleResponse } from "@utils/helperFunctions";
import Select2 from "@components/Forms/Select2/Select2";
import ProjectVariables from "../../ProjectVariables/ProjectVariables";
import {
  getUpdatedProjectVariables,
  validateVariables,
} from "../../Projects/projectUtils/projectHelperFunctions";

function Product({
  account_slug,
  project_id,
  projectStatus,
  account_id,
  products,
  services,
  projectProducts,
  productCreated,
  setProductCreated,
  setProductUpdated,
  productId,
  authorizationCode,
  productPvs,
}) {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  let navigate = useNavigate();
  const location = window.location.href;
  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];

  const [product, setProduct] = useState("");
  const [newProductId, setNewProductId] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [description, setDescription] = useState("");
  const [partNum, setPartNum] = useState("");
  const [productIdField, setProductIdField] = useState("");
  const [vendorSku, setVendorSku] = useState("");
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [unitOfMeasure, setUnitOfMeasure] = useState("");
  const [productPrice, setProductPrice] = useState(0);
  const [cost, setCost] = useState(0);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [projectServiceId, setProjectServiceId] = useState(null);
  const [selectedService, setSelectedService] = useState(null);

  const [unitPriceChecked, setUnitPriceChecked] = useState(true);
  const [listPriceChecked, setListPriceChecked] = useState(false);
  const [customUnitCost, setCustomUnitCost] = useState("");
  const [customUnitPrice, setCustomUnitPrice] = useState("");
  const [customListPrice, setCustomListPrice] = useState("0.0");
  const [customVendorDiscount, setCustomVendorDiscount] = useState("0.0");
  const [customMarkup, setCustomMarkup] = useState("0.0");

  const [updatedAlertIsOpen, setUpdatedAlertIsOpen] = useState(false);
  const [createdAlertIsOpen, setCreatedAlertIsOpen] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const [isCustom, setIsCustom] = useState(false);
  const [unrequiredProductPvs, setUnrequiredProductPvs] = useState([]);
  const [requiredProductPvs, setRequiredProductPvs] = useState([]);
  const [isVariablePricing, setIsVariablePricing] = useState(false);
  const [isVariableCost, setIsVariableCost] = useState(false);
  const [pricingPermission, setPricingPermission] = useState(false);

  useEffect(() => {
    API.Get(`${apiHost}/v1/me`, authorizationCode).then((res) => {
      if (res.status == 200) {
        const permission = res.data.data.attributes.privileges.find(
          (p) => p.privilege == "projects.materials"
        );
        if (permission && permission["access-level"] == "manage") {
          setPricingPermission(true);
        }
      }
    });
    // Project Variables
    if (productPvs.length !== 0 && lastWordInLocation.includes("new")) {
      let reqProjectVariables = productPvs.filter((variable) => {
        if (variable.required === true) {
          return variable.required;
        }
      });
      reqProjectVariables = reqProjectVariables.map((variable) => {
        if (variable["variable-type"] === "date") {
          return { ...variable, value: formatDate(today) };
        } else {
          return { ...variable, value: "" };
        }
      });
      setRequiredProductPvs(reqProjectVariables);
      let unreqProjectVariables = productPvs.filter((variable) => {
        if (variable.required === false) {
          return variable;
        }
      });
      unreqProjectVariables = unreqProjectVariables.map((variable) => {
        return { ...variable, value: "" };
      });
      setUnrequiredProductPvs(unreqProjectVariables);
    }
    setServiceOptions(
      services.map((service) => {
        return {
          value: service.id,
          label: service.attributes.name,
        };
      })
    );

    if (lastWordInLocation === "new?custom=true") {
      setIsCustom(true);
    }

    if (lastWordInLocation === "edit") {
      if (productCreated === true) {
        setCreatedAlertIsOpen(true);
      }
      for (let i = 0; i < projectProducts.length; i++) {
        let attributes = projectProducts[i].attributes;
        let relationships = projectProducts[i].relationships;
        let isCustom = projectProducts[i].attributes.custom;
        if (projectProducts[i].id == id) {
          setProduct({ value: id, label: attributes.name });
          setQuantity(attributes.quantity);
          setCustomUnitPrice(attributes["unit-price"]);
          setCustomUnitCost(attributes["unit-cost"]);
          setCustomListPrice(attributes["list-price"]);
          setCustomVendorDiscount(attributes["vendor-discount"]);
          setCustomMarkup(attributes.markup);
          if (attributes["list-price"] > 0) {
            setListPriceChecked(true);
            setUnitPriceChecked(false);
          }
          if (isCustom && isCustom === true) {
            setIsCustom(isCustom);
          }
          for (let j = 0; j < products.length; j++) {
            if (attributes.name === products[j].attributes.name) {
              setNewProductId(products[j].id);
            }
          }
          if (relationships && relationships["project-service"].data) {
            let service = services.filter(
              (item) => item.id === relationships["project-service"].data.id
            );

            if (service.length > 0) {
              setProjectServiceId(service[0].id);
              setSelectedService({
                label: service[0].attributes.name,
                value: service[0].id,
              });
            }
          }
          setDescription(attributes.description);
          setPartNum(attributes["manufacturer-part-number"]);
          setProductIdField(attributes["product-id"]);
          setVendorSku(attributes.sku);
          setCategory(attributes.category);
          setSubcategory(attributes.subcategory);
          setUnitOfMeasure(attributes["unit-of-measure"]);
          setProductPrice(attributes["unit-price"]);
          setCost(attributes["unit-cost"]);
          // Check variable_rates for hardware_price or hardware_cost
          if (
            attributes["variable-rates"]["hardware_price"] &&
            attributes["variable-rates"]["hardware_price"].length > 1
          ) {
            setIsVariablePricing(true);
          }
          if (
            attributes["variable-rates"]["hardware_cost"] &&
            attributes["variable-rates"]["hardware_cost"].length > 1
          ) {
            setIsVariableCost(true);
          }

          // Project Variables
          if (
            projectProducts[i].attributes["project-variables"] &&
            projectProducts[i].attributes["project-variables"].length !== 0
          ) {
            let projVariables =
              projectProducts[i].attributes["project-variables"];
            let reqProjVariables = projVariables.filter((variable) => {
              if (variable.required === true) {
                return variable;
              }
            });
            setRequiredProductPvs(reqProjVariables);
            let unreqProjVariables = projVariables.filter((variable) => {
              if (variable.required === false) {
                return variable;
              }
            });
            setUnrequiredProductPvs(unreqProjVariables);
          }
        }
      }
    }
  }, [products, projectProducts, productCreated, authorizationCode, services]);

  const renderProductOptions = () => {
    let options = products.map((product) => {
      let id = product.id;
      let productOption = product.attributes.name;
      return { value: id, label: productOption };
    });
    return options;
  };

  const redirectToProjectProducts = () => {
    navigate(`/projects/${project_id}/project_materials`);
    window.location.reload();
  };

  const redirectToEditProduct = (productId) => {
    navigate(`/projects/${project_id}/project_materials/${productId}/edit`);
    window.location.reload();
  };

  const validateForm = () => {
    let isValid = true;
    if (product === "" || product === " ") {
      isValid = false;
      if (isCustom) {
        setErrorMessages("Could not save Custom Product. Name must exist.");
      }
      if (isCustom === false) {
        setErrorMessages(
          "Could not save Product. You must select a product to submit."
        );
      }
    }
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = validateForm();
    if (formIsValid === false) {
      setShowFailAlert(true);
      return;
    }

    const allProjectVariables = requiredProductPvs.concat(unrequiredProductPvs);
    const productPvsAreValid = validateVariables(allProjectVariables);
    if (productPvsAreValid === false) {
      return;
    }

    const getProductRelationship = () => {
      if (newProductId !== null && isCustom === false) {
        let productRelationship = {
          data: { id: newProductId, type: "products" },
        };
        return productRelationship;
      } else {
        return null;
      }
    };

    const getServiceRelationship = () => {
      return projectServiceId
        ? { data: { id: projectServiceId, type: "project-services" } }
        : null;
    };

    const attributes = {
      custom: isCustom,
      name: product.label,
      description: description,
      "manufacturer-part-number": partNum,
      "product-id": productIdField,
      sku: vendorSku,
      category: category,
      subcategory: subcategory,
      quantity: quantity,
      "unit-of-measure": unitOfMeasure,
      "unit-price": unitPriceChecked ? customUnitPrice : "0.0",
      "unit-cost": unitPriceChecked ? customUnitCost : "0.0",
      "list-price": listPriceChecked ? customListPrice : "0.0",
      "vendor-discount": listPriceChecked ? customVendorDiscount : "0.0",
      markup: listPriceChecked ? customMarkup : "0.0",
      "project-variables": getUpdatedProjectVariables(allProjectVariables),
    };

    if (
      lastWordInLocation === "new" ||
      lastWordInLocation === "new?custom=true"
    ) {
      let productData = {
        data: {
          type: "project-products",
          attributes: attributes,
          relationships: {
            project: { data: { type: "projects", id: project_id } },
            product: getProductRelationship(),
            "project-service": getServiceRelationship(),
          },
        },
      };

      API.Post(
        `${apiHost}/${account_slug}/v1/project-products`,
        productData,
        authorizationCode
      )
        .then((response) => {
          const data = response.data.data;
          let id = data.id;
          setCustomUnitCost(data.attributes["unit-cost"]);
          setCustomUnitPrice(data.attributes["unit-price"]);
          setCustomListPrice(data.attributes["list-price"]);
          setCustomVendorDiscount(data.attributes["vendor-discount"]);
          setCustomMarkup(data.attributes["markup"]);
          setProductCreated(true);
          redirectToEditProduct(id);
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "Product"));
          setShowFailAlert(true);
        });
    }

    if (lastWordInLocation === "edit") {
      let updatedProductData = {
        data: {
          type: "project-products",
          id: id,
          attributes: attributes,
          relationships: {
            project: { data: { type: "projects", id: project_id } },
            product: getProductRelationship(),
            "project-service": getServiceRelationship(),
          },
        },
      };

      API.Patch(
        `${apiHost}/${account_slug}/v1/project-products/${id}`,
        updatedProductData,
        authorizationCode
      )
        .then((response) => {
          const data = response.data.data;
          setCustomUnitCost(data.attributes["unit-cost"]);
          setCustomUnitPrice(data.attributes["unit-price"]);
          setCustomListPrice(data.attributes["list-price"]);
          setCustomVendorDiscount(data.attributes["vendor-discount"]);
          setCustomMarkup(data.attributes["markup"]);
          setProductUpdated(true);
          setUpdatedAlertIsOpen(true);
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "Product"));
          setShowFailAlert(true);
        });
    }
  };

  const handleUpdatedAlert = () => {
    if (updatedAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="Alerts"
          variant="info"
          onClose={() => setUpdatedAlertIsOpen(false)}
          text="Product updated"
        />
      );
    }
  };

  const productCreatedAlert = () => {
    if (createdAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="productAlerts"
          variant="info"
          onClose={() => setCreatedAlertIsOpen(false)}
          text="Product created"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      {handleFailAlert()}
      {handleUpdatedAlert()}
      {productCreatedAlert()}
      <Card>
        <CardHeader title="Product" />
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col sm={6}>
                {isCustom === false ? (
                  <Select2
                    label="Product *"
                    value={product}
                    onChange={(e) => {
                      setProduct({
                        value: e.value,
                        label: e.label,
                      });
                      setNewProductId(e.value);
                      for (let i = 0; i < products.length; i++) {
                        if (products[i].id == e.value) {
                          setDescription(products[i].attributes.description);
                          setPartNum(
                            products[i].attributes["manufacturer-part-number"]
                          );
                          setProductIdField(
                            products[i].attributes["product-id"]
                          );
                          setVendorSku(products[i].attributes.sku);
                          setCategory(products[i].attributes.category);
                          setSubcategory(products[i].attributes.subcategory);
                          setCustomListPrice(products[i].attributes['list-price']);
                          setCustomVendorDiscount(products[i].attributes['vendor-discount']);
                          setCustomMarkup(products[i].attributes.markup);
                          setSubcategory(products[i].attributes.subcategory);
                          setUnitOfMeasure(
                            products[i].attributes["unit-of-measure"]
                          );
                          setProductPrice(products[i].attributes["unit-price"]);
                          setCost(products[i].attributes["unit-cost"]);
                          setCustomUnitCost(products[i].attributes["unit-cost"])
                          setCustomUnitPrice(products[i].attributes["unit-price"])
                          // Set Labels for Variable Pricing and Cost (Uses Safe navigate and ternary)
                          products[i].attributes?.["variable-rates"]?.[
                            "hardware_price"
                          ]?.length > 1 ?? false
                            ? setIsVariablePricing(true)
                            : setIsVariablePricing(false);
                          products[i].attributes?.["variable-rates"]?.[
                            "hardware_cost"
                          ]?.length > 1 ?? false
                            ? setIsVariableCost(true)
                            : setIsVariableCost(false);

                          // Project Variables
                          if (
                            products[i].attributes["project-variables"] &&
                            products[i].attributes["project-variables"]
                              .length !== 0
                          ) {
                            let projVariables =
                              products[i].attributes["project-variables"];
                            let reqProjVariables = projVariables.filter(
                              (variable) => {
                                if (variable.required === true) {
                                  return variable;
                                }
                              }
                            );
                            setRequiredProductPvs(reqProjVariables);
                            let unreqProjVariables = projVariables.filter(
                              (variable) => {
                                if (variable.required === false) {
                                  return variable;
                                }
                              }
                            );
                            setUnrequiredProductPvs(unreqProjVariables);
                          }
                        }
                      }
                    }}
                    options={renderProductOptions()}
                  />
                ) : (
                  <FormTextField
                    label="Name *"
                    value={product.label}
                    onChange={(e) =>
                      setProduct({ value: "", label: e.target.value })
                    }
                  />
                )}
              </Col>
              <Col sm={6}>
                <FormTextField
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <FormTextField
                  label="Manufacturer Part Num"
                  value={partNum}
                  onChange={(e) => setPartNum(e.target.value)}
                />
              </Col>
              <Col sm={3}>
                <FormTextField
                  label="Internal Product ID"
                  value={productIdField}
                  onChange={(e) => setProductIdField(e.target.value)}
                />
              </Col>
              <Col sm={3}>
                <FormTextField
                  label="Vendor SKU"
                  value={vendorSku}
                  onChange={(e) => setVendorSku(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <FormTextField
                  label="Category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                />
              </Col>
              <Col sm={3}>
                <FormTextField
                  label="Subcategory"
                  value={subcategory}
                  onChange={(e) => setSubcategory(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <FormNumberField
                  label="Quantity *"
                  value={quantity}
                  min={1}
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </Col>
              <Col sm={3}>
                <FormTextField
                  label="Unit of Measure"
                  value={unitOfMeasure}
                  onChange={(e) => setUnitOfMeasure(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ProjectVariables
                  arr={unrequiredProductPvs}
                  account_slug={account_slug}
                  project_id={project_id}
                  setState={setUnrequiredProductPvs}
                  setVariableChanged={null}
                />
                <ProjectVariables
                  arr={requiredProductPvs}
                  account_slug={account_slug}
                  project_id={project_id}
                  setState={setRequiredProductPvs}
                  setVariableChanged={null}
                />
              </Col>
            </Row>
            <Row>
              <CustomFields
                unitPriceChecked={unitPriceChecked}
                setUnitPriceChecked={setUnitPriceChecked}
                listPriceChecked={listPriceChecked}
                setListPriceChecked={setListPriceChecked}
                customUnitPrice={customUnitPrice}
                setCustomUnitPrice={setCustomUnitPrice}
                customUnitCost={customUnitCost}
                setCustomUnitCost={setCustomUnitCost}
                customListPrice={customListPrice}
                setCustomListPrice={setCustomListPrice}
                customVendorDiscount={customVendorDiscount}
                setCustomVendorDiscount={setCustomVendorDiscount}
                customMarkup={customMarkup}
                setCustomMarkup={setCustomMarkup}
              />
            </Row>
            <Row>
              <Col xs={6}>
                <Select2
                  label="Which service makes use of this product?"
                  value={selectedService}
                  defaultValue={selectedService}
                  options={serviceOptions}
                  onChange={(e) => {
                    setSelectedService({ label: e.label, value: e.value });
                    setProjectServiceId(e.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <SubmitButton />
              </Col>
              <Col xs={6}>
                <BackButton url={`/projects/${project_id}/project_materials`} />
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Product;

import React from "react";
import ProjectOverview from "../ProjectOverview/ProjectOverview";
import { useLocation } from "react-router-dom";

function EditProject({ account_id, account_slug }) {
  const locationHook = useLocation();
  return (
    <ProjectOverview
      project_id={locationHook.state.id}
      account_id={account_id}
      account_slug={account_slug}
      locationHook={locationHook}
    />
  );
}

export default EditProject;

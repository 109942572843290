import React, { useState, useEffect, useImperativeHandle } from "react";
import { Form, Row, Col } from "react-bootstrap";
import ControlledDropDownV2 from "@components/Forms/ControlledDropDownV2";
import { useNavigate } from "react-router";
import { Expense, OptionType } from "../types/Expense";
import {
  GetProjectByIdApiResponse,
  V1AccountResource,
  V1PhaseResource,
  V1ResourceRateResource,
  V1ResourceResource,
  useCreateProjectExpenseMutation,
  useListExpenseCategoriessForAccountQuery,
  useListProjectPhasesForAccountQuery,
  useListServiceCategorysForAccountQuery,
  useUpdateProjectExpenseMutation,
} from "@generated";
import FormTextField from "@components/Forms/FormTextField";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import {
  findResourceByNameAndReturnPromise,
  handleAlert,
  renderResourceOptions,
} from "@utils/helperFunctions";
import useResourceRates from "./api/useResourceRates";
import useOauth from "@utils/customHooks/useOauth";
import API from "@utils/API/API";
import { FormNumberFieldV2 } from "@components/FormsV2/FormNumberFieldV2";
import { Select2V2 } from "@components/FormsV2";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import RequiredReactSelect from "@components/FormsV2/RequiredReactSelect/RequiredReactSelect";

interface Props {
  expenseProp?: Expense;
  project_id: number;
  account: {
    data: V1AccountResource;
    included: V1PhaseResource[] | V1ResourceResource[] | [];
  };
  permission: string;
  project: GetProjectByIdApiResponse;
}

const ExpenseForm = React.forwardRef(
  ({ expenseProp, project_id, account, permission, project }: Props, ref) => {
    // Redux / ENV
    const { accountSlug } = useSelector((state: RootState) => state.slug);
    const [authorizationCode] = useOauth();
    const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

    // Get Service Categories
    const {
      data: lobs,
      error: lobsError,
      isLoading: lobsLoading,
      refetch: lobsRefetch,
    } = useListServiceCategorysForAccountQuery({
      slug: accountSlug,
    });

    // Get Expense Categories
    const {
      data: expenseCategoriesList,
      error: expenseCategoriesError,
      isLoading: expenseCategoriesLoading,
      refetch: refetchExpenseCategories,
    } = useListExpenseCategoriessForAccountQuery({
      slug: accountSlug,
      include: ["phase"],
    });

    // Resources
    const projectResources = project?.included?.filter(
      (item) =>
        item.type === "project-resources" && item?.attributes?.["active"]
    );
    const allResources = account?.["included"]?.filter(
      (item) => item.type === "resources"
    );
    const resources = account?.["included"]?.filter(
      (item) => item.type === "resources" && item?.attributes?.["active"]
    );
    const resourcesFromResourceRates =
      account?.data?.attributes?.settings?.["project_resource_Rates"] || false;
    const rateTableId =
      project?.included
        ?.filter(
          (item) => item.type === "rate-tables" && item?.attributes?.["active"]
        )[0]
        .id?.toString() || "";

    // Get Resource Rates
    const {
      resourceRates,
      resourceRatesError,
      resourceRatesLoading,
      pageMeta,
      resourceRatesRefetch,
    } = useResourceRates({
      resources,
      resourcesFromResourceRates: resourcesFromResourceRates,
      filter: { "rate-table": rateTableId },
    });

    const {
      data: phases,
      error: phasesError,
      isLoading: phasesLoading,
      refetch: phasesRefetch,
    } = useListProjectPhasesForAccountQuery({
      slug: accountSlug,
      filter: { active: true, project: project_id },
      include: ["phase", "project"],
    });

    // Location States / React Router
    const location = window.location.href;
    const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
    var locationArr = location.split("/");
    var id = locationArr[locationArr.length - 2];
    let navigate = useNavigate();

    // Field States
    const [expense, setExpense] = useState<string>(
      expenseProp?.expenseCategory || ""
    );
    const [newExpenseId, setNewExpenseId] = useState<number | null>(null);
    const [description, setDescription] = useState(
      expenseProp?.description || ""
    );
    const [phase, setPhase] = useState(
      expenseProp?.phase?.label || "Assign to Phase"
    );
    const [newPhaseId, setNewPhaseId] = useState<string | null>(
      expenseProp?.phase?.value.toString() || null
    );
    const [amountDisabled, setAmountDisabled] = useState(false);
    const [phaseDisabled, setPhaseDisabled] = useState(false);
    const [resource, setResource] = useState<OptionType>(
      expenseProp?.resource || { label: "Select Resource", value: 0 }
    );
    const [newResourceId, setNewResourceId] = useState<null | string>(null);
    const [selectedService, setSelectedService] = useState<OptionType>(
      expenseProp?.service || { label: "Select Service", value: 0 }
    );
    const [projectServiceId, setProjectServiceId] = useState(
      expenseProp?.service?.value || null
    );
    const [quantity, setQuantity] = useState(expenseProp?.quantity || "1");
    const [markup, setMarkup] = useState(expenseProp?.markup || "0.0");
    const [rate, setRate] = useState(expenseProp?.rate || "0.0");
    const [filteredResources, setFilteredResources] = useState(
      resourcesFromResourceRates ? resourceRates : resources
    );

    // Mutations
    const [createProjectExpense] = useCreateProjectExpenseMutation();
    const [updateProjectExpense] = useUpdateProjectExpenseMutation();

    useEffect(() => {
      if (lastWordInLocation !== "new") {
        setAmountDisabled(true);
        if (phase !== "Assign to Phase") {
          setPhaseDisabled(true);
        }
      }
    }, [authorizationCode, expenseProp]);

    useImperativeHandle(ref, () => ({
      saveExpense,
    }));

    // Alert States
    const [errorMessages, setErrorMessages] = useState<string | string[]>(
      "Something went wrong! Your changes could not be saved at this time."
    );
    const [showFailAlert, setShowFailAlert] = useState(false);
    const [successMessage, setSuccessMessage] = useState(
      "Project Expense saved successfully!"
    );
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [resourceRequired, setResourceRequired] = useState(false);

    const expenseOptions: any = expenseCategoriesList?.data?.map((expense) => {
      return (
        <option accessKey={expense?.id?.toString()} key={expense?.id}>
          {expense?.attributes?.name}
        </option>
      );
    }) || [
      <option value="" accessKey={"0"} key={0}>
        Select Travel/Expense
      </option>,
    ];

    const phaseOptions: any = (phases?.data || []).map((phase) => {
      return (
        (
          <option accessKey={phase?.id?.toString()} key={phase.id}>
            {phase?.attributes?.["name"]}
          </option>
        ) || (
          <option accessKey={"0"} key={0}>
            Assign to Phase
          </option>
        )
      );
    });

    const services = project?.included?.filter(
      (item) => item.type == "project-services"
    );
    const serviceOptions = (services || []).map((service) => {
      return { label: service?.attributes?.["name"], value: service.id };
    }) || [{ label: "Select a Service", value: "select" }];

    const handleExpenseChange = (resource, expense) => {
      (expenseCategoriesList?.data || []).forEach((expenseCategory) => {
        if (expenseCategory.attributes?.name === expense) {
          if (expenseCategory) {
            setNewExpenseId(Number(expenseCategory.id));
          }
          let rate = expenseCategory.attributes?.rate;
          let useResourceRate =
            expenseCategory.attributes?.["use-resource-rate"] || false;
          let phaseData = expenseCategory.relationships?.phase?.data;
          let resourceId = "";

          if (useResourceRate) {
            setResourceRequired(true);
          }

          // Handle Resource Rate
          if (useResourceRate && resource.value !== 0) {
            allResources.forEach(
              (res: V1ResourceResource | V1ResourceRateResource | any) => {
                if (res?.attributes?.name === resource.label) {
                  resourceId = res.id.toString();
                }
              }
            );

            API.Get(
              `${apiHost}/${accountSlug}/v1/resource-rates?filter[rate-table]=${rateTableId}&filter[resource]=${resourceId}`,
              authorizationCode
            ).then((res) => {
              if (res.data.data !== null && res.data.data.length !== 0) {
                setRate(res.data.data[0].attributes["expense-rate"]);
                setAmountDisabled(true);
              }
            });
          } else {
            setRate("0.0");
          }

          // Set Rate and Phase
          if (rate !== null) setRate(rate || 0);
          (phases?.data || []).forEach((phase) => {
            if (
              phaseData !== null &&
              phase?.["relationships"]?.phase?.data?.id == phaseData?.id
            ) {
              setPhase(phase?.attributes?.["name"] || "");
              setNewPhaseId(phaseData?.id?.toString() || null);
              setPhaseDisabled(true);
            } else {
              setPhase("Assign to Phase");
              setNewPhaseId(null);
              setPhaseDisabled(false);
            }
          });

          if (rate !== null || useResourceRate) {
            setAmountDisabled(true);
          }
          if (rate === null && !useResourceRate) {
            setAmountDisabled(false);
          }

          let markup = expenseCategory.attributes?.["markup"];
          setMarkup(markup);
        }
      });
    };

    const setResourceRelationship = (expenseData) => {
      if (newResourceId && newResourceId !== null && newResourceId !== "0") {
        if (newResourceId.includes("resources")) {
          expenseData.relationships.resource = {
            data: {
              type: "resources",
              id: Number(newResourceId.split("-")[1]),
            },
          };
        }
        if (newResourceId.includes("rate")) {
          expenseData.relationships["resource-rate"] = {
            data: {
              type: "resource-rates",
              id: Number(newResourceId.split("-")[2]),
            },
          };
        }
        if (newResourceId.includes("project")) {
          delete expenseData.relationships.resource;
          expenseData.relationships["project-resource"] = {
            data: {
              type: "project-resources",
              id: Number(newResourceId.split("-")[2]),
            },
          };
        }
      }
    };

    const clearForm = () => {
      setExpense("");
      setDescription("");
      setPhase("Assign to Phase");
      setResource({ label: "Select Resource", value: 0 });
      setSelectedService({ label: "Select Service", value: 0 });
      setQuantity("1");
      setRate("0.0");
      setMarkup("0.0");
      setAmountDisabled(false);
      setNewExpenseId(null);
      setNewPhaseId(null);
      setNewResourceId(null);
      setProjectServiceId(null);
      setResourceRequired(false);
    };

    const getProjectServiceRelationship = () => {
      return projectServiceId
        ? { data: { id: projectServiceId, type: "project-services" } }
        : null;
    };

    const getPhaseRelationship = () => {
      if (newPhaseId && newPhaseId !== null && newPhaseId !== "0") {
        return { data: { type: "project-phases", id: parseInt(newPhaseId) } };
      }
    };

    const getExpenseCategoryRelationship = () => {
      if (newExpenseId !== null || 0) {
        return { data: { type: "expense-categories", id: newExpenseId } };
      }
    };

    const saveExpense = (saveAndAddClicked) => {
      if (resourceRequired && resource.value == 0) {
        return;
      }
      let expenseData: any = {
        type: "project-expenses",
        id: expenseProp?.id,
        attributes: {
          description: description,
          rate: rate,
          quantity: quantity,
          markup: markup,
        },
        relationships: {
          project: { data: { type: "projects", id: project_id } },
          "project-phase": getPhaseRelationship(),
          "expense-category": getExpenseCategoryRelationship(),
          "project-service": getProjectServiceRelationship(),
        },
      };

      setResourceRelationship(expenseData);

      if (lastWordInLocation !== "new") {
        updateProjectExpense({
          slug: accountSlug,
          id: Number(expenseData.id),
          body: { data: expenseData },
        })
          .unwrap()
          .then((response) => {
            if (response?.data?.id) {
              navigate(`/projects/${project_id}/project_expenses`, {
                state: { edit: true },
              });
            }
          })
          .catch((err) => {
            let msg = err?.data?.errors?.[0]?.detail;
            setErrorMessages(
              msg
                ? msg
                : "Something went wrong. Your changes could not be saved at this time."
            );
            setShowFailAlert(true);
          });
      } else {
        delete expenseData.id;
        createProjectExpense({
          slug: accountSlug,
          body: { data: expenseData },
        })
          .unwrap()
          .then((response) => {
            if (response?.data?.id) {
              if (saveAndAddClicked) {
                clearForm();
                setSuccessMessage("Project Expense created successfully!");
                setShowSuccessAlert(true);
              } else {
                navigate(`/projects/${project_id}/project_expenses`, {
                  state: { new: true },
                });
              }
            }
          })
          .catch((err) => {
            let msg = err?.data?.errors?.[0]?.detail;
            setErrorMessages(
              msg
                ? msg
                : "Something went wrong. Your changes could not be saved at this time."
            );
            setShowFailAlert(true);
          });
      }
    };

    if (
      expenseCategoriesLoading ||
      !expenseCategoriesList ||
      !account ||
      !lobs ||
      lobsLoading
    ) {
      return <SmallSpinner />;
    }

    return (
      <Form style={{ marginLeft: "16px" }} className="expenseForm">
        {handleAlert(
          showFailAlert,
          errorMessages,
          setShowFailAlert,
          "warning",
          ToastAlert
        )}
        {handleAlert(
          showSuccessAlert,
          successMessage,
          setShowSuccessAlert,
          "success",
          ToastAlert
        )}
        <section>
          <div className="instructionalText bold">About</div>
          <Row>
            <Col sm={4}>
              <ControlledDropDownV2
                label="Expense *"
                value={expense}
                required={true}
                options={
                  lastWordInLocation === "new"
                    ? [
                        <option value="" accessKey={"0"} key={0}>
                          Select Travel/Expense
                        </option>,
                        expenseOptions,
                      ]
                    : expenseOptions
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  let exp = e.target.value;
                  setExpense(exp);
                  handleExpenseChange(resource, exp);
                  let selected = e.target["options"].selectedIndex;
                  setNewExpenseId(e.target["options"][selected].accessKey);
                }}
                readOnly={false}
                disabled={
                  (permission === "view" ||
                    permission === "create" ||
                    project?.data?.attributes?.status !== "building") &&
                  lastWordInLocation !== "new"
                }
              />
            </Col>
            <Col sm={8}>
              <FormTextField
                id="expenseDescription"
                label="Description"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                readOnly={
                  (permission === "view" ||
                    permission === "create" ||
                    project?.data?.attributes?.status !== "building") &&
                  lastWordInLocation !== "new"
                }
                required={false}
                placeholder="Enter description"
              />
            </Col>
          </Row>
        </section>
        <hr style={{ marginRight: "10px" }} />
        <section>
          <div className="instructionalText bold">Service Details</div>
          <Row>
            <Col sm={4}>
              <ControlledDropDownV2
                label="Phase"
                value={phase}
                disabled={phaseDisabled}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPhase(e.target.value);
                  let selected = e.target["options"].selectedIndex;
                  setNewPhaseId(e.target["options"][selected].accessKey);
                }}
                options={
                  phase === "Assign to Phase"
                    ? [
                        <option accessKey={"0"} key={0}>
                          Assign to Phase
                        </option>,
                        phaseOptions,
                      ]
                    : phaseOptions
                }
                readOnly={
                  (permission === "view" ||
                    permission === "create" ||
                    project?.data?.attributes?.status !== "building") &&
                  lastWordInLocation !== "new"
                }
                required={false}
              />
            </Col>
            <Col sm={4}>
              <RequiredReactSelect
                label="Resource"
                value={resource}
                required={resourceRequired}
                isInvalid={resourceRequired && resource.value == 0}
                onInputChange={(e) => {
                  let resourcesArray:
                    | V1ResourceResource[]
                    | V1ResourceRateResource[]
                    | any[] = resourcesFromResourceRates
                    ? resourceRates
                    : resources;
                  if (e !== "") {
                    findResourceByNameAndReturnPromise(
                      resourcesArray[0].type,
                      e,
                      accountSlug,
                      authorizationCode,
                      `resource,rate-table,line-of-business&filter[rate-table]=${rateTableId}`
                    ).then((response) => {
                      if (response.data.data) {
                        let newResources = response.data.data;
                        if (newResources[0].type == "resources") {
                          setFilteredResources(
                            newResources.filter((res) => res.attributes.active)
                          );
                        } else {
                          let resourcesToMatch = response.data.included.filter(
                            (item) =>
                              item.type == "resources" && item.attributes.active
                          );
                          let resourceRatesToReturn = new Set();
                          newResources.forEach((resourceRate) => {
                            resourcesToMatch.forEach((resource) => {
                              if (
                                resource.id ==
                                resourceRate.relationships.resource.data.id
                              ) {
                                resourceRatesToReturn.add(resourceRate);
                              }
                            });
                          });
                          setFilteredResources(
                            Array.from(resourceRatesToReturn)
                          );
                        }
                      }
                    });
                  }
                }}
                onChange={(e) => {
                  const resource = e as OptionType;
                  setResource(resource);
                  setNewResourceId(resource.value.toString());
                }}
                options={renderResourceOptions(
                  projectResources,
                  filteredResources,
                  lobs?.data
                )}
                isDisabled={
                  (permission === "view" ||
                    permission === "create" ||
                    project?.data?.attributes?.status !== "building") &&
                  lastWordInLocation !== "new"
                }
              />
            </Col>
            <Col xs={4}>
              <Select2V2
                label="Which service makes use of this expense?"
                value={selectedService}
                options={serviceOptions}
                onChange={(e) => {
                  setSelectedService({ label: e.label, value: e.value });
                  setProjectServiceId(e.value);
                }}
                isDisabled={
                  (permission === "view" ||
                    permission === "create" ||
                    project?.data?.attributes?.status !== "building") &&
                  lastWordInLocation !== "new"
                }
              />
            </Col>
          </Row>
        </section>
        <hr style={{ marginRight: "10px" }} />
        <section>
          <div className="instructionalText bold">Pricing</div>
          <Row>
            <Col sm={4}>
              <FormNumberFieldV2
                label="Quantity *"
                required={true}
                min={1}
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                step={1}
                placeholder="Enter quantity"
                readOnly={
                  (permission === "view" ||
                    permission === "create" ||
                    project?.data?.attributes?.status !== "building") &&
                  lastWordInLocation !== "new"
                }
              />
            </Col>
            <Col sm={4}>
              <FormNumberFieldV2
                label="Amount Per Item/Day"
                required={true}
                min={0}
                step={0.01}
                value={rate}
                onChange={(e) => {
                  setRate(e.target.value);
                }}
                placeholder="Enter amount per item / day"
                readOnly={
                  amountDisabled ||
                  ((permission === "view" ||
                    permission === "create" ||
                    project?.data?.attributes?.status !== "building") &&
                    lastWordInLocation !== "new")
                }
              />
            </Col>
            <Col sm={4}>
              <FormNumberFieldV2
                label="Target Margin (%) *"
                required={true}
                min={0}
                step={0.01}
                value={markup}
                onChange={(e) => setMarkup(e.target.value)}
                placeholder="Enter margin"
                readOnly={
                  (permission === "view" ||
                    permission === "create" ||
                    project?.data?.attributes?.status !== "building") &&
                  lastWordInLocation !== "new"
                }
              />
            </Col>
          </Row>
        </section>
      </Form>
    );
  }
);

export default ExpenseForm;

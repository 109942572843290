import { useGetAccountQuery } from "../../../../services/generated";
import { camelizeHyphenCaseKeys } from "@utils/helpers";
import { Account } from "app/javascript/src/types";

const useGetAccount = (slug: string) => {
  const { data, error, isLoading, refetch } = useGetAccountQuery({ slug });
  let account: Account | null = null;

  if (data?.data) {
    account = camelizeHyphenCaseKeys(data.data);
  }

  return { data: account, error, isLoading, refetch };
};

export default useGetAccount;

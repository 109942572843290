import React from "react";
import "./ScopeStackModal.css";
import { Button, Modal } from "react-bootstrap";
import CloseButtonX from "../CloseButtonX/CloseButtonX";

function ScopeStackModal({
  show,
  modalTitle,
  modalBody,
  button1Text,
  button2Text,
  handleClose,
  handleButton1Click,
  handleButton2Click,
  button2Disabled = false,
  deleteModal = false,
}) {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="ssModalTitle">{modalTitle}</Modal.Title>
          {handleClose ? <CloseButtonX onClose={handleClose} /> : null}
        </Modal.Header>
        <Modal.Body className="ssModalBody">{modalBody}</Modal.Body>
        {(button1Text || button2Text) && (
          <Modal.Footer className="ssModalFooter">
            {button1Text && (
              <Button
                variant="secondary"
                className="btnSeawhite"
                onClick={handleButton1Click}
              >
                {button1Text}
              </Button>
            )}
            {button2Text && (
              <Button
                variant={deleteModal ? "danger" : "primary"}
                className={deleteModal ? "delete" : "seafoamBgButton"}
                onClick={handleButton2Click}
                disabled={button2Disabled}
              >
                {button2Text}
              </Button>
            )}
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default ScopeStackModal;

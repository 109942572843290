import { FC, ReactNode, useEffect } from "react";
import { useStyles } from "./styles";
import { Button } from "@components/Button";
import "./styles/styles.css";

interface SlideoutMenuProps {
  isOpen: boolean;
  onClose: () => void;
  onClearAll?: () => void;
  onSave?: () => void;
  children: ReactNode;
  title: string;
  filterCount?: number;
}

const SlideoutMenu: FC<SlideoutMenuProps> = ({
  isOpen,
  onClose,
  onClearAll,
  onSave,
  children,
  title,
  filterCount = 0,
}) => {
  const styles = useStyles();

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("slideout-v2-no-scroll");
      let screenOverlay = document.createElement("div");
      screenOverlay.classList.add("slideout-v2-overlay");
      document.body.appendChild(screenOverlay);
    } else {
      document.body.classList.remove("slideout-v2-no-scroll");
      let screenOverlay = document.querySelector(".slideout-v2-overlay");
      if (screenOverlay) {
        screenOverlay.remove();
      }
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div
          className={`${styles.overlay} slideOutMenuV2`}
          onClick={onClose}
        ></div>
      )}
      <div className={`${styles.slideoutMenu} ${isOpen ? "open" : ""}`}>
        <div className={styles.header}>
          <h3>{title}</h3>
          <div className={styles.buttonWrapper}>
            <Button variant="secondary" onClick={onClose}>
              {onSave ? "Cancel" : "Close"}
            </Button>
            {onSave && ( // Render Save button only if onSave is provided
              <div className={styles.buttonSubWrapper}>
                <Button variant="primary" onClick={onSave}>
                  Save
                </Button>
                {onClearAll &&
                  filterCount > 0 && ( // Render Clear All button only if onClearAll is provided and filterCount is greater than 0
                    <div onClick={onClearAll}>Clear all ({filterCount})</div>
                  )}
              </div>
            )}
          </div>
        </div>
        <div className={styles.menuContent}>{children}</div>
      </div>
    </>
  );
};

export default SlideoutMenu;

export const defaultFlags = {
  "surveys.v2.enable": true,
  "insight-pupm.enable": true,
  "halo-psa.enable": true,
  "surveys.v2.manage.enable": true,
  "surveys.v2.archived.enable": true,
  "insight-lob-filtering": true,
  "all-language-tabs": false,
  "custom-oauth": true,
  "show-lob-editor": false,
  "show-resource-planner": false,
  "projects.products.v2.enable": true,
  "insight-pricing-adjustments": true,
  "connected.apps.v2.enable": true,
  "verinext.force.crm.opportunity.enabled": false,
  "projects.travel-and-expense.v2.enable": true,
  "projects.governance.v2.enable": true,
  "projects.customer-summary.minimal": false,
  "vendor-quotes.v2.enable": true,
  "vendor-quotes.v2.import": true,
  "professional-services-react.enable": true,
  "document-template.v2.enable": true,
  "professional-services-react.manage.enable": false
};

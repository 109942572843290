import React, { useState, useRef } from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import QuoteForm from "../common/QuoteForm/QuoteForm";

function New({ project_id, account, permission, project }) {
  const navigate = useNavigate();
  const submitRef = useRef<any>();

  return (
    <div className="newQuotePage">
      <Card>
        <Card.Header
          style={{
            backgroundColor: "#fff",
            color: "#1C2655",
            padding: "0px 16px",
          }}
        >
          <Row style={{ padding: "20px 0 0 20px", alignItems: "center" }}>
            <Col sm={1}>
              <Button
                className="btnSeawhite"
                onClick={(e) =>
                  navigate(`/projects/${project_id}/quotes`, {
                    state: null,
                  })
                }
              >
                Cancel
              </Button>
            </Col>
            <Col sm={6} className="text24">
              Add Vendor Quote
            </Col>
            {permission !== "view" &&
            project?.data?.attributes?.status === "building" ? (
              <Col style={{ marginLeft: "155px" }} sm={2}>
                <Button
                  style={{ width: "200px" }}
                  className="squareGreenButton"
                  onClick={() => {
                    submitRef.current?.saveQuote(true);
                  }}
                >
                  Save and Add Another
                </Button>
              </Col>
            ) : null}
            {permission !== "view" &&
            project?.data?.attributes?.status === "building" ? (
              <Col sm={1}>
                <Button
                  className="squareGreenButton"
                  onClick={() => {
                    submitRef.current?.saveQuote(false);
                  }}
                >
                  Save
                </Button>
              </Col>
            ) : null}
          </Row>
        </Card.Header>
        <hr style={{ marginLeft: "30px", marginRight: "30px" }} />
        <Card.Body>
          <QuoteForm
            project_id={project_id}
            account={account}
            ref={submitRef}
            permission={permission}
            project={project}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default New;

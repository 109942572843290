import React, { FC, useEffect, useState } from "react";
import DataTable from "@components/DataTable";
import "./ServicesTable.css";
import { Service, Subservice } from "../types/Service";
import { ColumnDef } from "@tanstack/react-table";

interface ServiceTableProps {
  servicesPassed: any[];
  serviceColumns: any[];
  onDragEnd?: (item) => void;
  subserviceColumns: any[];
  addSubservice?: (selectedService) => void;
  deleteService?: (selectedService) => void;
  hasSettings?: boolean;
  hasDragAndDrop?: boolean;
  hookType?: string;
  moveToHook?: any;
}

export const ServicesTable: FC<ServiceTableProps> = ({
  servicesPassed,
  serviceColumns,
  onDragEnd,
  subserviceColumns,
  addSubservice,
  deleteService,
  hasSettings,
  hasDragAndDrop,
  hookType,
  moveToHook,
}) => {
  const [services, setServices] = useState<any[] | []>(servicesPassed || []);
  const [selectedService, setSelectedService] =
    useState<Service | Subservice>();
  const [selectedSubservice, setSelectedSubservice] =
    useState<Service | Subservice>();

  useEffect(() => {
    const servicesWithCollapsedSubservices = servicesPassed.map((service) => {
      return {
        ...service,
        collapsedData: (
          <div>
            <div className="instructionalText bold">Subservices</div>
            <DataTable
              classForTable={`subserviceTableForService${service?.id}`}
              data={
                service?.subservices?.length && service?.subservices?.length > 0
                  ? service.subservices
                  : [
                      {
                        id: "noRow",
                        name: "No subservices exist on this service",
                      },
                    ]
              }
              columns={subserviceColumns}
              selectable={false}
              striped={true}
              selectedIds={[]}
              setSelectedIds={() => null}
              dragAndDrop={
                service?.subservices?.length &&
                service?.subservices?.length > 1 &&
                hasDragAndDrop
              }
              isCollapsible={false}
              hasSettings={service?.subservices?.length > 0 && hasSettings}
              settingsListItems={[
                {
                  name: "Delete",
                  onClick: () =>
                    deleteService
                      ? deleteService(selectedSubservice)
                      : () => null,
                  disabled: false,
                },
              ]}
              onSettingsClick={(
                subservice: Subservice,
                settingsMenuOpen: boolean
              ) => {
                setSelectedSubservice(subservice);
              }}
              settingsMenuStyles={{ right: "8%", marginTop: "60px" }}
              hookType={hookType}
              moveToUpdateHook={moveToHook}
              onDragEnd={
                onDragEnd ? (item) => onDragEnd(item) : () => null
              }
            />
          </div>
        ),
      };
    });
    setServices(servicesWithCollapsedSubservices);
  }, [servicesPassed, selectedSubservice]);

  return (
    <div>
      <DataTable
        classForTable="servicesTable"
        data={
          services?.length > 0
            ? services
            : [
                {
                  id: "noRow",
                  phase: {
                    label: "No services exist, please add or import",
                    value: 0,
                  },
                },
              ]
        }
        columns={serviceColumns}
        selectable={false}
        striped
        selectedIds={[]}
        setSelectedIds={() => null}
        dragAndDrop={services.length > 1 && hasDragAndDrop}
        isCollapsible={services.length > 0}
        hasSettings={services.length > 0 && hasSettings}
        settingsListItems={[
          {
            name: "Add Subservice",
            onClick: () =>
              addSubservice ? addSubservice(selectedService) : () => null,
            disabled: false,
          },
          {
            name: "Delete",
            onClick: () =>
              deleteService ? deleteService(selectedService) : () => null,
            disabled: false,
          },
        ]}
        onSettingsClick={(service: Service, settingsMenuOpen: boolean) => {
          setSelectedService(service);
        }}
        settingsMenuStyles={{ right: "5%", marginTop: "77px" }}
        hookType={hookType}
        moveToUpdateHook={moveToHook}
        onDragEnd={(item) => (onDragEnd ? onDragEnd(item) : null)}
      />
    </div>
  );
};

import { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import SearchField from "@components/Forms/SearchField/SearchField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import "./styles/styles";
import { Tab, Tabs } from "react-bootstrap";
import DataTable from "@components/DataTable";
import useListProducts from "./api/useListProducts";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faTableLayout } from "@fortawesome/pro-solid-svg-icons";
import ControlledDropDownV2 from "@components/Forms/ControlledDropDownV2";
import { Product } from "../types";
import { ColumnDef } from "@tanstack/react-table";
import ProductSlideout from "../common/ProductSlideOut";
import { useNavigate } from "react-router";
import Add from "../Add";
import {
  V1UserFavoriteResource,
  useGetAccountQuery,
  FavoriteProduct,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";
import { useListAccountConnectionsQuery } from "@generated";
import {
  capitalizeFirstLetter,
  snakeToPascalCase,
  splitPascalCase,
} from "@utils/helperFunctions";
import FavoriteProducts from "./FavoriteProducts";
import {
  useCreateUserFavoriteMutation,
  useWhoAmIQuery,
  useDeleteUserFavoriteMutation,
} from "@generated";
import useListFavoriteProducts from "./FavoriteProducts/api/useListFavoriteProducts";

const New = (): JSX.Element => {
  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);
  const [allSelectedProducts, setAllSelectedProducts] = useState<Product[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [slideoutOpen, setSlideoutOpen] = useState<boolean>(false);
  const [productToPreview, setProductToPreview] =
    useState<Product | null>(null);
  const [searchInput, setSearchInput] = useState<string>("");
  //The following two variables are set to the option.value that it matches in the options array
  const [productSource, setProductSource] = useState<string>("standard");
  const [searchBy, setSearchBy] = useState<string>("name");
  const [searchByOptions, setSearchByOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [onAddPage, setOnAddPage] = useState(false);
  const navigate = useNavigate();
  const [createUserFavorite] = useCreateUserFavoriteMutation();
  const [deleteUserFavorite] = useDeleteUserFavoriteMutation();
  const [favoriteProductCount, setFavoriteProductCount] = useState<number>(0);
  const [triggerRefetch, setTriggerRefetch] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [favoriteProductIds, setFavoriteProductIds] = useState<
    { favoriteId: string; sourceKey: string }[]
  >([]);
  const [currentTab, setCurrentTab] = useState(1);

  const projectId = parseInt(window.location.href.split("/")[4]);

  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { data: account } = useGetAccountQuery({ slug: accountSlug });
  const { data: me } = useWhoAmIQuery();

  const { products: favoriteProducts, isLoading: favoritesLoading } =
    useListFavoriteProducts();

  const {
    products: productsData,
    pageMeta: productsMeta,
    isLoading: productsLoading,
    refetch,
    error,
  } = useListProducts({
    page: { number: currentPage, size: pageSize },
    filter: {},
    source: productSource,
  });

  const {
    data: accountConnections,
    isLoading: isAccountConnectionsLoading,
    error: accountConnectionsError,
  } = useListAccountConnectionsQuery({
    slug: accountSlug,
    filter: { products: true, active: true },
  });

  useEffect(() => {
    let newSelectedProducts = [...allSelectedProducts];
    selectedProductIds.forEach((id) => {
      const productExistsInSelected = allSelectedProducts.find(
        (product) => String(product.id) === String(id)
      );

      const source = currentTab === 1 ? products : favoriteProducts;
      const foundProductOnPage = source.find(
        (prod) => prod && String(prod.id) === String(id)
      );
      if (!productExistsInSelected) {
        if (foundProductOnPage) {
          //check if product comes from third party connection when assigning source
          const connection = accountConnections?.data?.find(
            (connection) => Number(connection.id) === Number(productSource)
          );
          newSelectedProducts.push({
            ...foundProductOnPage,
            source:
              (connection?.attributes?.service as Product["source"]) ||
              "standard",
          });
        }
      }
    });
    newSelectedProducts = newSelectedProducts.filter((product) =>
      selectedProductIds.includes(product.id.toString())
    );
    setAllSelectedProducts(newSelectedProducts);
  }, [selectedProductIds]);

  const productSourceOptions =
    accountConnections && accountConnections.data
      ? accountConnections.data.map((connection) => {
          if (connection && connection.attributes) {
            return {
              label: splitPascalCase(
                snakeToPascalCase(connection.attributes.service)
              ),
              value: connection.id,
            };
          } else {
            return {
              label: "Select",
              value: "select",
            };
          }
        })
      : [];

  productSourceOptions.unshift({
    label: "Standard Products",
    value: "standard",
  });

  useEffect(() => {
    if (productsData) {
      setProducts(
        productsData.map((product) => ({
          ...product,
          isFavorited: favoriteProductIds.some(
            (favorite) => favorite.sourceKey === product.id.toString()
          ),
          favoriteId: favoriteProductIds.find(
            (favorite) => favorite.sourceKey === product.id.toString()
          )?.favoriteId,
        }))
      );
    }
  }, [productsData, favoriteProductIds]);

  useEffect(() => {
    const dynamicFilter = searchInput.trim() ? { [searchBy]: searchInput } : {};
    refetch({
      source: productSource,
      filter: dynamicFilter,
      page: { number: currentPage, size: pageSize },
    });
  }, [pageSize, currentPage]);

  useEffect(() => {
    //Set options for the "Search By" dropdown
    const options = [
      { label: "Name", value: "name" },
      { label: "Manufacturer Part #", value: "manufacturer-part-number" },
      { label: "SKU", value: "sku" },
      { label: "Internal Product ID", value: "product-id" },
    ];
    setSearchByOptions(
      productSource === "standard"
        ? options
        : options.slice(0, options.length - 1)
    );
  }, [productSource]);

  const currencyUnit =
    account?.data?.attributes?.["field-labels"]?.["currency_unit"] ?? "$";

  const productsTableColumns: ColumnDef<Product>[] = [
    {
      header: "Name",
      cell(props) {
        return (
          <div className="displayFlex">
            {props.row.original.name}{" "}
            {props.row.original.id !== 0 && (
              <FontAwesomeIcon
                className="productPreview"
                icon={faEye}
                onClick={() => {
                  const source = currentTab === 1 ? products : favoriteProducts;
                  const productSelected = source.find(
                    (product) =>
                      String(product.id) === String(props.row.original.id)
                  );
                  if (productSelected) {
                    setProductToPreview(productSelected);
                    setSlideoutOpen(true);
                  }
                }}
              />
            )}
          </div>
        );
      },
    },
    {
      header: "MFR Part",
      cell(props) {
        return <>{props.row.original.mfrPartNumber}</>;
      },
    },
    {
      header: "SKU",
      cell(props) {
        return <>{props.row.original.sku}</>;
      },
    },
    {
      header: "Int Prod Id",
      cell(props) {
        return <>{props.row.original.productId}</>;
      },
    },
    {
      header: "Units",
      cell(props) {
        return <>{props.row.original.units}</>;
      },
    },
    {
      header: "Category",
      cell(props) {
        return <>{props.row.original.category}</>;
      },
    },
    {
      header: "Unit Price",
      cell(props) {
        return (
          <>
            {props.row.original.id !== 0 && (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={(Number(props.row.original.price) || 0).toFixed(2)}
              />
            )}
          </>
        );
      },
    },
  ];

  const handleFavoriteClick = async (
    resource: Product,
    isFavorited: boolean
  ) => {
    if (isFavorited) {
      try {
        await deleteUserFavorite({
          slug: accountSlug,
          id: Number(resource.favoriteId),
        });

        setProducts((prevProducts) =>
          prevProducts.map((product) =>
            product.id === resource.id
              ? { ...product, isFavorited: false }
              : product
          )
        );
      } catch (error) {
        console.error("Error adding favorite:", error);
      }
      setTriggerRefetch(true);

      return;
    }

    const favoriteProduct: FavoriteProduct = {
      name: resource.name,
      description: resource.description,
      product_id: resource.productId,
      sku: resource.sku,
      manufacturer_part_number: resource.mfrPartNumber,
      vendor_name: resource.vendor,
      category: resource.category,
      subcategory: resource.subcategory,
      unit_of_measure: resource.units,
      unit_cost: parseFloat(resource.unitCost || "") || 0,
      unit_price: parseFloat(resource.price || "") || 0,
      vendor_discount: parseFloat(resource.vendorDiscount || "") || 0,
      markup: parseFloat(resource.markup || "") || 0,
    };

    const favoriteResource = {
      type: "user-favorites",
      attributes: {
        name: resource.name,
        "user-id": me?.data?.id,
        ["content-type"]: "product",
        source: "standard",
        ["source-key"]: resource.id.toString(),
        content: favoriteProduct,
      },
    };

    try {
      await createUserFavorite({
        slug: accountSlug,
        body: {
          data: favoriteResource as any, // Api docs require any type
        },
      });

      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === resource.id
            ? { ...product, isFavorited: true }
            : product
        )
      );

      setTriggerRefetch(true);
    } catch (error) {
      console.error("Error adding favorite:", error);
    }
  };

  const handleSearch = (e) => {
    if (e.key === "Enter" || e.type === "submit") {
      e.preventDefault();
      const dynamicFilter = searchInput.trim()
        ? { [searchBy]: searchInput }
        : {};
      refetch({
        source: productSource ? productSource.toString() : "",
        filter: searchInput !== "" ? dynamicFilter : {},
        page: { number: currentPage, size: pageSize },
      });
    }
  };

  if (onAddPage) {
    return (
      <Add
        products={allSelectedProducts}
        selectedProductIds={selectedProductIds}
        setSelectedProductIds={setSelectedProductIds}
        setOnAddPage={(bool) => setOnAddPage(bool)}
        currencyUnit={currencyUnit}
        projectId={projectId}
      />
    );
  } else {
    return (
      <div className="addProducts">
        <ProductSlideout
          product={productToPreview}
          slideoutOpen={slideoutOpen}
          setSlideoutOpen={setSlideoutOpen}
        />
        <div className="addProductsCard">
          <div className="addProductsHeader">
            <div className="displayFlex">
              <Button
                className={"ssButtonBgWhite"}
                onClick={(e) =>
                  navigate(`/projects/${projectId}/project_materials`, {
                    state: null,
                  })
                }
              >
                Cancel
              </Button>
              <h2>Search Products</h2>
            </div>
            <Button
              className={`actionButton ${
                selectedProductIds.length === 0 ? "disabled" : ""
              }`}
              onClick={() => setOnAddPage(true)}
              disabled={selectedProductIds.length === 0}
            >
              Continue to Add&nbsp;
              {selectedProductIds.length > 0 &&
                `(${selectedProductIds.length})`}
            </Button>
          </div>
          <Form onSubmit={handleSearch}>
            <div className="searchContainer">
              <ControlledDropDownV2
                label="Product Source"
                value={
                  productSourceOptions.find(
                    (option) => option.value == productSource
                  )?.label || "Standard Products"
                }
                options={productSourceOptions.map((option, i) => (
                  <option key={i} accessKey={option.value?.toString()}>
                    {option.label}
                  </option>
                ))}
                onChange={(e) => {
                  const productSource = productSourceOptions.find(
                    (option) => option.label == e.target.value
                  );
                  setProductSource(
                    productSource && productSource.value
                      ? productSource.value.toString()
                      : ""
                  );
                }}
                required
                readOnly={false}
                disabled={false}
              />
              <ControlledDropDownV2
                label="Search By"
                value={
                  searchByOptions.find((option) => option.value == searchBy)
                    ?.label || "Name"
                }
                options={searchByOptions.map((option, i) => (
                  <option key={i} accessKey={option.value}>
                    {option.label}
                  </option>
                ))}
                onChange={(e) => {
                  const searchBy = searchByOptions.find(
                    (option) => option.label == e.target.value
                  );
                  setSearchBy(searchBy ? searchBy.value : "");
                }}
                required
                readOnly={false}
                disabled={false}
              />
              <div className="searchBar">
                <FormFieldLabel label="Search Catalog" className={""} />
                <div className="displayFlex">
                  <SearchField
                    placeholder="Enter search term to get started"
                    onChange={(e) => setSearchInput(e.target.value)}
                    value={searchInput}
                    onClick={() => {
                      setSearchInput("");
                      refetch({
                        source: productSource ? productSource.toString() : "",
                        filter: {},
                        page: { number: currentPage, size: pageSize },
                      });
                    }}
                  />
                  {/* <FontAwesomeIcon className="tableIcon" icon={faTableLayout} /> */}
                </div>
              </div>
            </div>
          </Form>

          <div>
            <Tabs
              defaultActiveKey={1}
              id="uncontrolled-tab"
              onSelect={(eventKey) => setCurrentTab(Number(eventKey))}
            >
              <Tab
                eventKey={1}
                title={`All Products ${
                  productsMeta?.recordCount
                    ? `(${productsMeta.recordCount})`
                    : ""
                }`}
              >
                <>
                  {productsLoading || favoritesLoading ? (
                    <SmallSpinner />
                  ) : (
                    <DataTable
                      data={
                        products?.length > 0 && !error
                          ? products
                          : [
                              {
                                id: 0,
                                name: "No products found",
                              },
                            ]
                      }
                      columns={productsTableColumns}
                      selectable={products?.length > 0 && !error}
                      bordered
                      striped
                      hover
                      totalPages={productsMeta?.pageCount ?? undefined}
                      totalRows={productsMeta?.recordCount ?? undefined}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      onPageSizeChange={setPageSize}
                      selectedIds={selectedProductIds}
                      setSelectedIds={setSelectedProductIds}
                      paginationEnabled={!!(productsMeta?.pageCount ?? 0)}
                      pageSizeEnabled={!!(productsMeta?.pageCount ?? 0)}
                      favoriteable={products?.length > 0 ? true : false}
                      onFavoriteClick={handleFavoriteClick}
                    />
                  )}
                </>
              </Tab>
              <Tab eventKey={2} title={`Favorites (${favoriteProductCount})`}>
                {!favoritesLoading && (
                  <FavoriteProducts
                    setFavoriteProductCount={setFavoriteProductCount}
                    triggerRefetch={triggerRefetch}
                    setTriggerRefetch={setTriggerRefetch}
                    setFavoriteProductIds={setFavoriteProductIds}
                    selectedIds={selectedProductIds}
                    setSelectedIds={setSelectedProductIds}
                  />
                )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
};

export default New;

import React, { useEffect, useState } from "react";
import ServicesByPhase from "../ServicesByPhase";
import useProjectServicesData from "../api/useProjectServicesData";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import CreateProjectFooter from "../../CreateProjectFooter/CreateProjectFooter";

function ServicesAdded({
  authorizationCode,
  account_slug,
  subpage,
  setSubpage,
  projectId,
  setServicesHaveBeenAdded,
  onData,
  resources,
  rateType,
  onServicesData,
}) {
  const { data, error, loaded } = useProjectServicesData({
    account_slug,
    authorizationCode,
    projectId,
  });

  useEffect(() => {
    if (data !== null) {
      onData(data);
      onServicesData(data);
    }
  }, [data]);

  useEffect(() => {
    setServicesHaveBeenAdded(true);
  }, []);

  return loaded ? (
    <ServicesByPhase
      authorizationCode={authorizationCode}
      account_slug={account_slug}
      subpage={subpage}
      setSubpage={setSubpage}
      projectId={projectId}
      data={data}
      resources={resources}
      rateType={rateType}
    />
  ) : (
    <SmallSpinner />
  );
}

export default ServicesAdded;

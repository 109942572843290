import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { useListTeamsForAccountQuery } from "@generated";

interface Option {
  label: string;
  value: string;
}

const useListTeamsForAccount = () => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null; // Declare customError

  const { data, error, isLoading, refetch, isFetching } =
    useListTeamsForAccountQuery({
      slug: accountSlug,
    });

  let teams: Option[] = [];

  if (data?.data) {
    try {
      teams = data.data.map((team) => {
        if (!team.id) {
          throw new Error("Missing team.id");
        }
        if (!team.attributes?.name) {
          throw new Error("Missing team.attributes.name");
        }

        return {
          value: String(team.id),
          label: team.attributes.name || "",
        };
      });
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }
  return {
    teams,
    isLoading,
    error: error ?? customError,
    refetch,
    isFetching,
  };
};
export default useListTeamsForAccount;

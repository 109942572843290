import React, { useEffect, useState, useCallback } from "react";
import { Card, Button, Form, FormCheck, Row, Col } from "react-bootstrap";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import SearchField from "@components/Forms/SearchField/SearchField";
import getTableData from "./api/buildTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsFilter } from "@fortawesome/pro-solid-svg-icons";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import loadStandardServices from "./api/loadStandardServices";
import loadPhases from "../api/loadPhases";
import SlideOutMenuFilter from "@components/SlideOutMenu/SlideOutMenuSectioned";
import buildTextFilterSection from "./filterSections/buildTextFilterSection";
import FormTextField from "@components/Forms/FormTextField";
import PaginationNumbersAPI from "@components/Buttons/PaginationAPI/PaginationNumbersAPI";

const AddByServicesStandard = ({
  account_slug,
  authorizationCode,
  setSubpage,
  setServicesOnStandard,
  setSubservicesOnStandard,
  setPhasesOnStandard,
  servicesHaveBeenAdded,
  rateType,
}) => {
  const PAGE_SIZE = 10;
  const SERVICE_TYPES = ["professional_services", "managed_services"];

  const [advancedFilters, setAdvancedFilters] = useState({});
  const [hasAdvancedFilters, setHasAdvancedFilters] = useState(false);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [searchedLobs, setSearchedLobs] = useState([]);
  const [lobs, setLobs] = useState([]);
  const [displayedLobs, setDisplayedLobs] = useState([]);
  const [standards, setStandards] = useState([]);
  const [searchedServiceCategories, setSearchedServiceCategories] = useState(
    []
  );
  const [displayedServiceCategories, setDisplayedServiceCategories] = useState(
    []
  );
  const [searchedTags, setSearchedTags] = useState([]);
  const [displayedTags, setDisplayedTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [skus, setSKUs] = useState([]);
  const [selectedStandards, setSelectedStandards] = useState([]);
  const [standardToSearch, setStandardToSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [phases, setPhases] = useState([]);
  const [hasFilterPhases, setHasFilterPhases] = useState(false);
  const [meta, setMeta] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageArray, setPageArray] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);

  useEffect(() => {
    document.body.setAttribute("style", "background: #eee");
  }, []);

  const pluck = (arr, key) => arr.map((i) => i[key]);

  const handyApplyAdvancedFilters = () => {
    setShowAdvancedFilters(false);
    if (
      lobs.length === 0 &&
      serviceCategories.length === 0 &&
      tags.length === 0 &&
      skus.length === 0 &&
      phases.filter((phase) => phase.checked && phase.checked === true)
        .length === 0 &&
      serviceTypes === 0
    ) {
      return;
    }
    if (
      phases.filter((phase) => phase.checked && phase.checked === true).length >
      0
    ) {
      setHasFilterPhases(true);
    }
    setHasAdvancedFilters(true);
    setIsLoading(true);
    setAdvancedFilters({
      lob: [lobs, serviceCategories],
      sku: skus,
      ["tag_list"]: tags,
      phase: pluck(
        phases.filter((phase) => phase.checked && phase.checked === true),
        "id"
      ),
      ["service_type"]: serviceTypes,
    });
  };

  const handleApply = () => {
    setSubpage("applyStandards");
  };

  const handleCancelFilters = () => {
    setShowAdvancedFilters(false);
  };

  const handleClearFilters = () => {
    setDisplayedLobs([]);
    setDisplayedServiceCategories([]);
    setDisplayedTags([]);
    setSKUs([]);
    setLobs([]);
    setServiceCategories([]);
    setServiceTypes([]);
    setTags([]);
    setHasFilterPhases(false);
    setPhases([]);
    setShowAdvancedFilters(false);
    setHasAdvancedFilters(false);
    setIsLoading(true);
    setAdvancedFilters({});
  };

  const handleStandardSearch = (e) => {
    e.preventDefault();
    setIsLoading(true);
    fetchData();
  };

  const handleSelectStandard = (e) => {
    e.preventDefault();
    const standardId = e.target.value;
    const standard = standards.find((standard) => standard.id === standardId);
    setSelectedStandards(
      [...selectedStandards, standard].sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
    );
    setStandards(standards.filter((standard) => standard.id !== standardId));
    setServicesOnStandard(
      [...selectedStandards, standard].sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
    );
  };

  const handleRemoveStandard = (e) => {
    e.preventDefault();
    const standardId = e.target.value;
    const standard = selectedStandards.find(
      (standard) => standard.id === standardId
    );
    setStandards(
      [...standards, standard].sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
    );
    setSelectedStandards(
      selectedStandards.filter((standard) => standard.id !== standardId)
    );
  };

  const handleCancel = () => {
    if (servicesHaveBeenAdded) {
      setSubpage("servicesAdded");
    } else {
      setSubpage("accordians");
    }
  };

  const headerData = {
    rowClass: "",
    columns: [
      { class: "checkboxHeader", name: "" },
      {
        class: "",
        name: "Standard Name",
      },
      {
        class: "",
        name: "Service Category",
      },
    ],
  };

  const selectedStandardListTable = new ScopeStackTable(
    "selectedStandards",
    headerData,
    getTableData(selectedStandards, handleRemoveStandard, true),
    null
  );

  const standardListTable = new ScopeStackTable(
    "standards",
    headerData,
    getTableData(standards, handleSelectStandard, false),
    null
  );

  const skuSection = {
    sectionClass: "projectInfoSection",
    sectionContent: (
      <div style={{ padding: "0px 23px" }}>
        <FormTextField
          label={"SKU"}
          value={skus}
          onChange={(e) => setSKUs(e.target.value)}
          placeholder={"Search"}
        />
      </div>
    ),
  };

  const serviceType = () => ({
    sectionClass: "projectInfoSection",
    sectionContent: (
      <>
        <FormFieldLabel className="filterMenuLabel" label="Service Type" />
        <Form style={{ margin: "0px 8px 24px 8px" }}>
          {SERVICE_TYPES.map((service) => (
            <FormCheck
              className="phaseCheckbox"
              inline
              key={service}
              label={service
                .replace(/_/g, " ")
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
              name="group2"
              type="checkbox"
              id={service}
              onChange={(e) => {
                if (serviceTypes.includes(e.target.id)) {
                  setServiceTypes(
                    serviceTypes.filter((type) => type !== e.target.id)
                  );
                } else {
                  setServiceTypes([...serviceTypes, e.target.id]);
                }
              }}
            />
          ))}
        </Form>
      </>
    ),
  });

  const phaseSection = (phases) => ({
    sectionClass: "projectInfoSection",
    sectionContent: (
      <>
        <FormFieldLabel className="filterMenuLabel" label="Phase" />
        <Form style={{ margin: "8px" }}>
          {!phases || phases.length === 0 ? (
            <div key="none" style={{ textAlign: "center" }}>
              No phases found
            </div>
          ) : (
            phases.map((phase) => (
              <FormCheck
                className="phaseCheckbox"
                inline
                key={phase.id}
                label={phase.attributes.name}
                name="group1"
                type="checkbox"
                id={phase.id}
                onChange={(e) => {
                  const newPhases = phases.map((phase) => {
                    if (phase.id === e.target.id) {
                      return {
                        ...phase,
                        checked: !phase.checked,
                      };
                    }
                    return phase;
                  });
                  setPhases(newPhases);
                }}
              />
            ))
          )}
        </Form>
      </>
    ),
  });

  const activeAdvancedFiltersCount = () => {
    let count = 0;
    if (lobs.length > 0) {
      count += 1;
    }
    if (serviceCategories.length > 0) {
      count += 1;
    }
    if (tags.length > 0) {
      count += 1;
    }
    if (skus.length > 0) {
      count += 1;
    }
    if (hasFilterPhases) {
      count += 1;
    }
    if (serviceTypes.length > 0) {
      count += 1;
    }
    return count;
  };

  const fetchData = useCallback(async () => {
    if (!isLoading) {
      return;
    }

    const filters = (() => {
      const filtersToReturn = {};
      if (standardToSearch) {
        filtersToReturn.name = standardToSearch;
      }
      if (advancedFilters) {
        Object.keys(advancedFilters).forEach((key) => {
          if (advancedFilters[key].length > 0) {
            filtersToReturn[key] = advancedFilters[key];
          }
        });
      }
      return Object.keys(filtersToReturn).length === 0 ? null : filtersToReturn;
    })();

    const data = await loadStandardServices({
      account_slug,
      authorizationCode,
      filters,
      pageSize: PAGE_SIZE,
      pageNumber: currentPage,
      selectedIds: selectedStandards.map((standard) => standard.id),
    });

    const phasesData = await loadPhases({
      account_slug,
      authorizationCode,
    });

    const pageArray = (() => {
      const pageArrayToReturn = [];
      if (data.meta) {
        for (let i = 1; i <= data.meta["page-count"]; i += 1) {
          pageArrayToReturn.push(i);
        }
      } else {
        pageArrayToReturn.push(1);
      }
      return pageArrayToReturn;
    })();

    setPhases(phasesData.data || []);
    setPhasesOnStandard(phasesData.data || []);
    setStandards(data.data ? data.data.standardServices : []);
    setSubservicesOnStandard(data.data ? data.data.subservices : []);
    setIsLoading(false);
    setMeta(data.meta || { "page-count": 1 });
    setPageArray(pageArray);
  }, [advancedFilters, standardToSearch, isLoading]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <SlideOutMenuFilter
        show={showAdvancedFilters}
        setShow={(showAdvancedFilters) =>
          setShowAdvancedFilters(showAdvancedFilters)
        }
        menuHeader="Filter Results By"
        actionButtonText="Apply"
        onActionButtonClick={handyApplyAdvancedFilters}
        clearFields={
          hasAdvancedFilters
            ? `Clear All (${activeAdvancedFiltersCount()})`
            : null
        }
        onCancel={handleCancelFilters}
        onClearFieldsClick={handleClearFilters}
        showDivider={false}
        sectionsArray={[
          buildTextFilterSection(
            account_slug,
            authorizationCode,
            setSearchedLobs,
            searchedLobs,
            "Line of Business",
            "line-of-businesses",
            setLobs,
            setDisplayedLobs,
            displayedLobs
          ),
          serviceType(),
          buildTextFilterSection(
            account_slug,
            authorizationCode,
            setSearchedServiceCategories,
            searchedServiceCategories,
            "Service Category",
            "service-categories",
            setServiceCategories,
            setDisplayedServiceCategories,
            displayedServiceCategories
          ),
          skuSection,
          buildTextFilterSection(
            account_slug,
            authorizationCode,
            setSearchedTags,
            searchedTags,
            "Tag",
            "tags",
            setTags,
            setDisplayedTags,
            displayedTags
          ),
          phaseSection(phases),
        ]}
      />
      <Card style={{ padding: "10px" }}>
        <Card.Header
          style={{
            backgroundColor: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            lineHeight: "unset",
          }}
        >
          <div className="accordianTitle">Add Services From Standard</div>
          <div>
            <Button
              style={{ marginRight: "10px" }}
              onClick={() => handleCancel()}
              className="ssButtonBgWhite"
            >
              Cancel
            </Button>
            <Button
              style={
                selectedStandards.length === 0
                  ? { display: "none" }
                  : { marginRight: "10px" }
              }
              onClick={() => handleApply()}
              className="seafoamBgButton"
            >
              Add Standards
            </Button>
          </div>
        </Card.Header>
        <hr />
        <Card.Body>
          <div
            style={{ fontSize: "20px", marginBottom: "30px", marginLeft: "0" }}
            className="accordianSubtitle"
          >
            Select a standard service
          </div>
          <Row>
            <Col sm={6}>
              <Row
                style={{
                  marginBottom: "20px",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <Col sm={11}>
                  <FormFieldLabel label="Search Standards" />
                  <Form
                    style={{ width: "100%" }}
                    onSubmit={handleStandardSearch}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        handleStandardSearch(e);
                      }
                    }}
                  >
                    <SearchField
                      placeholder="Search by Standard Name"
                      value={standardToSearch}
                      onChange={(e) => setStandardToSearch(e.target.value)}
                      onClick={() => {
                        setStandardToSearch("");
                        setIsLoading(true);
                        fetchData();
                      }}
                    />
                  </Form>
                </Col>
                <Col
                  style={{
                    marginTop: "1.35em",
                    fontSize: "20px",
                    padding: 0,
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <FontAwesomeIcon
                    onClick={() => setShowAdvancedFilters(true)}
                    icon={faBarsFilter}
                    style={
                      hasAdvancedFilters
                        ? { color: "#418172", marginLeft: "0" }
                        : {}
                    }
                  />
                  {hasAdvancedFilters && (
                    <p
                      style={{
                        color: "#418172",
                        fontSize: "10px",
                        width: "fit-content",
                        margin: "0 auto",
                      }}
                    >
                      {activeAdvancedFiltersCount()} Active
                    </p>
                  )}
                </Col>
              </Row>
              <div style={{ marginTop: "40px" }}>
                <BaseTable
                  className="sansTable standardListTable"
                  striped={true}
                  hover={true}
                  bordered={true}
                  headerRows={standardListTable.buildHeaderRows()}
                  dataRows={standardListTable.buildDataRows()}
                  footerRows={standardListTable.buildFooterRows()}
                  isLoading={isLoading}
                />
                <PaginationNumbersAPI
                  pageCount={meta["page-count"]}
                  pageArray={pageArray}
                  currentPage={currentPage}
                  setCurrentPage={(page) => setCurrentPage(page)}
                  leftArrowOnClick={() => {
                    if (currentPage > 1) {
                      setCurrentPage(currentPage - 1);
                      setIsLoading(true);
                    }
                  }}
                  rightArrowOnClick={() => {
                    if (currentPage < meta["page-count"]) {
                      setCurrentPage(currentPage + 1);
                      setIsLoading(true);
                    }
                  }}
                  itemsPerPage={PAGE_SIZE}
                  onNumberClick={(_, currentPage) => {
                    setCurrentPage(currentPage);
                    setIsLoading(true);
                  }}
                />
              </div>
            </Col>
            <Col sm={6}>
              {selectedStandards.length > 0 && (
                <Row>
                  <div
                    style={{
                      fontSize: "20px",
                      marginTop: "47px",
                      marginLeft: "15px",
                    }}
                    className="accordianSubtitle"
                  >
                    Selected standard services
                  </div>
                </Row>
              )}
              {selectedStandards.length > 0 && (
                <div style={{ marginTop: "40px" }}>
                  <BaseTable
                    className="sansTable selectedStandardListTable"
                    striped={true}
                    hover={true}
                    bordered={true}
                    headerRows={selectedStandardListTable.buildHeaderRows()}
                    dataRows={selectedStandardListTable.buildDataRows()}
                    footerRows={selectedStandardListTable.buildFooterRows()}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default AddByServicesStandard;

import React, { useEffect, useState, useCallback } from "react";
import "../NewProjectDetails/NewProjectDetails.css";
import API from "@API";
import CreateProjectFooter from "../CreateProjectFooter/CreateProjectFooter";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Modal from "react-bootstrap/Modal";
import ProjectReview from "./ProjectReview/ProjectReview";
import SummaryReview from "./SummaryReview/SummaryReview";
import ServicesReview from "./ServicesReview/ServicesReview";
import PricingReview from "./PricingReview/PricingReview";
import StationaryModal from "@components/StationaryModal/StationaryModal";
import { Row, Col, Button, Form, FormCheck } from "react-bootstrap";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import Select from "react-select";
import loading from "./images/SS-icon-checkmark.gif";
import successImg from "./images/SS-icon-checkmark.png";
import { snakeToPascalCase, handleAlert, validateEmails } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";

function Review({
  account_slug,
  authorizationCode,
  projectId,
  patchProjectUrl,
  setCurrentPage,
  setPageReturn,
  pageReturn,
  projectServices,
}) {
  // Env/ API
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const [projectData, setProjectData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [pricingData, setPricingData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState("project");
  const [documentTypes, setDocumentTypes] = useState([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState({});
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] =
    useState("download");
  const [showEmailField, setShowEmailField] = useState(false);
  const [emailList, setEmailList] = useState("");
  const [show, setShow] = useState(false);
  const [documentId, setDocumentId] = useState(null);
  const [documentURL, setDocumentURL] = useState(null);
  const [marginPermission, setMarginPermission] = useState(false);
  const [professionalServicePricings, setProfessionalServicePricings] =
    useState([]);
  const [managedServicePricings, setManagedServicePricings] = useState([]);
  const [oneTimeAdjustment, setOneTimeAdjustment] = useState("");
  const [recurringAdjustment, setRecurringAdjustment] = useState("");
  const [currencyUnit, setCurrencyUnit] = useState("");
  const [terms, setTerms] = useState([]);
  const [generateDocumentIsSubmitted, setGenerateDocumentIsSubmitted] =
    useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState([]);
  const [generateDocumentOptions, setGenerateDocumentOptions] = useState([
    { name: "Download" },
    { name: "Email" },
  ]);

  const getSchedule = (terms, options) => {
    let ids = {};
    let schedule = [];
    terms.relationships.terms.data.forEach((term) => (ids[term.id] = true));
    let items = options.filter((term) => ids[term.id] && term.type === "terms");
    items.forEach((item) =>
      schedule.push({
        type: item.attributes["percentage-due"]
          ? `${item.attributes["percentage-due"]}%`
          : snakeToPascalCase(item.attributes["term-type"]),
        description: item.attributes.description,
      })
    );
    return schedule;
  };

  const fetchData = useCallback(() => {
    if (!isLoading) {
      return;
    }

    //Check for docusign
    API.Get(`${apiHost}/v1/accounts/${account_slug}`,
      authorizationCode
    ).then((res) => {
      if (res.data.data.attributes["esignature?"]) {
        setGenerateDocumentOptions([
          { name: "Download" },
          { name: "Email" },
          { name: "Electronic Signature" },
        ]);
      }
    });

    // Get Project Data
    API.Get(
      `${apiHost}/${account_slug}/v1/projects/${projectId}?include=business-unit,rate-table,project-locations,client,payment-term,project-contacts`,
      authorizationCode
    ).then((response) => {
      let data = response.data.data;
      let attr = data.attributes;
      let included = response.data.included;
      setCurrencyUnit(attr["field-labels"].currency_unit);
      setOneTimeAdjustment(attr["one-time-adjustment"]);
      setRecurringAdjustment(attr["mrr-adjustment"]);
      setTerms(attr["mrr-terms"]);
      // Get project pricings
      API.Get(data.links["project-pricings"], authorizationCode).then((res) => {
        let allServices = res.data.data;
        let marginPermission = res.data.meta.margin;
        setMarginPermission(marginPermission);
        let professionalServices = allServices.filter(
          (service) => service.attributes["service-type"] == "professional"
        );
        setProfessionalServicePricings(
          professionalServices.filter(
            (service) => service.attributes["billing-frequency"] == "one_time"
          )
        );
        setManagedServicePricings(
          allServices.filter(
            (service) => service.attributes["service-type"] == "managed"
          )
        );
      });

      const project = {
        name: data.attributes["project-name"],
        salesExecutive: data.attributes["sales-executive-name"],
        presalesEngineer: data.attributes["presales-engineer-name"],
        projectVariables: data.attributes["project-variables"].filter(
          (item) => item.value
        ),
      };

      const summary = {
        executiveSummary: data.attributes["executive-summary"],
        solutionSummary: data.attributes["solution-summary"],
      };

      const pricing = {
        contractLength: data.attributes["mrr-terms"],
        startDate: data.attributes["service-start-date"],
        frequency: data.attributes["recurring-billing-frequency"],
        includeRevenue: data.attributes["include-ps-revenue-in-mrr"],
      };

      //GET Professional services Payment Terms
      if (data.relationships["payment-term"].data !== null) {
        let id = data.relationships["payment-term"].data.id;
        API.Get(
          `${apiHost}/${account_slug}/v1/payment-terms/${id}?include=terms`,
          authorizationCode
        ).then((response) => {
          if (response.data.data.relationships.terms.data[0]) {
            let terms = response.data.data;
            let options = response.data.included;
            pricing.schedule = getSchedule(terms, options);
          }
          pricing.professionalTerms = included.find(
            (item) => item.id === id && item.type === "payment-terms"
          ).attributes.name;
          setPricingData(pricing);
        });
      }

      //Rate Table
      if (data.relationships["rate-table"].data !== null) {
        let id = data.relationships["rate-table"].data.id;
        project.rateTable = included.find(
          (item) => item.id === id && item.type === "rate-tables"
        );
      }

      //Project Location(s)
      if (data.relationships["project-locations"].data !== null) {
        let locationIds = data.relationships["project-locations"].data.map(
          (location) => location.id
        );
        let locations = [];
        locationIds.forEach((id) => {
          locations.push(
            included.find(
              (item) => item.id === id && item.type === "project-locations"
            )
          );
        });
        project.locations = locations;
      }

      //Business Unit
      if (data.relationships["business-unit"].data !== null) {
        let id = data.relationships["business-unit"].data.id;
        project.businessUnit = included.find(
          (item) => item.id === id && item.type === "business-units"
        );
      }

      //Client
      if (data.relationships.client.data !== null) {
        let id = data.relationships.client.data.id;
        project.client = included.find(
          (item) => item.id === id && item.type === "clients"
        );
        setProjectData(project);
      }

      //Contacts
      if (data.relationships["project-contacts"].data !== null) {
        let contactIds = data.relationships["project-contacts"].data.map(
          (contact) => contact.id
        );
        let contacts = [];
        contactIds.forEach((id) => {
          contacts.push(
            included.find(
              (item) => item.id === id && item.type === "project-contacts"
            )
          );
        });
        if (contacts.length > 0) {
          summary.contacts = contacts;
        }
        setSummaryData(summary);
      }
    });

    //GET ProjectDownloadTypes
    API.Get(
      `${apiHost}/${account_slug}/v1/projects/${projectId}/project-download-types`,
      authorizationCode
    ).then((response) => {
      setDocumentTypes(response.data.data);
      setSelectedDocumentType({
        value: response.data.data[0].attributes["template-id"],
        label: response.data.data[0].attributes.name,
      });
    });

    setIsLoading(false);
  }, [isLoading]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleTabSelect = useCallback((eventKey) => {
    setSelectedTab(eventKey);
  }, []);

  const continueProjectCreationWorkFlow = () => {
    window.location.replace(`${appHost}/projects`);
  };

  const backToPricing = () => {
    setCurrentPage(4);
    setPageReturn(true);
  };

  const goToProjectEditor = () => {
    return window.location.replace(`${appHost}/projects/${projectId}/edit`);
  };

  const renderProjectDownLoadOptions = () => {
    let options = new Set();
    let documentGroups = new Set();
    documentTypes.forEach((documentType) => {
      documentGroups.add(documentType.attributes.group);
    });

    Array.from(documentGroups).forEach((docGroup) => {
      options.add({
        label: docGroup,
        options: documentTypes
          .filter((docType) => docType.attributes.group === docGroup)
          .map((dt) => {
            return {
              label: dt.attributes.name,
              value: dt.attributes["template-id"],
            };
          }),
      });
    });
    return Array.from(options);
  };

  const generateDocument = (e) => {
    e.preventDefault();

    if (selectedDeliveryMethod !== "docusign") {
      setShow(true);
    }

    setGenerateDocumentIsSubmitted(true);

    let documentData = {
      data: {
        type: "project-documents",
        attributes: {
          "document-type": documentTypes.find(
            (documentType) =>
              documentType.attributes["template-id"] ===
              selectedDocumentType.value
          ).attributes["document-type"],
          "delivery-type": selectedDeliveryMethod,
          "template-id": selectedDocumentType.value,
        },
        relationships: {
          project: { data: { id: projectId, type: "projects" } },
        },
      },
    };

    if (selectedDeliveryMethod == "email") {
      if (!validateEmails(emailList)) {
        return;
      }
      documentData.data.attributes["email-list"] = emailList;
    }

    API.Post(
      `${apiHost}/${account_slug}/v1/project-documents`,
      documentData,
      authorizationCode
    ).then((res) => {
      if (res.data.data.attributes.status === "failed") {
        let errorMsgs = res.data.data.attributes["error-text"];
        setErrorMessages(errorMsgs.split("\n").map((msg) => msg + "."));
        setShowFailAlert(true);
      } else {
        if (selectedDeliveryMethod === "docusign") {
          setSuccessMessage("Submitted to Docusign.");
          setShowSuccessAlert(true);
        } else {
          setDocumentId(res.data.data.id);
        }
      }
    });
  };

  const handleClose = () => {
    setDocumentId(null);
    setDocumentURL(null);
    setGenerateDocumentIsSubmitted(false);
    setShow(false);
  };

  useEffect(() => {
    const checkForDocURL = setInterval(() => {
      if (documentId !== null && selectedDeliveryMethod !== "docusign") {
        API.Get(
          `${apiHost}/${account_slug}/v1/project-documents/${documentId}`,
          authorizationCode
        ).then((res) => {
          if (
            res.data.data.attributes["document-url"] !== null &&
            res.data.data.attributes.status === "finished"
          ) {
            setDocumentURL(res.data.data.attributes["document-url"]);
            clearInterval(checkForDocURL);
          }
        });
      } else {
        clearInterval(checkForDocURL);
      }
    }, 1000);
  }, [documentId]);

  return (
    <>
      {handleAlert(
        showFailAlert,
        errorMessages,
        setShowFailAlert,
        "danger",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      <Row>
        <Col sm={8}>
          <div
            className="details"
            style={{
              backgroundColor: "white",
              padding: "30px 30px 10px 30px",
            }}
          >
            <div
              style={{
                borderBottom: "1px solid #dee2e6",
                paddingBottom: "10px",
                fontSize: "24px",
                fontWeight: "bold",
                color: "#1C2655",
              }}
            >
              Review
            </div>
            <div
              style={{
                margin: "20px 0px 0px 0px",
              }}
            >
              <Tabs
                defaultActiveKey="project"
                id="uncontrolled-tab-example"
                className="mb-3"
                onSelect={handleTabSelect}
              >
                <Tab
                  eventKey="project"
                  title="Project"
                  tabClassName="tabButton"
                  className={
                    selectedTab === "project"
                      ? "active-tab reviewTab"
                      : "reviewTab"
                  }
                >
                  <ProjectReview className="reviewPage" data={projectData} />
                </Tab>
                <Tab
                  eventKey="summary"
                  title="Summary"
                  tabClassName="tabButton"
                  className={
                    selectedTab === "summary"
                      ? "active-tab reviewTab"
                      : "reviewTab"
                  }
                >
                  <SummaryReview className="reviewPage" data={summaryData} />
                </Tab>
                <Tab
                  eventKey="services"
                  title="Services"
                  tabClassName="tabButton"
                  className={
                    selectedTab === "services"
                      ? "active-tab reviewTab"
                      : "reviewTab"
                  }
                >
                  <ServicesReview
                    className="reviewPage"
                    data={projectServices}
                  />
                </Tab>
                <Tab
                  eventKey="pricing"
                  title="Pricing"
                  tabClassName="tabButton"
                  className={
                    selectedTab === "pricing"
                      ? "active-tab reviewTab"
                      : "reviewTab"
                  }
                >
                  <PricingReview
                    className="reviewPage"
                    data={pricingData}
                    projectServices={projectServices}
                    professionalServicePricings={professionalServicePricings}
                    managedServicePricings={managedServicePricings}
                    oneTimeAdjustment={oneTimeAdjustment}
                    recurringAdjustment={recurringAdjustment}
                    currencyUnit={currencyUnit}
                    terms={terms}
                    isLoading={isLoading}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </Col>
        <Col sm={4} style={{ padding: "0px 70px 0px 10px" }}>
          <div style={{ marginTop: "24px" }}>
            <StationaryModal
              header={"Keep Working"}
              body={
                <>
                  <div>
                    If you need to continue customizing this project, you can
                    continue to the advanced Statement of Work Builder.
                  </div>
                  <Button
                    style={{ marginTop: "15px" }}
                    className="ssButtonBgWhite"
                    onClick={() => goToProjectEditor()}
                  >
                    Go To Advanced Project Editor
                  </Button>
                </>
              }
            />
            <StationaryModal
              header={"Generate Document"}
              body={
                <Form onSubmit={generateDocument}>
                  <div>
                    If you are satisfied that this scope document is complete or
                    want to preview the document, use the generate tool.
                  </div>
                  <Form.Group className="formGroup">
                    <FormFieldLabel label="Document Type" />
                    <Select
                      required={true}
                      options={renderProjectDownLoadOptions()}
                      onChange={(e) => setSelectedDocumentType(e)}
                      value={selectedDocumentType}
                    />
                  </Form.Group>
                  <div>
                    <FormFieldLabel label="Delivery *" />
                    <br />
                    {generateDocumentOptions.map((item, index) => (
                      <FormCheck
                        defaultChecked={index == 0 ? true : false}
                        required={true}
                        id={
                          item.name == "Email"
                            ? "email"
                            : item.name == "Download"
                            ? "download"
                            : "docusign"
                        }
                        className="deliveryCheckbox"
                        inline
                        key={index}
                        label={item.name}
                        name="group1"
                        type="radio"
                        onChange={(e) => {
                          setSelectedDeliveryMethod(e.target.id);
                          if (e.target.id == "email") {
                            setShowEmailField(true);
                          } else {
                            setShowEmailField(false);
                          }
                        }}
                      />
                    ))}
                  </div>
                  {showEmailField ? (
                    <div style={{ marginTop: "15px" }}>
                      <Form.Group className="formGroup">
                        <FormFieldLabel label="Email List (comma separated list of emails) *" />
                        <Form.Control
                          required={true}
                          value={emailList}
                          onChange={(e) => setEmailList(e.target.value)}
                          isInvalid={
                            generateDocumentIsSubmitted &&
                            !validateEmails(emailList)
                          }
                        />
                        {generateDocumentIsSubmitted &&
                          !validateEmails(emailList) && (
                            <Form.Control.Feedback type="invalid">
                              Please enter a valid list of emails
                            </Form.Control.Feedback>
                          )}
                      </Form.Group>
                    </div>
                  ) : null}
                  <Button
                    type="submit"
                    style={{ marginTop: "15px" }}
                    variant="btn-secondary"
                    className="btnSeafoam"
                  >
                    Generate Document
                  </Button>
                </Form>
              }
            />
          </div>
        </Col>
      </Row>
      <CreateProjectFooter
        className="createProjectFooter"
        previousTabName={"Pricing"}
        backButtonOnClick={backToPricing}
        continueButtonType={"button"}
        continueButtonOnClick={() => continueProjectCreationWorkFlow()}
        disabled={false}
        continueButtonText="Close"
      />
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Body>
          {documentURL && selectedDeliveryMethod === "email" ? (
            <div>
              <img src={successImg} style={{ margin: "0px 115px 0px 135px" }} />
              <h1 style={{ textAlign: "center" }}>Success!</h1>
            </div>
          ) : (
            <div>
              <img src={loading} style={{ margin: "0 105px" }} />
              <p style={{ textAlign: "center" }}>
                The document is being generated. It will be{" "}
                {selectedDeliveryMethod === "download"
                  ? "available to download"
                  : "sent via email"}{" "}
                as soon as the file is ready. Please do not close browser
                window.
              </p>
            </div>
          )}
        </Modal.Body>
        {documentURL && (
          <Modal.Footer>
            <>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              {selectedDeliveryMethod === "download" && (
                <Button variant="primary" href={documentURL} target="_blank">
                  Download
                </Button>
              )}
            </>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default Review;

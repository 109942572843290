import React from "react";

function CloseButtonX({ onClose, className, style }) {
  return (
    <button
      style={style}
      onClick={onClose}
      type="button"
      className={className + " close"}
      data-dismiss="alert"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  );
}

export default CloseButtonX;

import React from "react";
import { useAuthorization } from "@common/providers";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import { Route, Routes, Outlet } from "react-router";
import Show from "./Show";
import { useGetAccountQuery, useWhoAmIQuery } from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import New from "./New";
import Edit from "./Edit";

const ProfessionalServicesMspa = ({
  serviceType,
}: {
  serviceType: "professional_services" | "managed_services";
}): JSX.Element => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  // Get Account
  const {
    data: account,
    error: accountError,
    isLoading: accountLoading,
    refetch: accountRefetch,
  } = useGetAccountQuery({
    slug: accountSlug,
    include: ["phases", "resources"],
  });

  // Get permissions for current user
  const {
    data: currentUser,
    error: currentUserError,
    isLoading: currentUserLoading,
    refetch: currentUserRefetch,
  } = useWhoAmIQuery();

  const authorized = useAuthorization();
  if (
    !authorized ||
    !account ||
    accountLoading ||
    !currentUser ||
    currentUserLoading
  ) {
    return <ScopeStackSpinner />;
  }

  const permission = currentUser?.data?.attributes?.privileges;
  const professionalServicePermission = permission?.find(
    (privilege) => privilege.privilege === "settings.tasks"
  )?.["access-level"];
  const managedServicePermission = permission?.find(
    (privilege) => privilege.privilege === "settings.managed_services"
  )?.["access-level"];
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Show
            account={account}
            servicePermission={
              serviceType === "professional_services"
                ? professionalServicePermission
                : managedServicePermission
            }
            serviceType={serviceType}
          />
        }
      />
      <Route path="/new" element={<Outlet />}>
        <Route
          index
          element={
            <New
              permission={
                serviceType === "professional_services"
                  ? professionalServicePermission
                  : managedServicePermission
              }
            />
          }
        />
      </Route>
      <Route path="/:id/edit" element={<Outlet />}>
        <Route
          index
          element={
            <Edit
              permission={
                serviceType === "professional_services"
                  ? professionalServicePermission
                  : managedServicePermission
              }
            />
          }
        />
      </Route>
    </Routes>
  );
};

export default ProfessionalServicesMspa;

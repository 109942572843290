import React, { useState, useEffect } from "react";
import CreateProjectFooter from "../CreateProjectFooter/CreateProjectFooter";
import { Row, Col } from "react-bootstrap";
import AddServiceDescriptions from "./AddServiceDescriptions";
import AccordianDrop from "@components/AccordianDrop/AccordianDrop";
import { getAccordianHeaderMarkup } from "../Services/serviceHelpers";
import { faCirclePlus } from "@fortawesome/pro-light-svg-icons";
import "../Services/Services.css";
import API from "@API";
import useProjectServicesData from "../Services/api/useProjectServicesData";

function ServiceDescriptions({
  account_slug,
  authorizationCode,
  projectId,
  patchProjectUrl,
  setCurrentPage,
  pageReturn,
  setPageReturn,
  projectServiceData,
  setProjectServiceData,
}) {
  // Env/ API
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;

  // States
  const [subpage, setSubpage] = useState("accordion");
  const [languageFields, setLanguageFields] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [projectData, setProjectData] = useState({});

  const { data, error, loaded } = useProjectServicesData({
    account_slug,
    authorizationCode,
    projectId,
  });

  useEffect(() => {
    if (data === null) {
      setIsLoading(true);
    } else {
      setProjectData(data);
      setProjectServiceData(data);
      setIsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    // Get Language Fields
    API.Get(
      `${apiHost}/${account_slug}/v1/language-fields`,
      authorizationCode
    ).then((response) => {
      let data = response.data.data;
      let serviceDescription = {
        id: 0,
        type: "language-fields",
        attributes: {
          name: "Service Description",
          "sow-language": "service_description",
        },
      };
      data.unshift(serviceDescription);
      setLanguageFields(data);
    });
  }, []);

  const continueProjectCreationWorkFlow = () => {
    setCurrentPage(4);
  };

  const backToServicesAdded = () => {
    setCurrentPage(2);
    setPageReturn(true);
  };

  return (
    <>
      <div className="details">
        <AddServiceDescriptions
          authorizationCode={authorizationCode}
          account_slug={account_slug}
          projectServiceData={projectData}
          languageFields={languageFields}
          isLoading={isLoading}
        />
      </div>
      <CreateProjectFooter
        previousTabName={"Services Added"}
        backButtonOnClick={backToServicesAdded}
        continueButtonType={"button"}
        continueButtonOnClick={() => continueProjectCreationWorkFlow()}
        disabled={false}
      />
    </>
  );
}

export default ServiceDescriptions;

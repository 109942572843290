import DataTable from "@components/DataTable";
import React, { useEffect, useState } from "react";
import { AuditLog, Change } from "./types/AuditLog";
import { ColumnDef } from "@tanstack/react-table";
import useListAuditLogs from "./api/useListAuditLogs";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";

function VersionHistory({ filter }) {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { auditLogs, auditLogsError, auditLogsLoading, pageMeta, refetch } =
    useListAuditLogs({
      filter: filter,
      page: {
        number: pageNumber,
        size: pageSize,
      },
    });

  const versionHistoryColumns: ColumnDef<AuditLog>[] = [
    {
      header: "Summary",
      cell(props) {
        return props.row.original.name;
      },
    },
    {
      header: "Change",
      cell(props) {
        return props.row.original.event;
      },
    },
    {
      header: "Changed On",
      cell(props) {
        const options: any = { month: "long", day: "numeric", year: "numeric" };
        return (
          props.row.original.createdAt?.toLocaleDateString("en-US", options) ||
          ""
        );
      },
    },
    {
      header: "Changed By",
      cell(props) {
        return props.row.original.changedBy;
      },
    },
  ];

  const changesColumns: ColumnDef<Change>[] = [
    {
      header: "Attribute",
      cell(props) {
        return props.row.original.attribute;
      },
    },
    {
      header: "Was",
      cell(props) {
        return props.row.original.changedFrom;
      },
    },
    {
      header: "Is",
      cell(props) {
        return props.row.original.changedTo;
      },
    },
  ];

  if (!auditLogs || auditLogsLoading) {
    return <SmallSpinner />;
  }

  return (
    <DataTable
      classForTable="versionHistoryTable"
      data={
        auditLogs.length > 0
          ? auditLogs.map((auditLog) => {
              return {
                ...auditLog,
                collapsedData: (
                  <div>
                    <DataTable
                      classForTable={`audiLogChanges-${auditLog?.id}`}
                      data={
                        auditLog?.changes?.length &&
                        auditLog?.changes?.length > 0
                          ? auditLog?.changes
                          : [
                              {
                                id: "noRow",
                                attribute: "No changes in this version",
                              },
                            ]
                      }
                      columns={changesColumns}
                      selectable={false}
                      striped={false}
                      selectedIds={[]}
                      setSelectedIds={() => null}
                      dragAndDrop={false}
                      isCollapsible={false}
                      hasSettings={false}
                    />
                  </div>
                ),
              };
            })
          : [
              {
                id: "noRow",
                name: "No version history found",
              },
            ]
      }
      columns={versionHistoryColumns}
      selectable={false}
      striped={true}
      selectedIds={[]}
      setSelectedIds={() => null}
      dragAndDrop={false}
      isCollapsible={auditLogs.length > 0}
      hasSettings={false}
      totalPages={pageMeta.pageCount}
      totalRows={pageMeta.recordCount}
      currentPage={pageNumber}
      setCurrentPage={setPageNumber}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
      paginationEnabled
      pageSizeEnabled
    />
  );
}

export default VersionHistory;

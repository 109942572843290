import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
} from "@tanstack/react-table";
import Table from "react-bootstrap/Table";
import { Form } from "react-bootstrap";
import Select from "react-select";
import PaginationNumbersAPI from "@components/Buttons/PaginationAPI/PaginationNumbersAPI";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { faStar as faStarSolid } from "@fortawesome/free-solid-svg-icons";
import { toCamelCase } from "@utils/helpers";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { faGripDotsVertical } from "@fortawesome/pro-solid-svg-icons";
import { faGear, faPlusSquare } from "@fortawesome/pro-light-svg-icons";
import "./DataTable.css";
import ContextMenu from "@components/ContextMenu/ContextMenu";

interface HasID {
  id: string | number;
  url?: string;
  isFavorited?: boolean;
  position?: number;
}

interface DataTableProps<T extends HasID> {
  data: T[];
  columns: ColumnDef<T>[];
  striped?: boolean;
  bordered?: boolean;
  hover?: boolean;
  selectable?: boolean;
  totalRows?: number;
  currentPage?: number;
  setCurrentPage?: (page: number) => void;
  pageSize?: number;
  totalPages?: number;
  onPageSizeChange?: (size: number) => void;
  selectedIds?: string[];
  setSelectedIds?: (ids: string[]) => void;
  paginationEnabled?: boolean;
  pageSizeEnabled?: boolean;
  favoriteable?: boolean;
  onFavoriteClick?: (resource: T, favorited: boolean) => void;
  classForTable?: string;
  dragAndDrop?: boolean;
  onDragEnd?: (position) => void;
  hookType?: string | null;
  moveToUpdateHook?: any;
  refetch?: any;
  isCollapsible?: boolean;
  hasSettings?: boolean;
  onSettingsClick?: (resource: T, settingsOpen: boolean) => void;
  settingsListItems?: any[];
  settingsMenuStyles?: object;
}

const DataTable = <T extends HasID>({
  data,
  columns,
  striped,
  bordered,
  hover,
  selectable = false,
  totalRows = 1,
  currentPage = 1,
  setCurrentPage = () => {},
  totalPages = 1,
  pageSize = 1,
  onPageSizeChange = () => {},
  selectedIds,
  setSelectedIds,
  paginationEnabled = false,
  pageSizeEnabled = false,
  favoriteable = false,
  onFavoriteClick,
  classForTable,
  dragAndDrop = false,
  onDragEnd,
  hookType = null,
  moveToUpdateHook = null,
  refetch = null,
  isCollapsible = false,
  hasSettings = false,
  onSettingsClick,
  settingsListItems,
  settingsMenuStyles,
}: DataTableProps<T>): JSX.Element => {
  const firstColumn = columns[0];
  const [errorMessages, setErrorMessages] = useState<string | string[]>(
    "Something went wrong! Your changes could not be saved at this time."
  );
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [dragItem, setDragItem] = useState<number>(0);
  const [positionHasUpdated, setPositionHasUpdated] = useState(false);
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  const modifiedFirstColumn: ColumnDef<T> = selectable
    ? {
        ...firstColumn,
        cell: (context) => {
          const rowId = context.row.original.id.toString();
          const isChecked = selectedIds?.includes(rowId);

          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                style={{ accentColor: "#418172" }}
                checked={isChecked}
                onChange={() => {
                  if (isChecked) {
                    setSelectedIds?.(
                      selectedIds?.filter((id) => id !== rowId) || []
                    );
                  } else {
                    setSelectedIds?.([...(selectedIds || []), rowId]);
                  }
                }}
              />
              <div style={{ marginLeft: "8px" }}>
                {flexRender(firstColumn.cell, context)}
              </div>
            </div>
          );
        },
      }
    : firstColumn;

  let newColumns = [modifiedFirstColumn, ...columns.slice(1)];
  const newFirstColumn = newColumns[0];

  const collapsibleFirstColumn: ColumnDef<T> = isCollapsible
    ? {
        ...newFirstColumn,
        cell: (context) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon
                icon={faPlusSquare}
                style={{
                  marginRight: "1.5em",
                  fontSize: "15px",
                }}
              />
              <div onClick={(e) => e.stopPropagation()}>
                {flexRender(newFirstColumn.cell, context)}
              </div>
            </div>
          );
        },
      }
    : newFirstColumn;

  const dndFirstColumn: ColumnDef<T> = dragAndDrop
    ? {
        ...collapsibleFirstColumn,
        cell: (context) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon
                onClick={(e) => e.stopPropagation()}
                icon={faGripDotsVertical}
                style={{
                  marginRight: "1.5em",
                  fontSize: "15px",
                }}
              />
              <div>{flexRender(collapsibleFirstColumn.cell, context)}</div>
            </div>
          );
        },
      }
    : newFirstColumn;

  newColumns = dragAndDrop
    ? [dndFirstColumn, ...newColumns.slice(1)]
    : [collapsibleFirstColumn, ...newColumns.slice(1)];

  const table = useReactTable({
    data,
    columns: newColumns,
    getCoreRowModel: getCoreRowModel(),
  });
  const [dataRows, setDataRows] = useState<any[]>(
    dragAndDrop
      ? table
          .getRowModel()
          .rows.sort((a, b) =>
            a.original.position && b.original.position
              ? a.original.position - b.original.position
              : 0 - 0
          )
      : table.getRowModel().rows
  );

  useEffect(() => {
    //takes care of making sure the colspan classes we pass are effective at making the elements span the correct width of the table and hiding the empty td elements pass so it does not affect the styling and the other normal table rows
    if (document.getElementsByClassName("fullColSpanColumnContent")) {
      let elements = Array.from(
        document.getElementsByClassName("fullColSpanColumnContent")
      );
      let elementsToHide = Array.from(
        document.getElementsByClassName("hideForFullColspan")
      );
      elements.forEach((element) => {
        const tdElement = element.parentElement?.parentElement;
        if (tdElement) {
          tdElement.setAttribute("colspan", "6");
        }
      });
      elementsToHide.forEach((element) => {
        const tdElement = element.parentElement?.parentElement;
        if (tdElement) {
          tdElement.setAttribute("style", "display: none");
        }
      });
    }
    if (!dragAndDrop || (dragAndDrop && !positionHasUpdated)) {
      setDataRows(
        dragAndDrop
          ? table
              .getRowModel()
              .rows.sort((a, b) =>
                a.original.position && b.original.position
                  ? a.original.position - b.original.position
                  : 0 - 0
              )
          : table.getRowModel().rows
      );
    }
  }, [data]);

  const tableClass = `sansTable standardListTable table ${
    bordered ? "table-bordered" : ""
  } ${hover ? "table-hover" : ""} ${classForTable ? classForTable : ""}`;

  const handleDragStart = (index) => {
    setDragItem(index);
  };

  const handleDragEnter = (e, index, row) => {
    e.preventDefault();
    if (!row.original.id.includes("new")) {
      if (e.target.tagName === "TD") {
        e.target.parentElement.setAttribute(
          "style",
          "border-bottom: thick solid green"
        );
      }

      e.target.style.opactiy = "0.5";
      let newList = [...dataRows];
      const item = newList[dragItem];
      newList.splice(dragItem, 1);
      newList.splice(index, 0, item);
      setDragItem(index);
      newList = newList.map((item) => {
        if (item.original.id == row.original.id) {
          item = {
            ...item,
            original: { ...row.original, position: dragItem + 1 },
          };
        }
        return item;
      });
      row["original"]["position"] =
        newList.find((item) => item.original.id == row.original.id)?.original
          .position || null;
      setDataRows(
        newList.sort((a, b) =>
          a.original.position && b.original.position
            ? a.original.position - b.original.position
            : 0 - 0
        )
      );
    }
    // else {
    //   setErrorMessages(
    //     "You must save all new services and subservice before changing it's position."
    //   );
    //   setShowFailAlert(true);
    // }
  };

  const handleDragLeave = (e) => {
    e.target.style.opactiy = "0.5";
    e.target.parentElement.removeAttribute("style");
  };

  const handleDrop = (e, item) => {
    e.stopPropagation();
    e.target.style.opactiy = "1";
    e.target.parentElement.removeAttribute("style");
    if (hookType && moveToUpdateHook && !item.id.includes("new")) {
      setPositionHasUpdated(true);
      const moveToBody = {
        type: hookType,
        id: item.id,
        attributes: {
          position: item.position,
        },
      };
      moveToUpdateHook({
        slug: accountSlug,
        id: Number(moveToBody?.id),
        body: { data: moveToBody },
      })
        .unwrap()
        .then((res) => {
          setPositionHasUpdated(false);
          if (refetch) {
            refetch();
          }
        })
        .catch((err) => {
          if (err) {
            let errorDetail =
              err?.data?.errors?.[0]?.detail || err?.data?.error;
            setErrorMessages(`Something went wrong! ${errorDetail}.`);
            setShowFailAlert(true);
          }
        });
    }
    if (onDragEnd) {
      onDragEnd(item);
    }
  };

  return (
    <div data-testid="dataTable" className="px-3 py-2">
      {handleAlert(
        showFailAlert,
        errorMessages,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      <Table className={tableClass}>
        <thead style={{ textAlign: "left" }}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} className={`${toCamelCase(header.id)}Col`}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {dataRows.map((row, index) => (
          <tbody
            draggable={dragAndDrop}
            onDragStart={() => handleDragStart(index)}
            onDragEnter={(e) => handleDragEnter(e, index, row)}
            onDragOverCapture={(e) => e.preventDefault()}
            onDragLeave={(e) => handleDragLeave(e)}
            onDrop={(e) => handleDrop(e, row.original)}
            onDragOver={(e) => e.preventDefault()}
            data-toggle="collapse"
            data-target={`.multi-collapse${row.original.id}`}
            aria-controls={`multiCollapseExample${row.original.id}`}
            key={row.id}
            onClick={() => {
              if (row.original.url) {
                window.location.href = row.original.url;
              }
            }}
            style={
              striped && index % 2 !== 0
                ? {
                    backgroundColor: "#eee",
                    cursor: row.original.url ? "pointer" : "default",
                  }
                : { cursor: row.original.url ? "pointer" : "default" }
            }
          >
            <tr>
              {row.getVisibleCells().map((cell, index, array) => {
                const isLastCell = index === array.length - 1;
                return (
                  <td
                    onClick={
                      index !== 0 ? (e) => e.stopPropagation() : () => null
                    }
                    key={cell.id}
                    className={`cellCol${index}`}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: isLastCell
                          ? "space-between"
                          : "flex-start",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                      {favoriteable && isLastCell && (
                        <FontAwesomeIcon
                          icon={
                            cell.row.original.isFavorited ? faStarSolid : faStar
                          }
                          onClick={() => {
                            if (cell.row.original) {
                              onFavoriteClick &&
                                onFavoriteClick(
                                  cell.row.original,
                                  cell.row.original.isFavorited || false
                                );
                            }
                          }}
                          style={{
                            marginLeft: "auto",
                            paddingRight: "2em",
                            cursor: "pointer",
                            color: cell.row.original.isFavorited
                              ? "#418172"
                              : "inherit",
                          }}
                        />
                      )}
                      {hasSettings && isLastCell && (
                        <>
                          <FontAwesomeIcon
                            icon={faGear}
                            onClick={(e) => {
                              e.stopPropagation();
                              setDataRows(
                                dataRows.map((r) => {
                                  if (r.original.id == row.original.id) {
                                    row.original.settingsOpen = row.original
                                      .settingsOpen
                                      ? !row.original.settingsOpen
                                      : true;
                                    return row;
                                  }
                                  r.original.settingsOpen = false;
                                  return r;
                                })
                              );
                              if (cell.row.original) {
                                onSettingsClick &&
                                  onSettingsClick(
                                    cell.row.original,
                                    cell.row.original.settingsOpen || false
                                  );
                              }
                            }}
                            style={{
                              marginLeft: "1em",
                              cursor: "pointer",
                              color: "#1c2655",
                              fontSize: "15px",
                            }}
                          />
                          {row.original.settingsOpen ? (
                            <ContextMenu
                              menuItems={settingsListItems}
                              style={settingsMenuStyles}
                            />
                          ) : null}
                        </>
                      )}
                    </div>
                  </td>
                );
              })}
            </tr>
            {isCollapsible ? (
              <tr
                onClick={(e) => e.stopPropagation()}
                className={`collapse multi-collapse${row.original.id}`}
                id={`multiCollapse${row.original.id}`}
              >
                <td colSpan={columns.length}>{row.original.collapsedData}</td>
              </tr>
            ) : null}
          </tbody>
        ))}
        <tfoot>
          {/* Pagination */}
          {paginationEnabled && (
            <tr>
              <td colSpan={columns.length}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: `${
                      pageSizeEnabled ? "space-between" : "center"
                    }`,
                    alignItems: "center",
                  }}
                >
                  {/* Row size dropdown */}
                  {pageSizeEnabled && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Showing
                      <Form.Group
                        className="formGroup"
                        style={{ marginBottom: "0", marginLeft: "1em" }}
                      >
                        <Select
                          className="basic-single"
                          onChange={(e) => {
                            if (e) {
                              setCurrentPage(1);
                              onPageSizeChange(e.value);
                            }
                          }}
                          defaultValue={{ value: 10, label: "10" }}
                          options={[
                            { value: 10, label: "10" },
                            { value: 25, label: "25" },
                            { value: 50, label: "50" },
                            { value: 100, label: "100" },
                          ]}
                        />
                      </Form.Group>
                    </div>
                  )}
                  {/* Page numbers */}
                  <PaginationNumbersAPI
                    pageCount={totalPages}
                    pageArray={Array.from(Array(totalPages), (_, i) => i + 1)}
                    currentPage={currentPage}
                    setCurrentPage={(page) => setCurrentPage(page)}
                    leftArrowOnClick={() => {
                      if (currentPage > 1) {
                        setCurrentPage(currentPage - 1);
                      }
                    }}
                    rightArrowOnClick={() => {
                      if (currentPage < totalPages) {
                        setCurrentPage(currentPage + 1);
                      }
                    }}
                    itemsPerPage={pageSize}
                    onNumberClick={(_, number) => setCurrentPage(number)}
                  />
                  {/* Viewing x of y */}
                  {pageSizeEnabled && (
                    <div style={{ display: "flex", alignContent: "center" }}>
                      {totalRows > 0 ? (
                        <>
                          Viewing{" "}
                          {currentPage == 1
                            ? 1
                            : (currentPage - 1) * pageSize + 1}{" "}
                          -{" "}
                          {currentPage == 1
                            ? pageSize > dataRows.length
                              ? dataRows.length
                              : pageSize
                            : currentPage === totalPages
                            ? totalRows
                            : currentPage * pageSize}{" "}
                          of {totalRows}
                        </>
                      ) : (
                        <>Viewing 0 - 0 of 0</>
                      )}
                    </div>
                  )}
                </div>
              </td>
            </tr>
          )}
        </tfoot>
      </Table>
      <div className="h-4" />
    </div>
  );
};

export default DataTable;

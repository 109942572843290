import React, { useState, useEffect } from "react";
import "../NewProjectDetails/NewProjectDetails.css";
import AccordianDrop from "@components/AccordianDrop/AccordianDrop";
import MultipleLineField from "@components/Forms/MulitpleLineField";
import { Row, Col, Button, Form } from "react-bootstrap";
import {
  toSnakeCase,
  splitPascalCase,
  snakeToPascalCase,
} from "@utils/helperFunctions";
import {
  faCirclePlus,
  faCircleMinus,
  faCircleCheck,
} from "@fortawesome/pro-light-svg-icons";
import { faPlus, faPencil, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import API from "@API";
import CreateProjectFooter from "../CreateProjectFooter/CreateProjectFooter";
import FormTextField from "@components/Forms/FormTextField";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";
import ContactFormSubmit from "@components/Forms/ContactFormSubmit.js";
import { getProjectServices } from "../Services/serviceHelpers";

function Summary({
  account_slug,
  authorizationCode,
  projectId,
  patchProjectUrl,
  setCurrentPage,
  setPageReturn,
  pageReturn,
  projectServices,
}) {
  // Env/ API
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const navigate = useNavigate();
  const [errorMessages, setErrorMessages] = useState([]);
  const [updatedAlertIsOpen, setUpdatedAlertIsOpen] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  //Customer Summary -- Form Field States
  const [customerSummaryAccordianIsOpen, setCustomerSummaryAccordianIsOpen] =
    useState(true);
  const [customerSummarySubmitted, setCustomerSummarySubmitted] =
    useState(false);
  const [executiveSummary, setExecutiveSummary] = useState("");
  const [solutionSummary, setSolutionSummary] = useState("");
  const [savedSolutionSummary, setSavedSolutionSummary] = useState("");
  const [savedExecutiveSummary, setSavedExecutiveSummary] = useState("");
  const [showExecutiveAll, setShowExecutiveAll] = useState(false);
  const [showSolutionAll, setShowSolutionAll] = useState(false);
  //Contacts -- Form Field States
  const [contactsAccordianIsOpen, setContactsAccordianIsOpen] = useState(false);
  const [contactSubmitted, setContactSubmitted] = useState(false);
  const [contactDeleted, setContactDeleted] = useState(false);
  const [contacts, setContacts] = useState([
    {
      contactChanged: false,
      contactSubmitted: false,
      id: `new-${Math.floor(Math.random() * 100)}`,
      name: "",
      title: "",
      phone: "",
      email: "",
      type: "",
    },
  ]);
  const [savedContacts, setSavedContacts] = useState([]);

  //Notes -- Form Field States
  const [notesAccordianIsOpen, setNotesAccordianIsOpen] = useState(false);
  const [noteSubmitted, setNoteSubmitted] = useState(false);
  const [notes, setNotes] = useState([
    {
      id: `new-${Math.floor(Math.random() * 100)}`,
      noteName: "Note 1",
      noteChanged: false,
      noteSubmitted: false,
      noteContent: "",
      noteCreatedAt: new Date().toLocaleTimeString([], {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }),
    },
  ]);
  const [savedNotes, setSavedNotes] = useState([]);
  const [noteDeleted, setNoteDeleted] = useState(false);
  const [showNoteRenameModal, setShowNoteRenameModal] = useState({
    showing: false,
    noteData: { noteName: "", noteChanged: false },
  });

  useEffect(() => {
    // If returning to page from Services, get existing summary, contacts, notes
    if (pageReturn === true) {
      //Existing Customer Summary
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${projectId}`,
        authorizationCode
      ).then((res) => {
        setSavedExecutiveSummary(res.data.data.attributes["executive-summary"]);
        setSavedSolutionSummary(res.data.data.attributes["solution-summary"]);
        const solutionSummary = res.data.data.attributes["solution-summary"];
        const executiveSummary = res.data.data.attributes["executive-summary"];
        setExecutiveSummary(executiveSummary);
        setSolutionSummary(solutionSummary);
        setCustomerSummarySubmitted(true);
      });

      //Existing Contacts
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${projectId}/project-contacts`,
        authorizationCode
      ).then((res) => {
        let response = res.data.data.map((savedContact) => {
          return {
            contactChanged: false,
            contactSubmitted: true,
            id: savedContact.id,
            name: savedContact.attributes.name,
            type: splitPascalCase(
              snakeToPascalCase(savedContact.attributes["contact-type"])
            ),
            title: savedContact.attributes.title,
            email: savedContact.attributes.email,
            phone: savedContact.attributes.phone,
          };
        });
        setSavedContacts(response);
        setContactSubmitted(true);
        setContacts(response);
        setContactsAccordianIsOpen(true);
        closeContactsAccordian();
      });

      //Exisiting Notes
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${projectId}/notes`,
        authorizationCode
      ).then((res) => {
        let notes = res.data.data.map((savedNote) => {
          return {
            id: savedNote.id,
            noteName: savedNote.attributes.name,
            noteChanged: false,
            noteSubmitted: true,
            noteContent: savedNote.attributes.content,
            noteCreatedAt: new Date(
              savedNote.attributes["created-at"]
            ).toLocaleTimeString([], {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }),
          };
        });
        setSavedNotes(notes);
        setNotes(notes);
        setNoteSubmitted(true);
        setNotesAccordianIsOpen(true);
        closeNoteAccordian();
        setShowNoteRenameModal({
          showing: false,
          noteData: { noteName: "", noteChanged: false },
        });
      });
    }
  }, [
    pageReturn,
    contactsAccordianIsOpen,
    notesAccordianIsOpen,
    customerSummaryAccordianIsOpen,
  ]);

  const getAccordianHeaderMarkup = (title, subtitle) => (
    <div style={{ width: "80vw" }}>
      <div className="accordianTitle">{title}</div>
      <div className="accordianSubtitle">{subtitle}</div>
    </div>
  );

  const closeCustomerSummaryAccordian = () => {
    if (
      document.querySelector(
        ".details .customerSummaryAccordian #openCloseToggle"
      )
    )
      document
        .querySelector(".details .customerSummaryAccordian #openCloseToggle")
        .click();
  };

  const renderSummary = (summary, isExec) => {
    if (
      (summary && summary !== "" && summary.length <= 335) ||
      (isExec ? showExecutiveAll : showSolutionAll)
    ) {
      return (
        <>
          {summary}
          {summary.length > 335 && (
            <button
              onClick={() => {
                if (isExec) {
                  setShowExecutiveAll(false);
                } else {
                  setShowSolutionAll(false);
                }
              }}
              className="viewAllClose"
            >
              -
            </button>
          )}
        </>
      );
    } else if (summary) {
      const truncated = summary.substring(0, 335) + "...";
      return (
        <>
          {truncated}
          <button
            onClick={() => {
              if (isExec) {
                setShowExecutiveAll(true);
              } else {
                setShowSolutionAll(true);
              }
            }}
            className="viewAllSummary viewAll"
          >
            View All
          </button>
        </>
      );
    }
  };

  const handleCustomerSummaryCancel = () => {
    // If no data changes, close accordian
    if (executiveSummary == "" && solutionSummary == "") {
      if (savedExecutiveSummary !== "" || savedSolutionSummary !== "") {
        setSolutionSummary(savedSolutionSummary);
        setExecutiveSummary(savedExecutiveSummary);
        setCustomerSummarySubmitted(true);
      } else {
        closeCustomerSummaryAccordian();
      }
    }
    // If there are unsaved changes, reset to empty state and close toggle
    else if (
      (executiveSummary !== "" && savedExecutiveSummary == "") ||
      (solutionSummary !== "" && savedSolutionSummary == "")
    ) {
      closeCustomerSummaryAccordian();
      setExecutiveSummary("");
      setSolutionSummary("");
    }
    // Else revert to previously saved (persisted in API) state
    else {
      setSolutionSummary(savedSolutionSummary);
      setExecutiveSummary(savedExecutiveSummary);
      setCustomerSummarySubmitted(true);
    }
  };

  const getCustomerSummaryHeader = () => {
    return (
      <div style={{ display: "flex", marginBottom: "20px" }}>
        <div>
          {getAccordianHeaderMarkup(
            "Customer Summary",
            "In the Executive Summary, we suggest you outline the desired business outcomes your Client is looking to achieve. In the Solution Summary, you can provide a high-level overview of the scoped solution."
          )}
        </div>
        <div>
          {customerSummaryAccordianIsOpen &&
          customerSummarySubmitted === false &&
          (executiveSummary !== "" ||
            solutionSummary !== "" ||
            executiveSummary !== savedExecutiveSummary ||
            solutionSummary !== savedSolutionSummary) ? (
            <Button
              style={{ marginRight: "10px" }}
              onClick={() => handleCustomerSummaryCancel()}
              className="ssButtonBgWhite"
            >
              Cancel
            </Button>
          ) : null}
          {customerSummarySubmitted ? (
            <Button
              onClick={() => {
                setCustomerSummarySubmitted(false);
                setCustomerSummaryAccordianIsOpen(true);
              }}
              className="ssButtonBgWhite"
            >
              Edit
            </Button>
          ) : customerSummaryAccordianIsOpen &&
            customerSummarySubmitted === false &&
            (executiveSummary !== "" ||
              solutionSummary !== "" ||
              executiveSummary !== savedExecutiveSummary ||
              solutionSummary !== savedSolutionSummary) ? (
            <Button
              style={{ border: "3px solid #418172" }}
              onClick={() => updateCustomerSummaryDetails()}
              className="seafoamBgButton"
            >
              Save
            </Button>
          ) : null}
        </div>
        <hr />
      </div>
    );
  };

  const customerSummaryBody = customerSummarySubmitted ? (
    <div style={{ fontSize: "16px" }}>
      <>
        <div
          style={{
            background: "#f2f2f2",
            borderRadius: "5px",
            padding: "25px",
          }}
        >
          <div className="columnTitle">Executive Summary</div>
          <p style={{ fontStyle: "normal", marginBottom: "0px" }}>
            {savedExecutiveSummary ? (
              renderSummary(savedExecutiveSummary, true)
            ) : (
              <span style={{ fontStyle: "italic" }}>
                No Executive Summary Saved
              </span>
            )}
          </p>
        </div>
      </>
      <>
        <br />
        <div
          style={{
            background: "#f2f2f2",
            borderRadius: "5px",
            padding: "25px",
          }}
        >
          <div className="columnTitle">Solution Summary</div>
          <p style={{ fontStyle: "normal", marginBottom: "0px" }}>
            {savedSolutionSummary ? (
              renderSummary(savedSolutionSummary, false)
            ) : (
              <span style={{ fontStyle: "italic" }}>
                No Solution Summary Saved
              </span>
            )}
          </p>
        </div>
      </>
    </div>
  ) : (
    <>
      <MultipleLineField
        label="Executive Summary"
        value={executiveSummary}
        onChange={(e) => {
          setExecutiveSummary(e.target.value);
        }}
        rows={2}
      />
      <MultipleLineField
        label="Solution Summary"
        value={solutionSummary}
        onChange={(e) => setSolutionSummary(e.target.value)}
        rows={2}
      />
    </>
  );

  const updateCustomerSummaryDetails = () => {
    setSavedExecutiveSummary("");
    setSavedSolutionSummary("");
    const data = {
      data: {
        type: "projects",
        id: projectId,
        attributes: {
          "executive-summary": executiveSummary,
          "solution-summary": solutionSummary,
        },
      },
    };
    API.Patch(patchProjectUrl, data, authorizationCode).then((res) => {
      if (res.status == 200) {
        const data = res.data.data;
        setCustomerSummarySubmitted(true);
        setSavedExecutiveSummary(data.attributes["executive-summary"]);
        setSavedSolutionSummary(data.attributes["solution-summary"]);
      }
    });
  };

  // Contacts

  const addContactButton = (
    <p
      style={{ color: "#418172", fontWeight: "bold", cursor: "pointer" }}
      onClick={() => {
        setContactSubmitted(false);
        setContacts([
          ...contacts,
          {
            id: `new-${Math.floor(Math.random() * 100)}`,
            contactChanged: false,
            contactSubmitted: false,
            type: "",
            name: "",
            title: "",
            email: "",
            phone: "",
          },
        ]);
      }}
    >
      <FontAwesomeIcon icon={faPlus} /> Add a Contact
    </p>
  );

  const closeContactsAccordian = () => {
    if (
      document.querySelector(".details .contactsAccordian #openCloseToggle")
    ) {
      document
        .querySelector(".details .contactsAccordian #openCloseToggle")
        .click();
    }
  };

  const handleContactsCancel = () => {
    let contactsWithContent = contacts.filter(
      (contact) =>
        contact.type !== "" ||
        contact.name !== "" ||
        contact.email !== "" ||
        contact.phone !== ""
    );
    let submittedContacts = contacts.filter(
      (contact) => contact.contactSubmitted === true
    );

    // If there's no contacts with added content, close accordian
    if (contactsWithContent.length == 0) {
      if (contactDeleted) {
        setContacts([
          {
            contactChanged: false,
            contactSubmitted: false,
            id: `new-${Math.floor(Math.random() * 100)}`,
            name: "",
            title: "",
            phone: "",
            email: "",
            type: "",
          },
        ]);
        setContactDeleted(false);
      } else {
        contacts.shift();
        setContacts([
          {
            contactChanged: false,
            contactSubmitted: false,
            id: `new-${Math.floor(Math.random() * 100)}`,
            name: "",
            title: "",
            phone: "",
            email: "",
            type: "",
          },
        ]);
        setContactDeleted(false);
        closeContactsAccordian();
      }
    }
    // If there's contacts with changes, but none have been submitted, reset to empty state and close accordian
    else if (
      contactsWithContent.length !== 0 &&
      submittedContacts.length == 0
    ) {
      setContacts([
        {
          contactChanged: false,
          contactSubmitted: false,
          id: `new-${Math.floor(Math.random() * 100)}`,
          name: "",
          title: "",
          phone: "",
          email: "",
          type: "",
        },
      ]);
      setContactDeleted(false);
      closeContactsAccordian();
    }
    // Else revert to previously saved (persisted in API) data and remove contacts with unsaved changes
    else {
      setContacts(savedContacts.filter((c) => c.contactChanged === false));
      setContactSubmitted(true);
      setContactDeleted(false);
    }
  };

  const contactsHeader = (
    <div style={{ display: "flex" }}>
      <div>
        {getAccordianHeaderMarkup(
          "Customer Contacts",
          <span>
            Add customer contacts external to the project.{" "}
            <span style={{ color: "#A1A1A1" }}>
              (E.g., CIO, CTO, Project Manager, etc.)
            </span>
          </span>
        )}
      </div>
      <div>
        {(contactsAccordianIsOpen &&
          contactSubmitted === false &&
          contacts.filter((contact) => contact.contactChanged).length !== 0) ||
        (contactsAccordianIsOpen && contactDeleted) ||
        (contactsAccordianIsOpen &&
          contactSubmitted === false &&
          savedContacts.length !== 0) ? (
          <Button
            style={{ marginRight: "10px" }}
            onClick={() => handleContactsCancel()}
            className="ssButtonBgWhite"
          >
            Cancel
          </Button>
        ) : null}
        {contactSubmitted ? (
          <Button
            onClick={() => {
              setContactSubmitted(false);
            }}
            className="ssButtonBgWhite"
          >
            Edit / Add
          </Button>
        ) : (contactsAccordianIsOpen && contactDeleted) ||
          (contactsAccordianIsOpen &&
            contacts.filter((contact) => contact.contactChanged).length !==
              0) ? (
          <Button
            style={{ border: "3px solid #418172" }}
            type="submit"
            // onClick={() => updateContactDetails(contacts)}
            className="seafoamBgButton"
          >
            Save
          </Button>
        ) : null}
      </div>
      <hr />
    </div>
  );

  const handleContactChange = (contact) => {
    let contactIndex = contacts.findIndex((c) => c.id === contact.id);
    contact.contactChanged = true;
    if (contactIndex !== -1) {
      contacts.splice(contactIndex, 1, contact);
      setContacts([...contacts]);
    } else {
      setContacts([...contacts, contact]);
    }
  };

  const deleteContact = (contact) => {
    let contactsArray = contacts.filter((c) => c.id !== contact.id);
    if (contactsArray.length === 0) {
      setContacts({
        contactChanged: false,
        contactSubmitted: false,
        id: `new-${Math.floor(Math.random() * 100)}`,
        name: "",
        title: "",
        phone: "",
        email: "",
        type: "",
      });
    }
    setContacts(contactsArray);
    setSavedContacts(contactsArray);
    setContactDeleted(true);
    if (
      contact.id.includes("new") == false ||
      contact.contactSubmitted === true
    ) {
      let contactData = {
        data: {
          type: "project-contacts",
          id: contact.id,
        },
      };
      API.Delete(
        `${apiHost}/${account_slug}/v1/project-contacts/${contact.id}`,
        contactData,
        authorizationCode
      ).catch((err) => {
        setErrorMessages(err);
        setShowFailAlert(true);
      });
    }
  };

  const contactsBody = contactSubmitted ? (
    <>
      <div className="columnTitle">Contacts</div>
      {savedContacts[0] ? (
        contacts
          .sort((a, b) => b.type.localeCompare(a.type))
          .map((contact, index) => (
            <div
              key={index}
              style={{
                background: "#f2f2f2",
                borderRadius: "5px",
                padding: "25px",
                marginBottom: "25px",
                fontSize: "15px",
              }}
            >
              <div>
                <strong>
                  {contact.type !== "Null" ? `${contact.type} -` : ""}
                </strong>
                <span>
                  {contact.name
                    ? ` ${contact.name}${
                        contact.title || contact.email || contact.phone
                          ? ","
                          : ""
                      }`
                    : ""}
                  {contact.title
                    ? ` ${contact.title}${
                        contact.email || contact.phone ? "," : ""
                      }`
                    : ""}
                  {contact.email
                    ? ` ${contact.email}${contact.phone ? "," : ""}`
                    : ""}
                  {contact.phone ? ` ${contact.phone}` : ""}
                </span>
              </div>
            </div>
          ))
      ) : (
        <div
          style={{
            background: "#f2f2f2",
            borderRadius: "5px",
            padding: "25px",
            marginBottom: "25px",
            fontSize: "15px",
            fontStyle: "italic",
          }}
        >
          No saved contacts
        </div>
      )}
    </>
  ) : (
    <>
      {contacts.map((contact, index) => (
        <div key={index} style={{ display: "flex" }}>
          <ContactFormSubmit
            onDataChange={handleContactChange}
            key={contact.id}
            submitted={contact.contactSubmitted}
            changed={contact.contactChanged}
            id={contact.id}
            name={contact.name}
            type={contact.type}
            title={contact.title}
            email={contact.email}
            phone={contact.phone}
            authorizationCode={authorizationCode}
            account_slug={account_slug}
            project_id={projectId}
          />

          <FontAwesomeIcon
            style={
              contact.contactSubmitted === false && index == 0
                ? { display: "none" }
                : {
                    color: "red",
                    marginLeft: "10px",
                    alignSelf: "center",

                    cursor: "pointer",
                    display: "flex",
                  }
            }
            icon={faTrashCan}
            onClick={() => {
              deleteContact(contact);
            }}
          />
        </div>
      ))}
      {addContactButton}
    </>
  );

  const addNoteButton = (
    <p
      style={{ color: "#418172", fontWeight: "bold", cursor: "pointer" }}
      onClick={() => {
        setNoteSubmitted(false);
        setNotes([
          ...notes,
          {
            id: `new-${Math.floor(Math.random() * 100)}`,
            noteName: `Note ${notes.length + 1}`,
            noteChanged: false,
            noteSubmitted: false,
            noteContent: "",
            noteCreatedAt: new Date().toLocaleTimeString([], {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }),
          },
        ]);
      }}
    >
      <FontAwesomeIcon icon={faPlus} /> Add Note
    </p>
  );

  const closeNoteAccordian = () => {
    if (document.querySelector(".details .notesAccordian #openCloseToggle")) {
      document
        .querySelector(".details .notesAccordian #openCloseToggle")
        .click();
    }
  };

  const handleNoteCancel = () => {
    let notesWithContent = notes.filter((note) => note.noteContent !== "");
    let submittedNotes = notes.filter((note) => note.noteSubmitted === true);
    // If there's notes with added content, close accordian
    if (notesWithContent.length == 0) {
      if (noteDeleted === true) {
        setNotes([
          {
            id: `new-${Math.floor(Math.random() * 100)}`,
            noteName: "Note 1",
            noteChanged: false,
            noteSubmitted: false,
            noteContent: "",
            noteCreatedAt: new Date().toLocaleTimeString([], {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }),
          },
        ]);
        setNoteDeleted(false);
      } else {
        closeNoteAccordian();
        notes.shift();
        setNotes([
          {
            id: `new-${Math.floor(Math.random() * 100)}`,
            noteName: "Note 1",
            noteChanged: false,
            noteSubmitted: false,
            noteContent: "",
            noteCreatedAt: new Date().toLocaleTimeString([], {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }),
          },
        ]);
        setNoteDeleted(false);
      }
    }
    // If there's notes with changes, but none have been submitted, reset to empty state and close accordian
    else if (notesWithContent.length !== 0 && submittedNotes.length == 0) {
      setNotes([
        {
          id: `new-${Math.floor(Math.random() * 100)}`,
          noteName: "Note 1",
          noteChanged: false,
          noteSubmitted: false,
          noteContent: "",
          noteCreatedAt: new Date().toLocaleTimeString([], {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }),
        },
      ]);
      closeNoteAccordian();
      setNoteDeleted(false);
    }
    // Else revert to previously saved (persisted in API) data and remove notes with unsaved changes
    else {
      setNotes(savedNotes.filter((n) => n.noteChanged === false));
      setNoteSubmitted(true);
      setNoteDeleted(false);
    }
  };

  const noteHeader = (
    <div style={{ display: "flex" }}>
      <div>
        {getAccordianHeaderMarkup(
          "Notes",
          <span>
            Add notes about anything relevant to the project for internal use.
          </span>
        )}
      </div>
      <div>
        {(notesAccordianIsOpen &&
          noteSubmitted === false &&
          notes.filter((note) => note.noteChanged).length !== 0) ||
        (notesAccordianIsOpen && noteDeleted) ||
        (notesAccordianIsOpen &&
          noteSubmitted === false &&
          savedNotes.length !== 0) ? (
          <Button
            style={{ marginRight: "10px" }}
            onClick={() => handleNoteCancel()}
            className="ssButtonBgWhite"
          >
            Cancel
          </Button>
        ) : null}
        {noteSubmitted ? (
          <Button
            onClick={() => setNoteSubmitted(false)}
            className="ssButtonBgWhite"
          >
            Edit / Add
          </Button>
        ) : (notesAccordianIsOpen && noteDeleted) ||
          (notesAccordianIsOpen &&
            notes.filter((note) => note.noteChanged).length !== 0) ? (
          <Button
            style={{ border: "3px solid #418172" }}
            onClick={() => updateNoteDetails(notes)}
            className="seafoamBgButton"
          >
            Save
          </Button>
        ) : null}
      </div>
      <hr />
    </div>
  );

  const deleteNote = (note) => {
    let notesArray = notes.filter((n) => n.id !== note.id);
    if (notesArray.length === 0) {
      setNotes([
        {
          id: `new-${Math.floor(Math.random() * 100)}`,
          noteName: "Note 1",
          noteChanged: false,
          noteSubmitted: false,
          noteContent: "",
          noteCreatedAt: new Date().toLocaleTimeString([], {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }),
        },
      ]);
    }
    setNotes(notesArray);
    setSavedNotes(notesArray);
    setNoteDeleted(true);
    if (note.id.includes("new") == false || note.noteSubmitted === true) {
      let noteData = {
        data: {
          type: "notes",
          id: note.id,
        },
      };
      API.Delete(
        `${apiHost}/${account_slug}/v1/projects/${projectId}/notes/${note.id}`,
        noteData,
        authorizationCode
      ).catch((err) => {
        setErrorMessages(err);
        setShowFailAlert(true);
      });
    }
  };

  const getNoteRenameModalBody = (note) => (
    <div>
      <p style={{ marginBottom: "20px" }}>
        Please provide a name for this note.
      </p>
      <FormTextField
        style={{ marginRight: "10px" }}
        label="Note Name"
        placeholder="New Note"
        onChange={(e) =>
          setShowNoteRenameModal({
            showing: true,
            noteData: { ...note, noteName: e.target.value },
          })
        }
      />
    </div>
  );

  const closeAndClearModal = () => {
    setShowNoteRenameModal({
      showing: false,
      noteData: { noteName: "", noteChanged: false },
    });
  };

  const getNoteLabel = (note, index) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <ScopeStackModal
          modalTitle="Rename Note"
          modalBody={getNoteRenameModalBody(showNoteRenameModal.noteData)}
          button1Text="Cancel"
          handleButton1Click={() => closeAndClearModal()}
          button2Text="Rename"
          handleButton2Click={() =>
            updateNoteDetails(
              notes.map((existingNote) =>
                existingNote.id == showNoteRenameModal.noteData.id
                  ? {
                      ...existingNote,
                      noteName: showNoteRenameModal.noteData.noteName,
                      noteChanged: true,
                    }
                  : existingNote
              ),
              true
            )
          }
          show={showNoteRenameModal.showing}
          handleClose={() => closeAndClearModal()}
        />
        <span>
          {note.noteName} - {note.noteCreatedAt}
        </span>
        <span>
          <FontAwesomeIcon
            id={note.id}
            style={
              note.noteContent == ""
                ? { display: "none" }
                : {
                    color: "#1c2655",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }
            }
            icon={faPencil}
            onClick={() => {
              setShowNoteRenameModal({ showing: true, noteData: note });
            }}
          />
          <FontAwesomeIcon
            style={
              note.noteSubmitted === false && index == 0
                ? { display: "none" }
                : {
                    color: "red",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }
            }
            icon={faTrashCan}
            onClick={() => deleteNote(note)}
          />
        </span>
      </div>
    );
  };

  const notesBody = noteSubmitted ? (
    <>
      <div className="columnTitle">Notes</div>
      {notes[0] ? (
        notes.map((note, index) => (
          <div
            key={index}
            style={{
              background: "#f2f2f2",
              borderRadius: "5px",
              padding: "25px 25px 25px 25px",
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                display: "flex",
                marginBottom: "15px",
                fontSize: "15px",
              }}
            >
              <strong style={{ marginRight: "8px" }}>{note.noteName}</strong>
              <span>{` - (${note.noteCreatedAt})`}</span>
            </div>
            <p style={{ marginBottom: "0px" }}>{note.noteContent}</p>
          </div>
        ))
      ) : (
        <div
          style={{
            background: "#f2f2f2",
            borderRadius: "5px",
            padding: "25px",
            marginBottom: "25px",
            fontSize: "15px",
            fontStyle: "italic",
          }}
        >
          No saved notes
        </div>
      )}
      <br />
    </>
  ) : (
    <>
      {notes.map((note, index) => (
        <div key={index}>
          <MultipleLineField
            id={note.id}
            label={getNoteLabel(note, index)}
            value={note.noteContent}
            onChange={(e) => {
              setNotes(
                notes.map((note) =>
                  note.id == e.target.id
                    ? {
                        ...note,
                        noteContent: e.target.value,
                        noteChanged: true,
                      }
                    : note
                )
              );
            }}
            rows={2}
          />
        </div>
      ))}
      {addNoteButton}
    </>
  );

  const updateContactDetails = (e, contacts) => {
    e.preventDefault();
    let validContacts = contacts.filter(
      (contact) => contact.id.includes("new") === false || contact.name !== ""
    );
    setSavedContacts(validContacts);
    if (contactDeleted === true) {
      setContactSubmitted(true);
      setContactDeleted(false);
    }
    validContacts.map((contact) => {
      const contactData = {
        data: {
          type: "project-contacts",
          attributes: {
            "contact-type":
              contact.type === "Null" || contact.type === ""
                ? "primary_customer_contact"
                : toSnakeCase(contact.type),
            name: contact.name,
            title: contact.title,
            email: contact.email,
            phone: contact.phone,
          },
          relationships: {
            project: { data: { type: "projects", id: projectId } },
          },
        },
      };

      let submitMethod = "Post";
      let submitUrl = `${apiHost}/${account_slug}/v1/project-contacts`;

      if (
        contact.id.includes("new") === false ||
        contact.contactSubmitted === true
      ) {
        submitMethod = "Patch";
        submitUrl = `${apiHost}/${account_slug}/v1/project-contacts/${contact.id}`;
        contactData.data.id = contact.id;
      }

      if (contact.contactChanged) {
        API.Submit(
          submitMethod,
          submitUrl,
          contactData,
          authorizationCode
        ).then((response) => {
          const data = response.data.data;
          if (response.status == 201 || response.status == 200) {
            API.Get(
              `${apiHost}/${account_slug}/v1/projects/${projectId}/project-contacts`,
              authorizationCode
            ).then((res) => {
              let response = res.data.data.map((savedContact) => {
                return {
                  contactChanged: false,
                  contactSubmitted: true,
                  id: savedContact.id,
                  name: savedContact.attributes.name,
                  type: splitPascalCase(
                    snakeToPascalCase(savedContact.attributes["contact-type"])
                  ),
                  title: savedContact.attributes.title,
                  email: savedContact.attributes.email,
                  phone: savedContact.attributes.phone,
                };
              });
              setSavedContacts(response);
              setContactSubmitted(true);
              setContacts(response);
            });
          }
        });
      }
    });
  };

  const updateNoteDetails = (notes, noteRenamed) => {
    if (noteDeleted === true) {
      setNoteSubmitted(true);
      setNoteDeleted(false);
    }
    // Patch project with notes
    notes.map((note) => {
      const noteData = {
        data: {
          type: "notes",
          attributes: {
            content: note.noteContent,
            name: note.noteName,
          },
        },
      };

      let submitMethod = "Post";
      let submitUrl = `${apiHost}/${account_slug}/v1/projects/${projectId}/notes`;

      if (note.id.includes("new") === false || note.noteSubmitted === true) {
        submitMethod = "Patch";
        submitUrl = `${apiHost}/${account_slug}/v1/projects/${projectId}/notes/${note.id}`;
        noteData.data.id = note.id;
      }

      if (note.noteChanged) {
        API.Submit(submitMethod, submitUrl, noteData, authorizationCode).then(
          (response) => {
            const data = response.data.data;
            const createdAt = new Date(data.attributes["created-at"]);
            let noteCreatedAt = createdAt.toLocaleTimeString([], {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            });
            if (response.status == 201 || response.status == 200) {
              API.Get(
                `${apiHost}/${account_slug}/v1/projects/${projectId}/notes`,
                authorizationCode
              ).then((res) => {
                let response = res.data.data
                  .map((savedNote) => {
                    return {
                      id: savedNote.id,
                      noteName: savedNote.attributes.name,
                      noteChanged: noteRenamed ? true : false,
                      noteSubmitted: true,
                      noteContent: savedNote.attributes.content,
                      noteCreatedAt: new Date(
                        savedNote.attributes["created-at"]
                      ).toLocaleTimeString([], {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                      }),
                    };
                  })
                  .sort();
                if (noteRenamed) {
                  setNoteSubmitted(false);
                } else {
                  setNoteSubmitted(true);
                }
                setSavedNotes(response);
                setNotes(response);
              });
              setShowNoteRenameModal({
                showing: false,
                noteData: { noteName: "", noteChanged: false },
              });
            }
          }
        );
      }
    });
  };

  const continueProjectCreationWorkFlow = () => {
    setCurrentPage(2);
  };

  return (
    <>
      <div className="details">
        <Row>
          <Col sm={12}>
            <div style={{ marginBottom: "40px" }}>
              <div style={{ width: "140px" }} className="columnTitle">
                Project Summary
              </div>
              <div className="columnSubtitle">
                Enter additional details pertinent to the success of this
                project.
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <AccordianDrop
              header={getCustomerSummaryHeader()}
              body={customerSummaryBody}
              iconOpen={
                customerSummarySubmitted ? faCircleCheck : faCircleMinus
              }
              iconClosed={
                customerSummarySubmitted &&
                (savedExecutiveSummary !== "" || savedSolutionSummary !== "")
                  ? faCircleCheck
                  : faCirclePlus
              }
              closeable={customerSummarySubmitted === false}
              setAccordianIsOpen={(accordianState) =>
                setCustomerSummaryAccordianIsOpen(accordianState)
              }
              className="customerSummaryAccordian mainAccordion"
              startingAccordionStateIsOpen={true}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form onSubmit={(e) => updateContactDetails(e, contacts)}>
              <AccordianDrop
                header={contactsHeader}
                body={contactsBody}
                iconOpen={contactSubmitted ? faCircleCheck : faCircleMinus}
                iconClosed={contactSubmitted ? faCircleCheck : faCirclePlus}
                closeable={contactSubmitted === false}
                setAccordianIsOpen={(accordianState) =>
                  setContactsAccordianIsOpen(accordianState)
                }
                className="contactsAccordian mainAccordion"
              />
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <AccordianDrop
              header={noteHeader}
              body={notesBody}
              iconOpen={noteSubmitted ? faCircleCheck : faCircleMinus}
              iconClosed={noteSubmitted ? faCircleCheck : faCirclePlus}
              closeable={noteSubmitted === false}
              setAccordianIsOpen={(accordianState) =>
                setNotesAccordianIsOpen(accordianState)
              }
              className="notesAccordian mainAccordion"
            />
          </Col>
        </Row>
      </div>
      <CreateProjectFooter
        previousTabName={"Projects"}
        backButtonOnClick={() => {
          navigate("/projects");
        }}
        continueButtonType={"button"}
        continueButtonOnClick={() => continueProjectCreationWorkFlow()}
        disabled={false}
      />
    </>
  );
}
export default Summary;
